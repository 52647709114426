"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateAddresses = exports.getAddressesById = exports.getAddresses = exports.fileTransferHeaders = exports.deleteAddresses = exports.defaultAddressData = exports.createAddresses = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = exports.fileTransferHeaders = {};
var defaultAddressData = exports.defaultAddressData = {
  id: 0,
  enabled: true,
  name: '',
  userId: 0,
  user: {
    id: null
  },
  contactId: 0,
  contact: {
    id: null
  },
  countryId: 0,
  country: {
    id: null
  },
  stateId: 0,
  state: {
    id: null
  },
  cityId: 0,
  city: {
    id: null
  },
  pincodeId: 0,
  pincode: {
    id: null
  },
  line1: '',
  line2: ''
};
var getAddresses = exports.getAddresses = function getAddresses(params) {
  return (0, _request.default)({
    url: '/addresses',
    method: 'get',
    params: params
  });
};
var getAddressesById = exports.getAddressesById = function getAddressesById(id) {
  return (0, _request.default)({
    url: "/addresses/".concat(id),
    method: 'get'
  });
};
var updateAddresses = exports.updateAddresses = function updateAddresses(id, data) {
  return (0, _request.default)({
    url: "/addresses/".concat(id),
    method: 'patch',
    data: data
  });
};
var deleteAddresses = exports.deleteAddresses = function deleteAddresses(id) {
  return (0, _request.default)({
    url: "/addresses/".concat(id),
    method: 'delete'
  });
};
var createAddresses = exports.createAddresses = function createAddresses(data) {
  return (0, _request.default)({
    url: '/addresses/',
    method: 'post',
    data: data
  });
};