"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateOrdersProduct = exports.getProductOrderInvoiceById = exports.getOrdersProducts = exports.getOrdersProductByOrderId = exports.getOrdersProductById = exports.fileTransferHeaders = exports.exportOrdersProducts = exports.exportAgingData = exports.defaultOrdersProductData = exports.countOrdersProducts = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = exports.fileTransferHeaders = {};
var defaultOrdersProductData = exports.defaultOrdersProductData = {
  id: 0,
  enabled: true,
  orderId: undefined,
  sellerId: 0,
  status: "",
  comments: "",
  subTotal: 0,
  shippingCharges: 0,
  taxedAmount: 0,
  discountAmount: 0,
  grandTotal: 0,
  totalProducts: 0,
  totalQuantity: 0,
  orderTimestamp: "",
  freightCharges: 0,
  rating: 0,
  orderDispatchedTimestamp: null,
  deliveredTimestamp: null,
  orderMarkingStatus: null,
  deliveryConfirmedTimestamp: null,
  notDeliveredTimestamp: null,
  cancelledTimestamp: null,
  lrNumber: null,
  lrReceipt: null,
  invoiceAttachment: null,
  qualityCoa: null,
  nominatedTransportDetails: null,
  notDeliveredReason: null,
  returnAmount: 0,
  returnQuantity: 0,
  returnDate: null,
  isPaid: false,
  sellerPaymentDate: null,
  userId: 0,
  user: {
    id: null
  },
  ordersSeller: {
    id: 0
  },
  order: {
    id: null
  },
  seller: {
    id: null
  }
};
var getOrdersProducts = exports.getOrdersProducts = function getOrdersProducts(params) {
  return (0, _request.default)({
    url: "/orders-products",
    method: "get",
    params: params
  });
};
var getOrdersProductById = exports.getOrdersProductById = function getOrdersProductById(id) {
  return (0, _request.default)({
    url: "/orders-products/".concat(id),
    method: "get"
  });
};
var getOrdersProductByOrderId = exports.getOrdersProductByOrderId = function getOrdersProductByOrderId(orderId) {
  return (0, _request.default)({
    url: "/orders-products/order/".concat(orderId),
    method: "get"
  });
};
var updateOrdersProduct = exports.updateOrdersProduct = function updateOrdersProduct(id, data) {
  return (0, _request.default)({
    url: "/orders-products/".concat(id),
    method: "patch",
    data: data
  });
};
var countOrdersProducts = exports.countOrdersProducts = function countOrdersProducts(params) {
  return (0, _request.default)({
    url: "/orders-products/count",
    method: "get",
    params: params
  });
};
var exportOrdersProducts = exports.exportOrdersProducts = function exportOrdersProducts(data) {
  return (0, _request.default)({
    url: "/orders-products/export",
    method: "post",
    data: data
  });
};
var exportAgingData = exports.exportAgingData = function exportAgingData(data) {
  return (0, _request.default)({
    url: "/orders-products/exportAgingData",
    method: "post",
    data: data
  });
};
var getProductOrderInvoiceById = exports.getProductOrderInvoiceById = function getProductOrderInvoiceById(id) {
  return (0, _request.default)({
    url: "/orders-products/pdf/".concat(id, "/1"),
    method: "get"
  });
};