"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uploadPincodeFile = exports.updatePincode = exports.getPincodes = exports.getPincodeById = exports.getCodPincodes = exports.fileTransferHeaders = exports.deletePincode = exports.defaultPincodeData = exports.createPincode = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = exports.fileTransferHeaders = {};
var defaultPincodeData = exports.defaultPincodeData = {
  id: 0,
  enabled: true,
  allowCod: false,
  countryId: undefined,
  country: {
    id: null
  },
  stateId: undefined,
  state: {
    id: null
  },
  cityId: undefined,
  city: {
    id: null
  },
  pincode: "",
  pincodeName: ""
};
var getPincodes = exports.getPincodes = function getPincodes(params) {
  return (0, _request.default)({
    url: "/pincodes",
    method: "get",
    params: params
  });
};
var getCodPincodes = exports.getCodPincodes = function getCodPincodes() {
  return (0, _request.default)({
    url: "/pincodes?filter=allowCod||eq||true",
    method: "get"
  });
};
var uploadPincodeFile = exports.uploadPincodeFile = function uploadPincodeFile(data) {
  return (0, _request.default)({
    url: "/pincodes/updateCod",
    method: "post",
    data: data
  });
};
var getPincodeById = exports.getPincodeById = function getPincodeById(id) {
  return (0, _request.default)({
    url: "/pincodes/".concat(id),
    method: "get"
  });
};
var updatePincode = exports.updatePincode = function updatePincode(id, data) {
  return (0, _request.default)({
    url: "/pincodes/".concat(id),
    method: "patch",
    data: data
  });
};
var deletePincode = exports.deletePincode = function deletePincode(id) {
  return (0, _request.default)({
    url: "/pincodes/".concat(id),
    method: "delete"
  });
};
var createPincode = exports.createPincode = function createPincode(data) {
  return (0, _request.default)({
    url: "/pincodes/",
    method: "post",
    data: data
  });
};