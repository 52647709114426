"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateAttribute = exports.getAttributesTree = exports.getAttributes = exports.getAttributeById = exports.fileTransferHeaders = exports.deleteAttribute = exports.defaultAttributeData = exports.createAttribute = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = exports.fileTransferHeaders = {};
var defaultAttributeData = exports.defaultAttributeData = {
  id: 0,
  enabled: true,
  name: ''
};
var getAttributes = exports.getAttributes = function getAttributes(params) {
  return (0, _request.default)({
    url: '/attributes',
    method: 'get',
    params: params
  });
};
var getAttributesTree = exports.getAttributesTree = function getAttributesTree(data) {
  return (0, _request.default)({
    url: '/attributes/tree',
    method: 'post',
    data: data
  });
};
var getAttributeById = exports.getAttributeById = function getAttributeById(id) {
  return (0, _request.default)({
    url: "/attributes/".concat(id),
    method: 'get'
  });
};
var updateAttribute = exports.updateAttribute = function updateAttribute(id, data) {
  return (0, _request.default)({
    url: "/attributes/".concat(id),
    method: 'patch',
    data: data
  });
};
var deleteAttribute = exports.deleteAttribute = function deleteAttribute(id) {
  return (0, _request.default)({
    url: "/attributes/".concat(id),
    method: 'delete'
  });
};
var createAttribute = exports.createAttribute = function createAttribute(data) {
  return (0, _request.default)({
    url: '/attributes/',
    method: 'post',
    data: data
  });
};