"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateCountry = exports.getCountryById = exports.getCountries = exports.deleteCountry = exports.defaultCountryData = exports.createCountry = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultCountryData = exports.defaultCountryData = {
  id: 0,
  enabled: true,
  code: '',
  name: '',
  zipCodeMinLength: 0,
  zipCodeMaxLength: 0
};
var getCountries = exports.getCountries = function getCountries(params) {
  return (0, _request.default)({
    url: '/countries',
    method: 'get',
    params: params
  });
};
var getCountryById = exports.getCountryById = function getCountryById(id) {
  return (0, _request.default)({
    url: "/countries/".concat(id),
    method: 'get'
  });
};
var updateCountry = exports.updateCountry = function updateCountry(id, data) {
  return (0, _request.default)({
    url: "/countries/".concat(id),
    method: 'patch',
    data: data
  });
};
var deleteCountry = exports.deleteCountry = function deleteCountry(id) {
  return (0, _request.default)({
    url: "/countries/".concat(id),
    method: 'delete'
  });
};
var createCountry = exports.createCountry = function createCountry(data) {
  return (0, _request.default)({
    url: '/countries/',
    method: 'post',
    data: data
  });
};