var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "formData",
      staticClass: "demo-form",
      attrs: {
        model: _vm.formData,
        "status-icon": "",
        rules: _vm.rules,
        "label-position": "left"
      }
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "Is Verified", prop: "isVerified" } },
        [
          _c("el-switch", {
            on: {
              change: function($event) {
                return _vm.toggleSwitch($event, "verified")
              }
            },
            model: {
              value: _vm.formData.isVerified,
              callback: function($$v) {
                _vm.$set(_vm.formData, "isVerified", $$v)
              },
              expression: "formData.isVerified"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Is Bestseller", prop: "isBestSeller" } },
        [
          _c("el-switch", {
            on: {
              change: function($event) {
                return _vm.toggleSwitch($event, "bestseller")
              }
            },
            model: {
              value: _vm.formData.isBestSeller,
              callback: function($$v) {
                _vm.$set(_vm.formData, "isBestSeller", $$v)
              },
              expression: "formData.isBestSeller"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Bid Allowed %", prop: "bidAllowed" } },
        [
          _c("el-input-number", {
            attrs: {
              name: "bidAllowed",
              placeholder: "Bid Allowed %",
              min: 0,
              max: 100,
              precision: 2
            },
            model: {
              value: _vm.formData.bidAllowed,
              callback: function($$v) {
                _vm.$set(_vm.formData, "bidAllowed", $$v)
              },
              expression: "formData.bidAllowed"
            }
          }),
          _c("help-text", { attrs: { content: "" } })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Priority", prop: "priority" } },
        [
          _c("el-input-number", {
            attrs: {
              name: "priority",
              placeholder: "Priority",
              min: 0,
              max: 1000,
              precision: 2
            },
            model: {
              value: _vm.formData.priority,
              callback: function($$v) {
                _vm.$set(_vm.formData, "priority", $$v)
              },
              expression: "formData.priority"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Video Url", prop: "videoLink" } },
        [
          _c("el-input", {
            attrs: { type: "text", name: "videoLink" },
            model: {
              value: _vm.formData.videoLink,
              callback: function($$v) {
                _vm.$set(_vm.formData, "videoLink", $$v)
              },
              expression: "formData.videoLink"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Color", prop: "color" } },
        [
          _c("el-input", {
            attrs: { type: "text", name: "color" },
            model: {
              value: _vm.formData.color,
              callback: function($$v) {
                _vm.$set(_vm.formData, "color", $$v)
              },
              expression: "formData.color"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Grade", prop: "grade" } },
        [
          _c("el-input", {
            attrs: { type: "text", name: "grade" },
            model: {
              value: _vm.formData.grade,
              callback: function($$v) {
                _vm.$set(_vm.formData, "grade", $$v)
              },
              expression: "formData.grade"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Dimensions", prop: "dimensions" } },
        [
          _c("el-input", {
            attrs: { type: "text", name: "dimensions" },
            model: {
              value: _vm.formData.dimensions,
              callback: function($$v) {
                _vm.$set(_vm.formData, "dimensions", $$v)
              },
              expression: "formData.dimensions"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "MRP", prop: "mrp" } },
        [
          _c("el-input-number", {
            attrs: { name: "mrp", placeholder: "mrp", min: 1, precision: 2 },
            model: {
              value: _vm.formData.mrp,
              callback: function($$v) {
                _vm.$set(_vm.formData, "mrp", $$v)
              },
              expression: "formData.mrp"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Moisture %", prop: "moisturePercent" } },
        [
          _c("el-input-number", {
            attrs: { name: "moisturePercent", min: 0, max: 100, precision: 2 },
            model: {
              value: _vm.formData.moisturePercent,
              callback: function($$v) {
                _vm.$set(_vm.formData, "moisturePercent", $$v)
              },
              expression: "formData.moisturePercent"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Description", prop: "description" } },
        [
          _c("editor", {
            attrs: {
              "api-key": "b3w2lncq9mhqbft4zaemvx1andivk7bodsffkzneoaol8tpq",
              init: {
                height: 300,
                plugins: ["lists link image paste help wordcount table code"],
                toolbar:
                  "undo redo | formatselect | bold italic | table | code | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help"
              }
            },
            model: {
              value: _vm.formData.description,
              callback: function($$v) {
                _vm.$set(_vm.formData, "description", $$v)
              },
              expression: "formData.description"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Description Hindi", prop: "description_h" } },
        [
          _c("editor", {
            attrs: {
              "api-key": "b3w2lncq9mhqbft4zaemvx1andivk7bodsffkzneoaol8tpq",
              init: {
                height: 300,
                plugins: ["lists link image paste help wordcount table code"],
                toolbar:
                  "undo redo | formatselect | bold italic | table | code | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help"
              }
            },
            model: {
              value: _vm.formData.description_h,
              callback: function($$v) {
                _vm.$set(_vm.formData, "description_h", $$v)
              },
              expression: "formData.description_h"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Tags", prop: "tags" } },
        [
          _vm._l(
            _vm.formData.tags ? _vm.formData.tags.split(",") : [],
            function(tag) {
              return _c(
                "el-tag",
                {
                  key: tag,
                  attrs: { closable: "", "disable-transitions": false },
                  on: {
                    close: function($event) {
                      return _vm.handleTagClose(tag)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(tag) + " ")]
              )
            }
          ),
          _vm.inputTagVisible
            ? _c("el-input", {
                ref: "saveTagInput",
                staticClass: "input-new-tag",
                attrs: { size: "mini" },
                on: { blur: _vm.handleTagInputConfirm },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleTagInputConfirm($event)
                  }
                },
                model: {
                  value: _vm.inputTagValue,
                  callback: function($$v) {
                    _vm.inputTagValue = $$v
                  },
                  expression: "inputTagValue"
                }
              })
            : _c(
                "el-button",
                {
                  staticClass: "button-new-tag",
                  attrs: { size: "small" },
                  on: { click: _vm.showTagInput }
                },
                [_vm._v(" + New Tag ")]
              )
        ],
        2
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                },
                { name: "waves", rawName: "v-waves" }
              ],
              attrs: { type: "success" },
              on: { click: _vm.submitForm }
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
          ),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              attrs: { type: "reset" },
              on: { click: _vm.resetForm }
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }