"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getOthersTrackingData = exports.getOrderRangeData = exports.getAshapuraTrackingData = exports.exportOrderRangeData = exports.defaultOrderTrackingData = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultOrderTrackingData = exports.defaultOrderTrackingData = {
  id: 0,
  agingBracket: "",
  noOfProducts: 0
};
var getAshapuraTrackingData = exports.getAshapuraTrackingData = function getAshapuraTrackingData(params) {
  return (0, _request.default)({
    url: "/aging-bracket/ashapuraOrdersTable",
    method: "get",
    params: params
  });
};
var getOthersTrackingData = exports.getOthersTrackingData = function getOthersTrackingData(params) {
  return (0, _request.default)({
    url: "/aging-bracket/vendorsOrdersTable",
    method: "get",
    params: params
  });
};
var getOrderRangeData = exports.getOrderRangeData = function getOrderRangeData(data) {
  return (0, _request.default)({
    url: "/aging-bracket/getOrderRangeData",
    method: "post",
    data: data
  });
};
var exportOrderRangeData = exports.exportOrderRangeData = function exportOrderRangeData(data) {
  return (0, _request.default)({
    url: "/aging-bracket/exportOrderRangeData",
    method: "post",
    data: data
  });
};