var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-form",
          {
            ref: "formData",
            staticClass: "demo-form",
            attrs: {
              model: _vm.formData,
              "status-icon": "",
              rules: _vm.rules,
              "label-position": "left"
            }
          },
          [
            _vm.isSuperAdmin()
              ? _c(
                  "el-form-item",
                  { attrs: { label: "Enabled", prop: "enabled" } },
                  [
                    _c("el-switch", {
                      model: {
                        value: _vm.formData.enabled,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "enabled", $$v)
                        },
                        expression: "formData.enabled"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-form-item",
              { attrs: { label: "Seller", prop: "seller" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      loading: _vm.sellerListLoading,
                      filterable: "",
                      remote: "",
                      clearable: "",
                      "remote-method": _vm.getSellerList,
                      name: "Seller",
                      placeholder: "Enter Product Name"
                    },
                    on: {
                      change: function($event) {
                        return _vm.handleSellerChange(_vm.formData.sellerId)
                      }
                    },
                    model: {
                      value: _vm.formData.sellerId,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "sellerId", $$v)
                      },
                      expression: "formData.sellerId"
                    }
                  },
                  _vm._l(_vm.sellerList, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.fullName, value: item.id }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "User Mobile", prop: "mobileNumber" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "mobileNumber",
                    placeholder: "User Mobile",
                    disabled: true
                  },
                  model: {
                    value: _vm.mobileNumber,
                    callback: function($$v) {
                      _vm.mobileNumber = $$v
                    },
                    expression: "mobileNumber"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Validity", prop: "validity" } },
              [
                _c("el-date-picker", {
                  attrs: {
                    type: "daterange",
                    "start-placeholder": "Start date",
                    "end-placeholder": "End date",
                    "default-time": ["00:00:00", "23:59:59"]
                  },
                  model: {
                    value: _vm.validity,
                    callback: function($$v) {
                      _vm.validity = $$v
                    },
                    expression: "validity"
                  }
                })
              ],
              1
            ),
            _vm.isEdit
              ? _c("el-form-item", {
                  attrs: { label: "Video", prop: "videoUrl" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm.isEdit
                              ? _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      type: "primary",
                                      href: _vm.formData.videoUrl,
                                      target: "_blank"
                                    }
                                  },
                                  [_vm._v(" view file ")]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3944273446
                  )
                })
              : _vm._e(),
            !_vm.isEdit
              ? _c(
                  "el-form-item",
                  { attrs: { label: "Upload Video", prop: "image" } },
                  [
                    _c("file-upload", {
                      attrs: {
                        multiple: false,
                        "file-list": _vm.videoUrl,
                        "max-size": 5,
                        url: "/common/upload"
                      },
                      on: { change: _vm.fileUploaded }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-form-item",
              { attrs: { label: "Page", prop: "page" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      name: "page",
                      required: "",
                      filterable: "",
                      "auto-complete": "off"
                    },
                    model: {
                      value: _vm.formData.page,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "page", $$v)
                      },
                      expression: "formData.page"
                    }
                  },
                  _vm._l(_vm.pageList, function(page) {
                    return _c("el-option", {
                      key: page.key,
                      attrs: { label: page.key, value: page.value }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm.formData.page === "Category"
              ? _c(
                  "el-form-item",
                  { attrs: { label: "Category", prop: "categoryId" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          name: "categoryId",
                          filterable: "",
                          "auto-complete": "off",
                          placeholder: "Category"
                        },
                        model: {
                          value: _vm.formData.categoryId,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "categoryId", $$v)
                          },
                          expression: "formData.categoryId"
                        }
                      },
                      _vm._l(_vm.categories, function(category) {
                        return _c("el-option", {
                          key: category.id,
                          attrs: { label: category.name, value: category.id }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.formData.page === "Product"
              ? _c(
                  "el-form-item",
                  { attrs: { label: "Product", prop: "productId" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          loading: _vm.productListLoading,
                          filterable: "",
                          remote: "",
                          "remote-method": _vm.getProductList,
                          name: "Product",
                          placeholder: "Enter Product Name"
                        },
                        model: {
                          value: _vm.formData.productId,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "productId", $$v)
                          },
                          expression: "formData.productId"
                        }
                      },
                      _vm._l(_vm.productList, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id }
                        })
                      }),
                      1
                    ),
                    _c("el-button", {
                      attrs: { slot: "append", icon: "el-icon-search" },
                      slot: "append"
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-form-item",
              { attrs: { label: "Status", prop: "status" } },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "filter-item",
                    staticStyle: { width: "130px" },
                    attrs: { placeholder: "Status", clearable: "" },
                    model: {
                      value: _vm.formData.status,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "status", $$v)
                      },
                      expression: "formData.status"
                    }
                  },
                  _vm._l(_vm.statusOptions, function(item) {
                    return _c("el-option", {
                      key: item.key,
                      attrs: { label: item.value, value: item.key }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      },
                      { name: "waves", rawName: "v-waves" }
                    ],
                    attrs: { type: "success" },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    attrs: { type: "reset" },
                    on: { click: _vm.resetForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }