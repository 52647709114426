"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.object.get-own-property-descriptor");
require("core-js/modules/es.object.to-string");
require("core-js/modules/es.string.iterator");
require("core-js/modules/es.weak-map");
require("core-js/modules/web.dom-collections.iterator");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _typeof2 = _interopRequireDefault(require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/typeof"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != (0, _typeof2.default)(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) { if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } } return n.default = e, t && t.set(e, n), n; }
var adminRouter = {
  path: "/admins",
  component: _index.default,
  redirect: "noredirect",
  name: "adminManagement.title",
  meta: {
    title: "adminManagement.title",
    icon: "user"
  },
  children: [{
    path: "/admins/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/list.vue"));
      });
    },
    name: "Admins",
    meta: {
      title: "adminManagement.admins.title",
      icon: "user",
      noCache: true
    }
  }, {
    path: "edit/:id(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/manage.vue"));
      });
    },
    name: "Edit Admin",
    props: {
      isEdit: true
    },
    meta: {
      title: "adminManagement.admins.manage",
      noCache: true,
      activeMenu: "/admin/list",
      hidden: true
    }
  }, {
    path: "add",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/manage.vue"));
      });
    },
    name: "Add Admin",
    props: {
      isEdit: false
    },
    meta: {
      title: "adminManagement.admins.manage",
      noCache: true,
      activeMenu: "/admin/list",
      hidden: true
    }
  }, {
    path: "/admin-roles/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/admin-roles/list.vue"));
      });
    },
    name: "Admin Roles",
    meta: {
      title: "adminManagement.roles.title",
      noCache: true,
      icon: "user"
    }
  }, {
    path: "/admin-roles/add",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/admin-roles/manage.vue"));
      });
    },
    name: "Add Role",
    props: {
      isEdit: false
    },
    meta: {
      title: "adminManagement.roles.manage",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/admin-roles/edit/:id(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/admin-roles/manage.vue"));
      });
    },
    name: "Edit Role",
    props: {
      isEdit: true
    },
    meta: {
      title: "adminManagement.roles.manage",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/assign-agent/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/assign-agent/list.vue"));
      });
    },
    name: "Admin Roles",
    meta: {
      title: "adminManagement.assignAgent.title",
      noCache: true,
      icon: "user"
    }
  }, {
    path: "/assign-onboarding-agent/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/assign--onboarding-agent/list.vue"));
      });
    },
    name: "Admin Roles",
    meta: {
      title: "adminManagement.assignOnboardingAgent.title",
      noCache: true,
      icon: "user"
    }
  }]
};
var _default = exports.default = adminRouter;