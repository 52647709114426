"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'dashboard': {
    width: 128,
    height: 100,
    viewBox: '0 0 128 100',
    data: '<path pid="0" d="M27.4 63.6c0-2.5-.9-4.6-2.6-6.4a8.8 8.8 0 00-6.5-2.6c-2.5 0-4.7.8-6.5 2.6a8.7 8.7 0 00-2.7 6.4c0 2.5 1 4.7 2.7 6.5 1.8 1.7 4 2.6 6.5 2.6s4.7-.9 6.5-2.6c1.7-1.8 2.6-4 2.6-6.5zm13.7-31.8c0-2.5-.9-4.6-2.6-6.4a8.8 8.8 0 00-6.5-2.6c-2.5 0-4.7.8-6.5 2.6a8.7 8.7 0 00-2.6 6.4c0 2.5.9 4.7 2.6 6.5 1.8 1.7 4 2.6 6.5 2.6s4.7-.9 6.5-2.6c1.7-1.8 2.6-4 2.6-6.5zM71.7 66L79 38.9c.3-1.3.1-2.4-.5-3.5a4.5 4.5 0 00-8.3 1.2L63 63.7a13.6 13.6 0 108 25.4 13 13 0 006.4-8.4A13.5 13.5 0 0071.7 66zm47.2-2.4c0-2.5-1-4.6-2.7-6.4a8.8 8.8 0 00-6.5-2.6c-2.5 0-4.7.8-6.5 2.6a8.7 8.7 0 00-2.6 6.4c0 2.5.9 4.7 2.7 6.5 1.7 1.7 3.9 2.6 6.4 2.6 2.5 0 4.7-.9 6.5-2.6 1.8-1.8 2.7-4 2.7-6.5zM73 18.2c0-2.5-.8-4.6-2.6-6.4A8.8 8.8 0 0064 9c-2.5 0-4.7 1-6.5 2.7a8.7 8.7 0 00-2.6 6.4c0 2.5.9 4.7 2.6 6.4 1.8 1.8 4 2.7 6.5 2.7s4.7-.9 6.5-2.7c1.7-1.7 2.6-3.9 2.6-6.4zm32 13.6c0-2.5-.8-4.6-2.6-6.4a8.8 8.8 0 00-6.5-2.6c-2.5 0-4.7.8-6.5 2.6a8.7 8.7 0 00-2.6 6.4c0 2.5.8 4.7 2.6 6.5 1.8 1.7 4 2.6 6.5 2.6s4.7-.9 6.5-2.6c1.7-1.8 2.6-4 2.6-6.5zm23 31.8c0 12.4-3.4 23.8-10 34.3-1 1.4-2.3 2-4 2H14c-1.7 0-3-.6-4-2a62.2 62.2 0 01-5-59 63.9 63.9 0 01123 24.7z"/>'
  }
});