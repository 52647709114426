"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPayLaterUsersById = exports.getPayLaterUsers = exports.getCountByPayLaterUsers = exports.defaultPayLaterUsers = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultPayLaterUsers = exports.defaultPayLaterUsers = {
  id: 0,
  enabled: true,
  userId: 0,
  user: {
    id: undefined
  }
};
var getPayLaterUsers = exports.getPayLaterUsers = function getPayLaterUsers(params) {
  return (0, _request.default)({
    url: "/apply-now-data",
    method: "get",
    params: params
  });
};
var getPayLaterUsersById = exports.getPayLaterUsersById = function getPayLaterUsersById(id) {
  return (0, _request.default)({
    url: "/apply-now-data/".concat(id),
    method: "get"
  });
};
var getCountByPayLaterUsers = exports.getCountByPayLaterUsers = function getCountByPayLaterUsers(data) {
  return (0, _request.default)({
    url: "/apply-now-data/list",
    method: "post",
    data: data
  });
};