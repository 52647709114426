"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSellersDocument = exports.getSellersDocuments = exports.getSellersDocumentById = exports.defaultSellersDocumentData = exports.createSellersDocument = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultSellersDocumentData = exports.defaultSellersDocumentData = {
  id: 0,
  enabled: true,
  name: '',
  file: undefined,
  status: undefined,
  user: {
    id: null
  }
};
var getSellersDocuments = exports.getSellersDocuments = function getSellersDocuments(params) {
  return (0, _request.default)({
    url: '/sellers-documents',
    method: 'get',
    params: params
  });
};
var getSellersDocumentById = exports.getSellersDocumentById = function getSellersDocumentById(id) {
  return (0, _request.default)({
    url: "/sellers-documents/".concat(id),
    method: 'get'
  });
};
var updateSellersDocument = exports.updateSellersDocument = function updateSellersDocument(id, data) {
  return (0, _request.default)({
    url: "/sellers-documents/".concat(id),
    method: 'patch',
    data: data
  });
};
var createSellersDocument = exports.createSellersDocument = function createSellersDocument(data) {
  return (0, _request.default)({
    url: '/sellers-documents/',
    method: 'post',
    data: data
  });
};