var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-form",
          {
            ref: "formData",
            staticClass: "demo-form",
            attrs: { "status-icon": "", "label-position": "left" }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "User", prop: "user" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      loading: _vm.userListLoading,
                      filterable: "",
                      remote: "",
                      clearable: "",
                      "remote-method": _vm.getUserList,
                      name: "User",
                      placeholder: "Enter Product Name"
                    },
                    model: {
                      value: _vm.formData.userId,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "userId", $$v)
                      },
                      expression: "formData.userId"
                    }
                  },
                  _vm._l(_vm.userList, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.fullName, value: item.id }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Delivery Option", prop: "deliveryOptions" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      placeholder: "Select",
                      name: "deliveryOptions",
                      required: ""
                    },
                    model: {
                      value: _vm.formData.selectedDelivery,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "selectedDelivery", $$v)
                      },
                      expression: "formData.selectedDelivery"
                    }
                  },
                  _vm._l(_vm.deliveryOptions, function(option, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { label: option.label, value: option.value }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm._l(_vm.formData.products, function(product, index) {
              return _c(
                "el-form-item",
                {
                  key: index,
                  attrs: {
                    label: "Product " + (index + 1),
                    prop: "product_" + index
                  }
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                loading: _vm.productListLoading,
                                filterable: "",
                                remote: "",
                                "remote-method": _vm.getProductList,
                                name: "product",
                                placeholder: "Enter User Name"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.handleProductChange(index)
                                }
                              },
                              model: {
                                value: product.id,
                                callback: function($$v) {
                                  _vm.$set(product, "id", $$v)
                                },
                                expression: "product.id"
                              }
                            },
                            _vm._l(_vm.productList, function(item, i) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: i }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "padding-left": "10px" },
                          attrs: { span: 8 }
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              name: "quantity",
                              placeholder: "Quantity",
                              "controls-position": "right",
                              min: 1,
                              max: 30000,
                              precision: 0
                            },
                            model: {
                              value: product.quantity,
                              callback: function($$v) {
                                _vm.$set(product, "quantity", $$v)
                              },
                              expression: "product.quantity"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "padding-left": "10px" },
                          attrs: { span: 4 }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "danger" },
                              on: {
                                click: function($event) {
                                  return _vm.removeProduct(index)
                                }
                              }
                            },
                            [_vm._v(" Remove ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.addProduct } },
                  [_vm._v("Add Product")]
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      },
                      { name: "waves", rawName: "v-waves" }
                    ],
                    attrs: { type: "success" },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    attrs: { type: "reset" },
                    on: { click: _vm.resetForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                )
              ],
              1
            )
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }