"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSellersVariationsCitiesPrice = exports.getSellersVariationsCitiesPrices = exports.getSellersVariationsCitiesPriceById = exports.deleteSellersVariationsCitiesPrice = exports.defaultISellersVariationsCitiesPriceData = exports.createSellersVariationsCitiesPrice = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultISellersVariationsCitiesPriceData = exports.defaultISellersVariationsCitiesPriceData = {
  id: 0,
  enabled: true,
  sellersProductsVariationId: null,
  cityId: null,
  unitPrice: 0,
  sellersProductsVariation: {
    id: null
  },
  city: {
    id: null
  }
};
var getSellersVariationsCitiesPrices = exports.getSellersVariationsCitiesPrices = function getSellersVariationsCitiesPrices(params) {
  return (0, _request.default)({
    url: '/sellers-variations-cities-prices',
    method: 'get',
    params: params
  });
};
var getSellersVariationsCitiesPriceById = exports.getSellersVariationsCitiesPriceById = function getSellersVariationsCitiesPriceById(id) {
  return (0, _request.default)({
    url: "/sellers-variations-cities-prices/".concat(id),
    method: 'get'
  });
};
var updateSellersVariationsCitiesPrice = exports.updateSellersVariationsCitiesPrice = function updateSellersVariationsCitiesPrice(id, data) {
  return (0, _request.default)({
    url: "/sellers-variations-cities-prices/".concat(id),
    method: 'patch',
    data: data
  });
};
var deleteSellersVariationsCitiesPrice = exports.deleteSellersVariationsCitiesPrice = function deleteSellersVariationsCitiesPrice(id) {
  return (0, _request.default)({
    url: "/sellers-variations-cities-prices/".concat(id),
    method: 'delete'
  });
};
var createSellersVariationsCitiesPrice = exports.createSellersVariationsCitiesPrice = function createSellersVariationsCitiesPrice(data) {
  return (0, _request.default)({
    url: '/sellers-variations-cities-prices/',
    method: 'post',
    data: data
  });
};