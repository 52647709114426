var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": ""
          },
          on: {
            "sort-change": _vm.sortChange,
            "selection-change": _vm.handleSelectionChange
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              width: "200px",
              align: "left",
              label: "Order Status",
              prop: "status"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("strong", [_vm._v("ID:")]),
                    _c(
                      "router-link",
                      { attrs: { to: "/order/" + scope.row.id } },
                      [
                        scope.row.status !== "in cart"
                          ? _c("el-link", { attrs: { type: "primary" } }, [
                              _vm._v(" " + _vm._s(scope.row.id) + " ")
                            ])
                          : _vm._e()
                      ],
                      1
                    ),
                    scope.row.status === "in cart"
                      ? _c("div", [_vm._v(" " + _vm._s(scope.row.id) + " ")])
                      : _vm._e(),
                    _c(
                      "p",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: "",
                              size: "mini",
                              type: _vm.statusColorMap[scope.row.status]
                            }
                          },
                          [_vm._v(" " + _vm._s(scope.row.status) + " ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tag",
                          {
                            attrs: {
                              size: "mini",
                              type: scope.row.isMobile ? "" : "warning"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.isMobile ? "Mobile" : "Website"
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "250",
              align: "center",
              label: "Products",
              prop: "ordersProduct"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._l(scope.row.ordersProduct, function(product) {
                      return _c(
                        "el-popover",
                        {
                          key: product.id,
                          attrs: {
                            placement: "top-start",
                            width: "400",
                            trigger: "hover"
                          }
                        },
                        [
                          _c("p", [
                            _vm._v("Quantity: " + _vm._s(product.quantity))
                          ]),
                          _c("p", [
                            _vm._v(
                              " Variant: " +
                                _vm._s(
                                  product.productsVariation
                                    ? product.productsVariation.name
                                    : product.product.name
                                ) +
                                " "
                            )
                          ]),
                          _c(
                            "p",
                            [
                              _vm._v(" Unit Price: "),
                              _c("inr", {
                                attrs: { number: product.unitPrice }
                              })
                            ],
                            1
                          ),
                          _c(
                            "p",
                            [
                              _vm._v(" Sale Price: "),
                              _c("inr", {
                                attrs: { number: product.salePrice }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-button",
                            { attrs: { slot: "reference" }, slot: "reference" },
                            [_vm._v(" " + _vm._s(product.product.name) + " ")]
                          )
                        ],
                        1
                      )
                    }),
                    _c("br")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "220px",
              align: "left",
              label: "User",
              prop: "userId"
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return scope.row.user
                      ? [
                          scope.row.userOrganizationName
                            ? _c("i", [
                                _c("i", { staticClass: "el-icon-suitcase" }),
                                _c("b", [
                                  _vm._v(
                                    " " + _vm._s(scope.row.userOrganizationName)
                                  )
                                ]),
                                _c("br")
                              ])
                            : _vm._e(),
                          _c("i", { staticClass: "el-icon-user" }),
                          _vm._v(" " + _vm._s(scope.row.user.fullName) + " "),
                          _c("br"),
                          _c("i", { staticClass: "el-icon-mobile-phone" }),
                          _vm._v(
                            " " + _vm._s(scope.row.user.mobileNumber) + " "
                          ),
                          _c("br"),
                          _c("i", { staticClass: "el-icon-message" }),
                          _vm._v(
                            " " + _vm._s(scope.row.user.emailAddress) + " "
                          )
                        ]
                      : undefined
                  }
                }
              ],
              null,
              true
            )
          }),
          _c("el-table-column", {
            attrs: {
              width: "210px",
              align: "left",
              label: "Grand Total",
              prop: "grandTotal"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._l(
                      ["total", "taxedAmount", "freightCharges", "grandTotal"],
                      function(field) {
                        return _c(
                          "div",
                          { key: field },
                          [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.totalColumn[field]) + ":")
                            ]),
                            _c("inr", { attrs: { number: scope.row[field] } })
                          ],
                          1
                        )
                      }
                    ),
                    scope.row.status === "Partial Payment"
                      ? _c("div", [
                          _c("strong", [
                            _vm._v(
                              _vm._s(_vm.totalColumn["amountReceived"]) + ":"
                            )
                          ]),
                          _vm._v(
                            " " + _vm._s(scope.row["amountReceived"]) + " "
                          )
                        ])
                      : _vm._e(),
                    scope.row.paymentGateway
                      ? _c("div", [
                          _c("strong", [_vm._v("Payment Gateway:")]),
                          _vm._v(" " + _vm._s(scope.row.paymentGateway) + " ")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "225px",
              align: "center",
              prop: "orderTimestamp",
              label: "Placed On"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.orderTimestamp
                      ? _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: _vm._f("moment")(
                                scope.row.orderTimestamp,
                                "MMMM Do YYYY, hh:mm a"
                              ),
                              placement: "top"
                            }
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("moment")(
                                    scope.row.orderTimestamp,
                                    "from"
                                  )
                                )
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _c("br"),
                    scope.row.deliveryOption
                      ? [
                          _c("div", [
                            _vm._v(
                              "Delivery: " + _vm._s(scope.row.deliveryOption)
                            )
                          ]),
                          scope.row.vrlId
                            ? [
                                _c(
                                  "div",
                                  { staticClass: "text-small text-italic" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.vrl && scope.row.vrl.name
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            : _vm._e(),
                          scope.row.nominatedTransportDetails
                            ? [
                                _c(
                                  "div",
                                  { staticClass: "text-small text-italic" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.nominatedTransportDetails
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            : _vm._e()
                        ]
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "225px",
              align: "center",
              prop: "admin.fullName",
              label: "Agent"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.admin && scope.row.admin.fullName
                      ? _c("span", [
                          _vm._v(" " + _vm._s(scope.row.admin.fullName) + " ")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: _vm.$t("table.actions"),
              width: "180"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "button",
                      {
                        staticStyle: {
                          background: "none",
                          border: "none",
                          outline: "none",
                          padding: "0",
                          "margin-left": "10px"
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleAccept(scope.row)
                          }
                        }
                      },
                      [
                        _c("el-button", {
                          directives: [{ name: "waves", rawName: "v-waves" }],
                          attrs: {
                            type: "primary",
                            size: "small",
                            icon: "el-icon-check"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "button",
                      {
                        staticStyle: {
                          background: "none",
                          border: "none",
                          outline: "none",
                          padding: "0",
                          "margin-left": "10px"
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleDecline(scope.row)
                          }
                        }
                      },
                      [
                        _c("el-button", {
                          directives: [{ name: "waves", rawName: "v-waves" }],
                          attrs: {
                            type: "danger",
                            size: "small",
                            icon: "el-icon-close"
                          }
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }