"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
var _typeof = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/typeof");
require("core-js/modules/es.array.concat");
require("core-js/modules/es.object.to-string");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/toConsumableArray"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var _pincodeSubMenu = require("./subMenus/pincode-sub-menu");
var _userSubMenu = require("./subMenus/user-sub-menu");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) { if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } } return n.default = e, t && t.set(e, n), n; }
var customerRouter = {
  path: "/customer",
  component: _index.default,
  redirect: "noredirect",
  name: "customerManagement.title",
  meta: {
    title: "customerManagement.title",
    icon: "peoples"
  },
  children: [{
    path: "/organization/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/organization/list.vue"));
      });
    },
    name: "Organization",
    meta: {
      icon: "building",
      title: "customerManagement.organization.title",
      noCache: true
    }
  }, {
    path: "/organization/edit/:id(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/organization/manage.vue"));
      });
    },
    name: "customerManagement.organization.edit",
    props: {
      isEdit: true
    },
    meta: {
      title: "customerManagement.organization.edit",
      noCache: true,
      activeMenu: "/organization/list",
      hidden: true
    }
  }, {
    path: "/organization/add",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/organization/manage.vue"));
      });
    },
    name: "customerManagement.organization.add",
    props: {
      isEdit: false
    },
    meta: {
      title: "customerManagement.organization.add",
      noCache: true,
      activeMenu: "/organization/list",
      hidden: true
    }
  }, {
    path: "/contact/list/:id",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/contact/list.vue"));
      });
    },
    name: "customerManagement.contact.title",
    meta: {
      title: "customerManagement.contact.title",
      hidden: true,
      noCache: true
    }
  }, {
    path: "/contact/add/:id",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/contact/manage.vue"));
      });
    },
    name: "customerManagement.contact.add",
    props: {
      isEdit: false
    },
    meta: {
      title: "customerManagement.contact.add",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/contact/edit/:id(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/contact/manage.vue"));
      });
    },
    name: "customerManagement.contact.edit",
    props: {
      isEdit: true
    },
    meta: {
      title: "customerManagement.contact.edit",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/address/list/:type/:id",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/address/list.vue"));
      });
    },
    name: "Address",
    meta: {
      title: "customerManagement.address.title",
      hidden: true,
      noCache: true
    }
  }, {
    path: "/address/list/:id",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/address/list.vue"));
      });
    },
    name: "Address",
    meta: {
      title: "customerManagement.address.title",
      hidden: true,
      noCache: true
    }
  }, {
    path: "/address/add/:id(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/address/manage.vue"));
      });
    },
    name: "customerManagement.address.add",
    props: {
      isEdit: false
    },
    meta: {
      title: "customerManagement.address.add",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/address/edit/:id(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/address/manage.vue"));
      });
    },
    name: "customerManagement.address.edit",
    props: {
      isEdit: true
    },
    meta: {
      title: "customerManagement.address.edit",
      noCache: true,
      hidden: true
    }
  }].concat((0, _toConsumableArray2.default)(_pincodeSubMenu.pincodesSubMenu), (0, _toConsumableArray2.default)(_userSubMenu.userSubMenu), [{
    path: "/users-logs/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/users-logs/list.vue"));
      });
    },
    name: "Logs",
    meta: {
      icon: "list",
      title: "customerManagement.userLog.title",
      noCache: true
    }
  }, {
    path: "/user-search-logs/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/user-search-logs/list.vue"));
      });
    },
    name: "Logs",
    meta: {
      icon: "list",
      title: "customerManagement.userSearchLog.title",
      noCache: true
    }
  }, {
    path: "/pay-later/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/pay-later/list.vue"));
      });
    },
    name: "Pay Later",
    meta: {
      icon: "list",
      title: "customerManagement.payLater.title",
      noCache: true
    }
  }, {
    path: "validateSeller",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/users/validate-seller.vue"));
      });
    },
    name: "userManagement.validateSeller.title",
    meta: {
      title: "userManagement.validateSeller.title",
      noCache: true,
      icon: "user"
    }
  }, {
    path: "validateOrganization",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/users/validate-organization.vue"));
      });
    },
    name: "userManagement.validateOrganization.title",
    meta: {
      title: "userManagement.validateOrganization.title",
      noCache: true,
      icon: "user"
    }
  }, {
    path: "stories",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/stories/list.vue"));
      });
    },
    name: "userManagement.stories.title",
    meta: {
      title: "userManagement.stories.title",
      noCache: true,
      icon: "user"
    }
  }, {
    path: "/stories/edit/:id(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/stories/manage.vue"));
      });
    },
    name: "customerManagement.stories.edit",
    props: {
      isEdit: true
    },
    meta: {
      title: "customerManagement.stories.edit",
      noCache: true,
      activeMenu: "/stories/list",
      hidden: true
    }
  }, {
    path: "/stories/add",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/stories/manage.vue"));
      });
    },
    name: "customerManagement.stories.add",
    props: {
      isEdit: false
    },
    meta: {
      title: "customerManagement.stories.add",
      noCache: true,
      activeMenu: "/stories/list",
      hidden: true
    }
  }, {
    path: "brands",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/brands/list.vue"));
      });
    },
    name: "userManagement.brands.title",
    meta: {
      title: "userManagement.brands.title",
      noCache: true,
      icon: "user"
    }
  }, {
    path: "/brands/edit/:id(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/brands/manage.vue"));
      });
    },
    name: "customerManagement.brands.edit",
    props: {
      isEdit: true
    },
    meta: {
      title: "customerManagement.brands.edit",
      noCache: true,
      activeMenu: "/brands/list",
      hidden: true
    }
  }, {
    path: "/brands/add",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/brands/manage.vue"));
      });
    },
    name: "customerManagement.brands.add",
    props: {
      isEdit: false
    },
    meta: {
      title: "customerManagement.brands.add",
      noCache: true,
      activeMenu: "/brands/list",
      hidden: true
    }
  }, {
    path: "/leads/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/leads/list.vue"));
      });
    },
    name: "Logs",
    meta: {
      icon: "list",
      title: "customerManagement.leads.title",
      noCache: true
    }
  }])
};
var _default = exports.default = customerRouter;