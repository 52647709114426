var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-form",
          {
            ref: "formData",
            staticClass: "demo-form",
            attrs: {
              model: _vm.formData,
              "status-icon": "",
              rules: _vm.rules,
              "label-position": "left"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "Enabled", prop: "enabled" } },
              [
                _c("el-switch", {
                  model: {
                    value: _vm.formData.enabled,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "enabled", $$v)
                    },
                    expression: "formData.enabled"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Question", prop: "questionText" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "questionText",
                    required: "",
                    placeholder: "Enter question"
                  },
                  model: {
                    value: _vm.formData.questionText,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "questionText", $$v)
                    },
                    expression: "formData.questionText"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Allow Custom Answer",
                  prop: "allowCustomAnswer"
                }
              },
              [
                _c("el-switch", {
                  model: {
                    value: _vm.formData.allowCustomAnswer,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "allowCustomAnswer", $$v)
                    },
                    expression: "formData.allowCustomAnswer"
                  }
                })
              ],
              1
            ),
            _vm.formData.allowCustomAnswer === false
              ? _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "Allow Multi-select",
                      prop: "allowMultiSelect"
                    }
                  },
                  [
                    _c("el-switch", {
                      model: {
                        value: _vm.formData.allowMultiSelect,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "allowMultiSelect", $$v)
                        },
                        expression: "formData.allowMultiSelect"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.formData.allowCustomAnswer === false &&
            _vm.formData.allowMultiSelect
              ? _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "Maximum Allowed Selections",
                      prop: "maximumAllowedSelections "
                    }
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { name: "action" },
                        model: {
                          value: _vm.formData.maximumAllowedSelections,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formData,
                              "maximumAllowedSelections",
                              $$v
                            )
                          },
                          expression: "formData.maximumAllowedSelections"
                        }
                      },
                      _vm._l([2, 3, 4, 5, 6, 7, 8, 9, 10], function(item) {
                        return _c("el-option", {
                          key: item,
                          attrs: { label: item, value: item }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    attrs: { loading: _vm.loading, type: "success" },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    attrs: { type: "reset" },
                    on: { click: _vm.resetForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }