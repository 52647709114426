"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'edit': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M106.1 67.2a4.8 4.8 0 00-4.8 4.8v46.4H9.6V26.7h50.1a4.8 4.8 0 100-9.6H9.6A9.6 9.6 0 000 26.7v91.7c0 5.3 4.3 9.6 9.6 9.6h91.7c5.3 0 9.6-4.3 9.6-9.6V72c0-2.7-2.1-4.8-4.8-4.8z"/><path pid="1" d="M125.2 13.4L114.6 2.8a9.6 9.6 0 00-13.6 0l-53 53a4.3 4.3 0 00-.9 1.3L33.8 88.5a4.2 4.2 0 001 4.7c1 1.2 2.8 1.7 4.6 1l31.4-13.4c.5-.2 1-.5 1.4-.9l53-53a9.6 9.6 0 000-13.5zm-59 59l-18.4 7.8 7.7-18.4 37.2-37.1 10.6 10.5L66 72.4zm52.1-52.2l-8.2 8.2L99.5 18l8.3-8.3L118.4 20z"/>'
  }
});