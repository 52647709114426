"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUsersLogs = exports.getUsersLogById = exports.getLeads = exports.getCountByUser = exports.defaultUsersLogData = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultUsersLogData = exports.defaultUsersLogData = {
  id: 0,
  enabled: true,
  userId: 0,
  user: {
    id: undefined
  }
};
var getUsersLogs = exports.getUsersLogs = function getUsersLogs(params) {
  return (0, _request.default)({
    url: "/users-logs",
    method: "get",
    params: params
  });
};
var getUsersLogById = exports.getUsersLogById = function getUsersLogById(id) {
  return (0, _request.default)({
    url: "/users-logs/".concat(id),
    method: "get"
  });
};
var getCountByUser = exports.getCountByUser = function getCountByUser(data) {
  return (0, _request.default)({
    url: "/users-logs/list",
    method: "post",
    data: data
  });
};
var getLeads = exports.getLeads = function getLeads(params) {
  return (0, _request.default)({
    url: "/leads",
    method: "get",
    params: params
  });
};