"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.object.get-own-property-descriptor");
require("core-js/modules/es.object.to-string");
require("core-js/modules/es.string.iterator");
require("core-js/modules/es.weak-map");
require("core-js/modules/web.dom-collections.iterator");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _typeof2 = _interopRequireDefault(require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/typeof"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != (0, _typeof2.default)(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) { if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } } return n.default = e, t && t.set(e, n), n; }
var homeNotificationsRouter = [{
  path: '/home-notifications/list',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/home-notifications/list.vue'));
    });
  },
  name: 'homeNotifications',
  meta: {
    title: 'homeNotificationsManagement.homeNotifications.title',
    noCache: true,
    icon: 'guide'
  }
}, {
  path: '/home-notifications/add/',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/home-notifications/manage.vue'));
    });
  },
  name: 'homeNotificationsManagement.homeNotifications.add',
  props: {
    isEdit: false
  },
  meta: {
    title: 'homeNotificationsManagement.homeNotifications.add',
    noCache: true,
    activeMenu: '/home-notifications/list',
    hidden: true
  }
}, {
  path: '/home-notifications/edit/:id(.*)',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/home-notifications/manage.vue'));
    });
  },
  name: 'homeNotificationsManagement.homeNotifications.edit',
  props: {
    isEdit: true
  },
  meta: {
    title: 'homeNotificationsManagement.homeNotifications.edit',
    noCache: true,
    activeMenu: '/home-notifications/list',
    hidden: true
  }
}];
var _default = exports.default = homeNotificationsRouter;