var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "130px" },
              attrs: {
                placeholder: _vm.$t("ordersTicket.enabled"),
                clearable: ""
              },
              on: { change: _vm.handleFilter },
              model: {
                value: _vm.listQuery.enabled,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "enabled", $$v)
                },
                expression: "listQuery.enabled"
              }
            },
            _vm._l(_vm.enableTypeOptions, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.value, value: item.key }
              })
            }),
            1
          ),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              placeholder: _vm.$t("ordersTicket.userName"),
              clearable: ""
            },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery["user.fullName"],
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "user.fullName", $$v)
              },
              expression: "listQuery['user.fullName']"
            }
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: "Orders Product Id", clearable: "" },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery["ordersProductId"],
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "ordersProductId", $$v)
              },
              expression: "listQuery['ordersProductId']"
            }
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "130px" },
              attrs: {
                placeholder: _vm.$t("ordersTicket.status"),
                clearable: ""
              },
              on: { change: _vm.handleFilter },
              model: {
                value: _vm.listQuery.status,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "status", $$v)
                },
                expression: "listQuery.status"
              }
            },
            _vm._l(_vm.statusOptions, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.value, value: item.key }
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "130px" },
              attrs: { placeholder: "Approval Status", clearable: "" },
              on: { change: _vm.handleFilter },
              model: {
                value: _vm.listQuery.approvalStatus,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "approvalStatus", $$v)
                },
                expression: "listQuery.approvalStatus"
              }
            },
            _vm._l(_vm.statusApprovalOptions, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.value, value: item.key }
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "140px" },
              on: { change: _vm.handleFilter },
              model: {
                value: _vm.listQuery.sort,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "sort", $$v)
                },
                expression: "listQuery.sort"
              }
            },
            _vm._l(_vm.sortOptions, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.label, value: item.key }
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [_vm._v(" " + _vm._s(_vm.$t("table.search")) + " ")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": ""
          },
          on: { "sort-change": _vm.sortChange }
        },
        [
          _c("el-table-column", {
            attrs: { width: "100", align: "center", label: "ID", prop: "id" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "router-link",
                      { attrs: { to: "/tickets/ticket-info/" + scope.row.id } },
                      [
                        _c("el-link", { attrs: { type: "primary" } }, [
                          _vm._v(" " + _vm._s(scope.row.id) + " ")
                        ])
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "250",
              align: "center",
              label: "User",
              prop: "userId"
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return scope.row.user
                      ? [
                          _c("enabled", {
                            attrs: { url: "/orders-supports/" + scope.row.id },
                            model: {
                              value: scope.row.enabled,
                              callback: function($$v) {
                                _vm.$set(scope.row, "enabled", $$v)
                              },
                              expression: "scope.row.enabled"
                            }
                          }),
                          _vm._v(" " + _vm._s(scope.row.user.fullName) + " "),
                          _c(
                            "p",
                            [
                              _c(
                                "el-link",
                                {
                                  attrs: {
                                    icon: "el-icon-message",
                                    target: "_blank",
                                    href:
                                      "mailto:" + scope.row.user.emailAddress
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.user.emailAddress) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "p",
                            [
                              _c(
                                "el-link",
                                {
                                  attrs: {
                                    icon: "el-icon-phone",
                                    target: "_blank",
                                    href: "tel:" + scope.row.user.mobileNumber
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.user.mobileNumber) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          scope.row.user.broker
                            ? _c(
                                "p",
                                [
                                  _c(
                                    "el-tag",
                                    {
                                      attrs: {
                                        type: "info",
                                        effect: "plain",
                                        size: "mini"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(scope.row.user.broker.name) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      : undefined
                  }
                }
              ],
              null,
              true
            )
          }),
          _c("el-table-column", {
            attrs: {
              width: "200px",
              align: "center",
              label: "Product Name",
              prop: "name"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(" " + _vm._s(_vm.getProductName(scope.row)) + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "180px",
              align: "center",
              label: "Product Marking Status",
              prop: "orderMarkingStatus"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(" " + _vm._s(_vm.getmarkingStatus(scope.row)) + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "250px",
              align: "center",
              label: "Order",
              prop: "orderId"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.ordersSeller
                      ? [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: "/order/" + scope.row.ordersSeller.orderId
                              }
                            },
                            [
                              _c("el-link", { attrs: { type: "primary" } }, [
                                _vm._v(" " + _vm._s(scope.row.orderId) + " ")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "p",
                            [
                              _c(
                                "el-tag",
                                {
                                  attrs: {
                                    disabled: "",
                                    size: "mini",
                                    type:
                                      _vm.orderStatusColorMap[
                                        scope.row.ordersSeller.status
                                      ]
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.ordersSeller.status) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._l(
                            ["total", "taxedAmount", "grandTotal"],
                            function(field) {
                              return _c(
                                "div",
                                { key: field },
                                [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.totalColumn[field]) + ":")
                                  ]),
                                  _c("inr", {
                                    attrs: {
                                      number: scope.row.ordersSeller[field]
                                    }
                                  })
                                ],
                                1
                              )
                            }
                          ),
                          scope.row.ordersSeller.status === "Partial Payment"
                            ? _c("div", [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(_vm.totalColumn["amountReceived"]) +
                                      ":"
                                  )
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row["amountReceived"]) +
                                    " "
                                )
                              ])
                            : _vm._e()
                        ]
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { width: "150px", align: "center", label: "Attachments" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._l([1, 2, 3], function(number, index) {
                      return [
                        scope.row["attachment" + number]
                          ? _c(
                              "a",
                              {
                                key: index,
                                staticClass: "download-file",
                                attrs: {
                                  href: scope.row["attachment" + number],
                                  download: "",
                                  target: "_blank"
                                }
                              },
                              [
                                _c("thumb", {
                                  attrs: {
                                    shape: "square",
                                    fit: "cover",
                                    size: 50,
                                    src: scope.row["attachment" + number]
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "150px",
              align: "center",
              label: "Ticket Status",
              prop: "status"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          disabled: scope.row.status !== "Open",
                          size: "mini",
                          type: _vm.statusColorMap[scope.row.status]
                        }
                      },
                      [_vm._v(" " + _vm._s(scope.row.status) + " ")]
                    ),
                    _c("span", {
                      staticClass: "text-italic text-small",
                      domProps: { innerHTML: _vm._s(scope.row.comments) }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "180px",
              align: "center",
              label: "Ticket Approval Status",
              prop: "approvalStatus"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(" " + _vm._s(_vm.getapprovalStatus(scope.row)) + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.createdDate"),
              width: "140px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm._f("moment")(
                            scope.row.createdTimestamp,
                            "MMMM Do YYYY, hh:mm a"
                          ),
                          placement: "top"
                        }
                      },
                      [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("moment")(
                                  _vm._f("moment")(
                                    scope.row.createdTimestamp,
                                    "YYYY-MM-DD HH:mm:ss"
                                  ),
                                  "from"
                                )
                              )
                          )
                        ])
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { width: "150px", align: "center", label: "Close Ticket" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status === "Closed"
                      ? [
                          _c("span", {
                            staticClass: "text-italic text-small",
                            domProps: {
                              innerHTML: _vm._s(scope.row.closureComments)
                            }
                          })
                        ]
                      : _vm._e(),
                    ["Open"].indexOf(scope.row.status) > -1
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "success",
                              size: "small",
                              icon: "el-icon-edit"
                            },
                            on: {
                              click: function($event) {
                                return _vm.closeTicket(scope.row)
                              }
                            }
                          },
                          [_vm._v(" Close Ticket ")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "200px",
              align: "center",
              label: "Change Approval Status"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          size: "small",
                          icon: "el-icon-edit"
                        },
                        on: {
                          click: function($event) {
                            return _vm.openStatusChangePrompt(scope.row)
                          }
                        }
                      },
                      [_vm._v(" Change Status ")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.isApprovalModalVisible,
            title: "Change Approval Status"
          },
          on: {
            "update:visible": function($event) {
              _vm.isApprovalModalVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Approval Status",
                    name: "approvalStatus",
                    prop: "approvalStatus"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "Select status" },
                      model: {
                        value: _vm.formData.approvalStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "approvalStatus", $$v)
                        },
                        expression: "formData.approvalStatus"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "Approved", value: "Approved" }
                      }),
                      _c("el-option", {
                        attrs: { label: "Not Approved", value: "Not Approved" }
                      }),
                      _c("el-option", {
                        attrs: { label: "Queried", value: "Queried" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { label: "Asignee to", prop: "asigneeId" },
                  model: {
                    value: _vm.formData.asigneeId,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "asigneeId", $$v)
                    },
                    expression: "formData.asigneeId"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        clearable: "",
                        loading: _vm.asigneeListLoading,
                        name: "Asignee",
                        placeholder: "Select Asignee"
                      },
                      model: {
                        value: _vm.formData.asigneeId,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "asigneeId", $$v)
                        },
                        expression: "formData.asigneeId"
                      }
                    },
                    _vm._l(_vm.asigneeList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.fullName, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.formData.approvalStatus === "Approved"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Approval Comments",
                        prop: "approvedComments"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          name: "approvedComments",
                          required: "",
                          placeholder: "Approval Comments"
                        },
                        model: {
                          value: _vm.formData.approvedComments,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "approvedComments", $$v)
                          },
                          expression: "formData.approvedComments"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formData.approvalStatus === "Not Approved"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Not Approved Comments",
                        prop: "notApprovedComments"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          name: "notApprovedComments",
                          required: "",
                          placeholder: "Not Approved Comments"
                        },
                        model: {
                          value: _vm.formData.notApprovedComments,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "notApprovedComments", $$v)
                          },
                          expression: "formData.notApprovedComments"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formData.approvalStatus === "Queried"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Queried Comments",
                        prop: "queryComments"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          name: "queryComments",
                          required: "",
                          placeholder: "Queried Comments"
                        },
                        model: {
                          value: _vm.formData.queryComments,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "queryComments", $$v)
                          },
                          expression: "formData.queryComments"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.cancelApprovalChange } }, [
                _vm._v(" Cancel ")
              ]),
              _c(
                "el-button",
                {
                  attrs: { rules: _vm.rules, type: "primary" },
                  on: { click: _vm.submitApprovalChange }
                },
                [_vm._v(" Submit ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }