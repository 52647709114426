"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sendRecoveryMail = exports.fileTransferHeaders = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = exports.fileTransferHeaders = {};
var sendRecoveryMail = exports.sendRecoveryMail = function sendRecoveryMail(data) {
  return (0, _request.default)({
    url: '/queue/email',
    method: 'post',
    data: data
  });
};