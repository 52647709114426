"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
var _typeof = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/typeof");
require("core-js/modules/es.array.filter");
require("core-js/modules/es.array.for-each");
require("core-js/modules/es.function.name");
require("core-js/modules/es.object.keys");
require("core-js/modules/web.dom-collections.for-each");
require("C:\\Users\\nasib\\Downloads\\desktop (1)\\tbadmin\\tradebridge-admin\\tradebridgeadmin\\node_modules\\core-js\\modules\\es.array.iterator.js");
require("C:\\Users\\nasib\\Downloads\\desktop (1)\\tbadmin\\tradebridge-admin\\tradebridgeadmin\\node_modules\\core-js\\modules\\es.promise.js");
require("C:\\Users\\nasib\\Downloads\\desktop (1)\\tbadmin\\tradebridge-admin\\tradebridgeadmin\\node_modules\\core-js\\modules\\es.object.assign.js");
require("C:\\Users\\nasib\\Downloads\\desktop (1)\\tbadmin\\tradebridge-admin\\tradebridgeadmin\\node_modules\\core-js\\modules\\es.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
require("normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
require("@/styles/element-variables.scss");
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("@/App.vue"));
var _store = _interopRequireDefault(require("@/store"));
var _app = require("@/store/modules/app");
var _router = _interopRequireDefault(require("@/router"));
var _lang = _interopRequireDefault(require("@/lang"));
require("@/icons/components");
require("@/permission");
require("@/utils/error-log");
require("@/pwa/register-service-worker");
var directives = _interopRequireWildcard(require("@/directives"));
var filters = _interopRequireWildcard(require("@/filters"));
var _vueMoment = _interopRequireDefault(require("vue-moment"));
var _vuePluginLoadScript = _interopRequireDefault(require("vue-plugin-load-script"));
var _vueQrcode = _interopRequireDefault(require("@chenfengyuan/vue-qrcode"));
var _vueCtkDateTimePicker = _interopRequireDefault(require("vue-ctk-date-time-picker"));
require("vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css");
require("element-ui/lib/theme-chalk/display.css");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) { if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } } return n.default = e, t && t.set(e, n), n; }
_vue.default.component('VueCtkDateTimePicker', _vueCtkDateTimePicker.default);
_vue.default.use(_elementUi.default, {
  size: _app.AppModule.size,
  i18n: function i18n(key, value) {
    return _lang.default.t(key, value);
  }
});
_vue.default.use(_vueMoment.default);
_vue.default.use(_vuePluginLoadScript.default);
_vue.default.component(_vueQrcode.default.name, _vueQrcode.default);
_vue.default.use(_vueSvgicon.default, {
  tagName: 'svg-icon',
  defaultWidth: '1em',
  defaultHeight: '1em'
});
// Register global directives
Object.keys(directives).forEach(function (key) {
  _vue.default.directive(key, directives[key]);
});
// Register global filter functions
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false;
new _vue.default({
  router: _router.default,
  store: _store.default,
  i18n: _lang.default,
  render: function render(h) {
    return h(_App.default);
  }
}).$mount('#app');