"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateOrganization = exports.getOrganizations = exports.getOrganizationToVerify = exports.getOrganizationByUser = exports.getOrganizationById = exports.fileTransferHeaders = exports.deleteOrganization = exports.defaultOrganizationData = exports.createOrganization = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = exports.fileTransferHeaders = {};
var defaultOrganizationData = exports.defaultOrganizationData = {
  id: 0,
  enabled: true,
  name: '',
  gstNumber: '',
  panNumber: '',
  fssaiNumber: '',
  gstDocument: '',
  panDocument: '',
  fssaiDocument: '',
  isVerified: null,
  isDocumentUploaded: null,
  users: {
    id: 0,
    fullName: '',
    mobileNumber: ''
  }
};
var getOrganizations = exports.getOrganizations = function getOrganizations(params) {
  return (0, _request.default)({
    url: '/Organizations',
    method: 'get',
    params: params
  });
};
var getOrganizationById = exports.getOrganizationById = function getOrganizationById(id) {
  return (0, _request.default)({
    url: "/Organizations/".concat(id),
    method: 'get'
  });
};
var updateOrganization = exports.updateOrganization = function updateOrganization(id, data) {
  return (0, _request.default)({
    url: "/Organizations/".concat(id),
    method: 'patch',
    data: data
  });
};
var deleteOrganization = exports.deleteOrganization = function deleteOrganization(id) {
  return (0, _request.default)({
    url: "/Organizations/".concat(id),
    method: 'delete'
  });
};
var createOrganization = exports.createOrganization = function createOrganization(data) {
  return (0, _request.default)({
    url: '/Organizations/',
    method: 'post',
    data: data
  });
};
var getOrganizationByUser = exports.getOrganizationByUser = function getOrganizationByUser(data) {
  return (0, _request.default)({
    url: '/Organizations/getOrganizationByUser',
    method: 'post',
    data: data
  });
};
var getOrganizationToVerify = exports.getOrganizationToVerify = function getOrganizationToVerify(data) {
  return (0, _request.default)({
    url: '/Organizations/getOrganizationToVerify',
    method: 'get',
    data: data
  });
};