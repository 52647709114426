"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateUserNotifications = exports.updateHomeNotifications = exports.getHomeNotificationsById = exports.getHomeNotifications = exports.getFilters = exports.deleteHomeNotifications = exports.defaultHomeNotificationsData = exports.createHomeNotifications = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultHomeNotificationsData = exports.defaultHomeNotificationsData = {
  id: 0,
  enabled: true,
  title: '',
  message: '',
  link: null,
  page: null,
  image: null,
  params: null,
  userId: null,
  fileName: '',
  user: {
    id: null,
    name: ''
  }
};
var getHomeNotifications = exports.getHomeNotifications = function getHomeNotifications(params) {
  return (0, _request.default)({
    url: '/home-notifications',
    method: 'get',
    params: params
  });
};
var getHomeNotificationsById = exports.getHomeNotificationsById = function getHomeNotificationsById(id) {
  return (0, _request.default)({
    url: "/home-notifications/".concat(id),
    method: 'get'
  });
};
var updateHomeNotifications = exports.updateHomeNotifications = function updateHomeNotifications(id, data) {
  return (0, _request.default)({
    url: "/home-notifications/".concat(id),
    method: 'patch',
    data: data
  });
};
var deleteHomeNotifications = exports.deleteHomeNotifications = function deleteHomeNotifications(id) {
  return (0, _request.default)({
    url: "/home-notifications/".concat(id),
    method: 'delete'
  });
};
var createHomeNotifications = exports.createHomeNotifications = function createHomeNotifications(data) {
  return (0, _request.default)({
    url: '/home-notifications/',
    method: 'post',
    data: data
  });
};
var updateUserNotifications = exports.updateUserNotifications = function updateUserNotifications(data) {
  return (0, _request.default)({
    url: '/home-notifications/updateUserNotifications/',
    method: 'post',
    data: data
  });
};
var getFilters = exports.getFilters = function getFilters(data) {
  return (0, _request.default)({
    url: '/notifications/fetch',
    method: 'post',
    data: data
  });
};