"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.array.concat");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateVRL = exports.getVRLsearch = exports.getVRLs = exports.getVRLByStateId = exports.getVRLById = exports.getVRLByFromStateId = exports.deleteVRL = exports.defaultVRLData = exports.createVRL = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultVRLData = exports.defaultVRLData = {
  id: 0,
  enabled: true,
  fromStateId: undefined,
  toStateId: undefined,
  warehouseCharges: undefined,
  homeDeliveryCharges: undefined
};
var getVRLsearch = exports.getVRLsearch = function getVRLsearch(params) {
  return (0, _request.default)({
    url: '/vrls',
    method: 'get',
    params: params
  });
};
var getVRLs = exports.getVRLs = function getVRLs(params) {
  return (0, _request.default)({
    url: '/vrl-delivery-locations',
    method: 'get',
    params: params
  });
};
var getVRLById = exports.getVRLById = function getVRLById(id) {
  return (0, _request.default)({
    url: "/vrl-delivery-locations/".concat(id),
    method: 'get'
  });
};
var getVRLByStateId = exports.getVRLByStateId = function getVRLByStateId(id) {
  return (0, _request.default)({
    url: "/vrls/listByState/".concat(id),
    method: 'get'
  });
};
var getVRLByFromStateId = exports.getVRLByFromStateId = function getVRLByFromStateId(stateId) {
  return (0, _request.default)({
    url: "/vrl-delivery-locations?filter=fromStateId||$eq||".concat(stateId, "&or=toStateId||$eq||").concat(stateId),
    method: 'get'
  });
};
var updateVRL = exports.updateVRL = function updateVRL(id, data) {
  return (0, _request.default)({
    url: "/vrl-delivery-locations/".concat(id),
    method: 'patch',
    data: data
  });
};
var deleteVRL = exports.deleteVRL = function deleteVRL(id) {
  return (0, _request.default)({
    url: "/vrl-delivery-locations/".concat(id),
    method: 'delete'
  });
};
var createVRL = exports.createVRL = function createVRL(data) {
  return (0, _request.default)({
    url: '/vrl-delivery-locations/',
    method: 'post',
    data: data
  });
};