var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-form",
          {
            ref: "formData",
            staticClass: "demo-form",
            attrs: {
              model: _vm.formData,
              "status-icon": "",
              rules: _vm.rules,
              "label-position": "left"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "Status", required: "" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { name: "status", placeholder: "Select" },
                    model: {
                      value: _vm.formData.status,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "status", $$v)
                      },
                      expression: "formData.status"
                    }
                  },
                  _vm._l(_vm.orderOptions, function(item) {
                    return _c("el-option", {
                      key: item.key,
                      attrs: {
                        required: "",
                        label: item.label,
                        value: item.key
                      }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            ["Order Dispatched", "Order Delivered"].includes(
              _vm.formData.status
            )
              ? [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "Invoice Number", prop: "invoiceNumber" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          name: "invoiceNumber",
                          placeholder: "Invoice Number"
                        },
                        model: {
                          value: _vm.formData.invoiceNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "invoiceNumber", $$v)
                          },
                          expression: "formData.invoiceNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "LR Number", prop: "lrNumber" } },
                    [
                      _c("el-input", {
                        attrs: { name: "lrNumber", placeholder: "LR Number" },
                        model: {
                          value: _vm.formData.lrNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "lrNumber", $$v)
                          },
                          expression: "formData.lrNumber"
                        }
                      })
                    ],
                    1
                  )
                ]
              : _vm._e(),
            _vm.formData.status === "Order Dispatched"
              ? [
                  _c(
                    "el-form-item",
                    { attrs: { label: "LR Receipt", prop: "lrReceipt" } },
                    [
                      _c("file-upload", {
                        attrs: {
                          "file-list": _vm.lrReceipt,
                          url: "/orders/upload"
                        },
                        on: {
                          change: function($event) {
                            _vm.formData.lrReceipt = $event.response
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Invoice Attachment",
                        prop: "invoiceAttachment"
                      }
                    },
                    [
                      _c("file-upload", {
                        attrs: {
                          "file-list": _vm.invoiceAttachment,
                          url: "/orders/upload"
                        },
                        on: {
                          change: function($event) {
                            _vm.formData.invoiceAttachment = $event.response
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Quality COA", prop: "qualityCoa" } },
                    [
                      _c("el-input", {
                        attrs: {
                          name: "qualityCoa",
                          placeholder: "Quality COA"
                        },
                        model: {
                          value: _vm.formData.qualityCoa,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "qualityCoa", $$v)
                          },
                          expression: "formData.qualityCoa"
                        }
                      })
                    ],
                    1
                  )
                ]
              : _vm._e(),
            _vm.formData.status === "Delivery Confirmed"
              ? [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Is Paid", prop: "isPaid" } },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.formData.isPaid,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "isPaid", $$v)
                          },
                          expression: "formData.isPaid"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.formData.isPaid
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Seller Payment Date",
                            prop: "sellerPaymentDate"
                          }
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              placeholder: "Select return date",
                              format: "dd-MM-yyyy"
                            },
                            model: {
                              value: _vm.formData.sellerPaymentDate,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "sellerPaymentDate", $$v)
                              },
                              expression: "formData.sellerPaymentDate"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Return Amount", prop: "returnAmount" } },
                    [
                      _c("el-input-number", {
                        attrs: { min: 0, precision: 2 },
                        model: {
                          value: _vm.formData.returnAmount,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "returnAmount", $$v)
                          },
                          expression: "formData.returnAmount"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Return Quantity",
                        prop: "returnQuantity"
                      }
                    },
                    [
                      _c("el-input-number", {
                        attrs: { min: 0, precision: 2 },
                        model: {
                          value: _vm.formData.returnQuantity,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "returnQuantity", $$v)
                          },
                          expression: "formData.returnQuantity"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Return Date", prop: "returnDate" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          placeholder: "Select return date",
                          format: "dd-MM-yyyy"
                        },
                        model: {
                          value: _vm.formData.returnDate,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "returnDate", $$v)
                          },
                          expression: "formData.returnDate"
                        }
                      })
                    ],
                    1
                  )
                ]
              : _vm._e(),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      },
                      { name: "waves", rawName: "v-waves" }
                    ],
                    attrs: { type: "success" },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    attrs: { type: "reset" },
                    on: { click: _vm.resetForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                )
              ],
              1
            )
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }