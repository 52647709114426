"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'banner': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M7.64 122.484l34.821 199.078c138.386-43.145 266.609-50.029 427.078 0l34.776-198.814C338.137 59.352 172.509 63.676 7.64 122.484zM59.526 71.155C56.71 55.007 41.455 43.987 24.808 46.809 8.54 49.702-2.429 65.163.463 81.57l1.928 10.933c18.024-6.427 28.937-10.207 57.686-18.224l-.551-3.124zM106.036 334.888c-26.429 6.265-33.38 8.688-58.023 16.37l20.17 114.403 59.078-10.415-21.225-120.358zM487.22 46.824c-15.728-2.73-31.536 6.049-35.245 27.071 27.25 7.514 40.309 12.155 57.606 18.757l1.956-11.096c2.892-16.376-8.074-31.864-24.317-34.732zM405.957 334.886l-21.22 120.36 59.078 10.415 20.171-114.407c-24.796-7.731-31.65-10.122-58.029-16.368z"/>'
  }
});