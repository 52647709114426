"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.object.get-own-property-descriptor");
require("core-js/modules/es.object.to-string");
require("core-js/modules/es.string.iterator");
require("core-js/modules/es.weak-map");
require("core-js/modules/web.dom-collections.iterator");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _typeof2 = _interopRequireDefault(require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/typeof"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != (0, _typeof2.default)(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) { if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } } return n.default = e, t && t.set(e, n), n; }
var orderTracking = {
  path: "/order-tracking",
  component: _index.default,
  redirect: "noredirect",
  name: "Order Tracking",
  meta: {
    title: "orderTracking.title",
    icon: "star"
  },
  children: [{
    path: "/order-tracking-ashapura/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/order-tracking-ashapura/list.vue"));
      });
    },
    name: "orderTracking.ashapura.title",
    meta: {
      title: "orderTracking.ashapura.title",
      noCache: true,
      icon: "eye-on"
    }
  }, {
    path: "/order-tracking-ashapura/:id(.*)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/order-tracking-ashapura/components/AshapuraInfo.vue"));
      });
    },
    name: "orderTracking.info",
    props: {
      isEdit: true
    },
    meta: {
      title: "orderTracking.info",
      noCache: true,
      activeMenu: "/order-tracking-ashapura/list",
      hidden: true
    }
  }, {
    path: "/order-tracking-others/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/order-tracking-others/list.vue"));
      });
    },
    name: "orderTracking.others.title",
    meta: {
      title: "orderTracking.others.title",
      noCache: true,
      icon: "eye-on"
    }
  }, {
    path: "/order-tracking-others/:id(.*)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/order-tracking-others/components/OthersInfo.vue"));
      });
    },
    name: "orderTracking.info",
    props: {
      isEdit: true
    },
    meta: {
      title: "orderTracking.info",
      noCache: true,
      activeMenu: "/order-tracking-others/list",
      hidden: true
    }
  }, {
    path: "/dispatch-delivery-tracker/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/dispatch-delivery-tracker/list.vue"));
      });
    },
    name: "orderTracking.others.title",
    meta: {
      title: "orderTracking.dispatchDeliveryTracker.title",
      noCache: true,
      icon: "eye-on"
    }
  }, {
    path: "/dispatch-delivery-tracker/:id(.*)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/dispatch-delivery-tracker/components/AshapuraInfo.vue"));
      });
    },
    name: "orderTracking.info",
    props: {
      isEdit: true
    },
    meta: {
      title: "orderTracking.info",
      noCache: true,
      activeMenu: "/dispatch-delivery-tracker/list",
      hidden: true
    }
  }, {
    path: "/dispatch-other-tracker/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/dispatch-other-tracker/list.vue"));
      });
    },
    name: "orderTracking.dispatchOtherTracker.title",
    meta: {
      title: "orderTracking.dispatchOtherTracker.title",
      noCache: true,
      icon: "eye-on"
    }
  }, {
    path: "/dispatch-other-tracker/:id(.*)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/dispatch-other-tracker/components/OthersInfo.vue"));
      });
    },
    name: "orderTracking.info",
    props: {
      isEdit: true
    },
    meta: {
      title: "orderTracking.info",
      noCache: true,
      activeMenu: "/dispatch-other-tracker/list",
      hidden: true
    }
  }]
};
var _default = exports.default = orderTracking;