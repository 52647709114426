var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-form",
          {
            ref: "formData",
            staticClass: "demo-form",
            attrs: {
              model: _vm.formData,
              "status-icon": "",
              rules: _vm.rules,
              "label-position": "left"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "Enabled", prop: "enabled" } },
              [
                _c("el-switch", {
                  model: {
                    value: _vm.formData.enabled,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "enabled", $$v)
                    },
                    expression: "formData.enabled"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Name", prop: "name" } },
              [
                _c("el-input", {
                  attrs: { name: "name", required: "", placeholder: "Name" },
                  model: {
                    value: _vm.formData.name,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "name", $$v)
                    },
                    expression: "formData.name"
                  }
                })
              ],
              1
            ),
            _c(
              "el-checkbox",
              {
                staticClass: "filter-item",
                model: {
                  value: _vm.formData.allowExport,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "allowExport", $$v)
                  },
                  expression: "formData.allowExport"
                }
              },
              [_vm._v(" Allow Export ")]
            ),
            _c(
              "el-checkbox",
              {
                staticClass: "filter-item",
                model: {
                  value: _vm.formData.allowEdit,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "allowEdit", $$v)
                  },
                  expression: "formData.allowEdit"
                }
              },
              [_vm._v(" Allow Edit ")]
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "pages", label: "Pages" } },
              [
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "16px",
                      "margin-block": "0px 5px"
                    }
                  },
                  [_c("b", [_vm._v("All")])]
                ),
                _vm._l(_vm.pageList, function(page) {
                  return page.adminPagesMaster.menuName === "All"
                    ? _c("el-checkbox", {
                        key: page.id,
                        attrs: {
                          prop: "page",
                          name: "name",
                          label: page.pageName,
                          placeholder: page.pageName
                        },
                        on: {
                          change: function($event) {
                            return _vm.setAdminPages(page, $event)
                          }
                        },
                        model: {
                          value: page.checked,
                          callback: function($$v) {
                            _vm.$set(page, "checked", $$v)
                          },
                          expression: "page.checked"
                        }
                      })
                    : _vm._e()
                }),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "16px",
                      "margin-block": "0px 5px"
                    }
                  },
                  [_c("b", [_vm._v("Masters")])]
                ),
                _vm._l(_vm.pageList, function(page) {
                  return page.adminPagesMaster.menuName === "Masters"
                    ? _c("el-checkbox", {
                        key: page.id,
                        attrs: {
                          prop: "page",
                          name: "name",
                          label: page.pageName,
                          placeholder: page.pageName
                        },
                        on: {
                          change: function($event) {
                            return _vm.setAdminPages(page, $event)
                          }
                        },
                        model: {
                          value: page.checked,
                          callback: function($$v) {
                            _vm.$set(page, "checked", $$v)
                          },
                          expression: "page.checked"
                        }
                      })
                    : _vm._e()
                }),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "16px",
                      "margin-block": "0px 5px"
                    }
                  },
                  [_c("b", [_vm._v("Product Management")])]
                ),
                _vm._l(_vm.pageList, function(page) {
                  return page.adminPagesMaster.menuName === "Product Management"
                    ? _c("el-checkbox", {
                        key: page.id,
                        attrs: {
                          prop: "page",
                          name: "name",
                          label: page.pageName,
                          placeholder: page.pageName
                        },
                        on: {
                          change: function($event) {
                            return _vm.setAdminPages(page, $event)
                          }
                        },
                        model: {
                          value: page.checked,
                          callback: function($$v) {
                            _vm.$set(page, "checked", $$v)
                          },
                          expression: "page.checked"
                        }
                      })
                    : _vm._e()
                }),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "16px",
                      "margin-block": "0px 5px"
                    }
                  },
                  [_c("b", [_vm._v("Customer Management")])]
                ),
                _vm._l(_vm.pageList, function(page) {
                  return page.adminPagesMaster.menuName ===
                    "Customer Management"
                    ? _c("el-checkbox", {
                        key: page.id,
                        attrs: {
                          prop: "page",
                          name: "name",
                          label: page.pageName,
                          placeholder: page.pageName
                        },
                        on: {
                          change: function($event) {
                            return _vm.setAdminPages(page, $event)
                          }
                        },
                        model: {
                          value: page.checked,
                          callback: function($$v) {
                            _vm.$set(page, "checked", $$v)
                          },
                          expression: "page.checked"
                        }
                      })
                    : _vm._e()
                }),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "16px",
                      "margin-block": "0px 5px"
                    }
                  },
                  [_c("b", [_vm._v("Order Management")])]
                ),
                _vm._l(_vm.pageList, function(page) {
                  return page.adminPagesMaster.menuName === "Order Management"
                    ? _c("el-checkbox", {
                        key: page.id,
                        attrs: {
                          prop: "page",
                          name: "name",
                          label: page.pageName,
                          placeholder: page.pageName
                        },
                        on: {
                          change: function($event) {
                            return _vm.setAdminPages(page, $event)
                          }
                        },
                        model: {
                          value: page.checked,
                          callback: function($$v) {
                            _vm.$set(page, "checked", $$v)
                          },
                          expression: "page.checked"
                        }
                      })
                    : _vm._e()
                }),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "16px",
                      "margin-block": "0px 5px"
                    }
                  },
                  [_c("b", [_vm._v("Subscription Management")])]
                ),
                _vm._l(_vm.pageList, function(page) {
                  return page.adminPagesMaster.menuName ===
                    "Subscription Management"
                    ? _c("el-checkbox", {
                        key: page.id,
                        attrs: {
                          prop: "page",
                          name: "name",
                          label: page.pageName,
                          placeholder: page.pageName
                        },
                        on: {
                          change: function($event) {
                            return _vm.setAdminPages(page, $event)
                          }
                        },
                        model: {
                          value: page.checked,
                          callback: function($$v) {
                            _vm.$set(page, "checked", $$v)
                          },
                          expression: "page.checked"
                        }
                      })
                    : _vm._e()
                }),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "16px",
                      "margin-block": "0px 5px"
                    }
                  },
                  [_c("b", [_vm._v("Order Tracking")])]
                ),
                _vm._l(_vm.pageList, function(page) {
                  return page.adminPagesMaster.menuName === "Order Tracking"
                    ? _c("el-checkbox", {
                        key: page.id,
                        attrs: {
                          prop: "page",
                          name: "name",
                          label: page.pageName,
                          placeholder: page.pageName
                        },
                        on: {
                          change: function($event) {
                            return _vm.setAdminPages(page, $event)
                          }
                        },
                        model: {
                          value: page.checked,
                          callback: function($$v) {
                            _vm.$set(page, "checked", $$v)
                          },
                          expression: "page.checked"
                        }
                      })
                    : _vm._e()
                }),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "16px",
                      "margin-block": "0px 5px"
                    }
                  },
                  [_c("b", [_vm._v("Tickets Management")])]
                ),
                _vm._l(_vm.pageList, function(page) {
                  return page.adminPagesMaster.menuName === "Tickets Management"
                    ? _c("el-checkbox", {
                        key: page.id,
                        attrs: {
                          prop: "page",
                          name: "name",
                          label: page.pageName,
                          placeholder: page.pageName
                        },
                        on: {
                          change: function($event) {
                            return _vm.setAdminPages(page, $event)
                          }
                        },
                        model: {
                          value: page.checked,
                          callback: function($$v) {
                            _vm.$set(page, "checked", $$v)
                          },
                          expression: "page.checked"
                        }
                      })
                    : _vm._e()
                }),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "16px",
                      "margin-block": "0px 5px"
                    }
                  },
                  [_c("b", [_vm._v("Notifications Scheduler")])]
                ),
                _vm._l(_vm.pageList, function(page) {
                  return page.adminPagesMaster.menuName ===
                    "Notifications Scheduler"
                    ? _c("el-checkbox", {
                        key: page.id,
                        attrs: {
                          prop: "page",
                          name: "name",
                          label: page.pageName,
                          placeholder: page.pageName
                        },
                        on: {
                          change: function($event) {
                            return _vm.setAdminPages(page, $event)
                          }
                        },
                        model: {
                          value: page.checked,
                          callback: function($$v) {
                            _vm.$set(page, "checked", $$v)
                          },
                          expression: "page.checked"
                        }
                      })
                    : _vm._e()
                })
              ],
              2
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      },
                      { name: "waves", rawName: "v-waves" }
                    ],
                    attrs: { type: "success" },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    attrs: { type: "reset" },
                    on: { click: _vm.resetForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }