import { render, staticRenderFns } from "./NotificationsDetail.vue?vue&type=template&id=691dfc8c&scoped=true&"
import script from "./NotificationsDetail.vue?vue&type=script&lang=ts&"
export * from "./NotificationsDetail.vue?vue&type=script&lang=ts&"
import style0 from "./NotificationsDetail.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./NotificationsDetail.vue?vue&type=style&index=1&id=691dfc8c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "691dfc8c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\nasib\\Downloads\\desktop (1)\\tbadmin\\tradebridge-admin\\tradebridgeadmin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('691dfc8c')) {
      api.createRecord('691dfc8c', component.options)
    } else {
      api.reload('691dfc8c', component.options)
    }
    module.hot.accept("./NotificationsDetail.vue?vue&type=template&id=691dfc8c&scoped=true&", function () {
      api.rerender('691dfc8c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/category-notifications/components/NotificationsDetail.vue"
export default component.exports