"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reviewCount = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var reviewCount = exports.reviewCount = function reviewCount(params) {
  return (0, _request.default)({
    url: '/reviews/count',
    method: 'get',
    params: params
  });
};