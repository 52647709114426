"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'message': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M0 21v59.6c0 11.5 8.5 21 19 21h28.7l1 26.4 28.1-26.5h32.1c10.6 0 19.1-9.4 19.1-21V21c0-11.6-8.5-21-19-21H19C8.6 0 0 9.4 0 21zm82.3 33c0-5.5 4-9.9 9-9.9s9.1 4.4 9.1 10c0 5.5-4 9.9-9 9.9s-9-4.4-9-10zm-27.6 0c0-5.5 4-9.9 9-9.9s9 4.4 9 10c0 5.5-4 9.9-9 9.9s-9-4.4-9-10zm-27 0c0-5.5 4-9.9 9-9.9s9 4.4 9 10c0 5.5-4 9.9-9 9.9s-9-4.4-9-10z"/>'
  }
});