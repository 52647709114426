var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "export-button-container" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-download" },
              on: { click: _vm.exportTable }
            },
            [_vm._v(" Export ")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { width: "80px", align: "center", label: "Id", prop: "id" }
          }),
          _c("el-table-column", {
            attrs: {
              width: "180px",
              align: "center",
              label: "Aging Brackets",
              prop: "agingBracket",
              type: "text"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.$index !== _vm.list.length - 1
                      ? [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: "/dispatch-delivery-tracker/" + scope.row.id
                              }
                            },
                            [
                              _c("el-link", { attrs: { type: "primary" } }, [
                                _vm._v(
                                  " " + _vm._s(scope.row.agingBracket) + " "
                                )
                              ])
                            ],
                            1
                          )
                        ]
                      : [_c("span", [_vm._v(_vm._s(scope.row.agingBracket))])]
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "180px",
              align: "center",
              label: "No of product orders",
              prop: "noOfProducts"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }