"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uploadBrandImages = exports.updateBrand = exports.getBrands = exports.getBrandById = exports.fileTransferHeaders = exports.deleteBrandImages = exports.deleteBrand = exports.defaultBrandData = exports.createBrand = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = exports.fileTransferHeaders = {};
var defaultBrandData = exports.defaultBrandData = {
  id: 0,
  enabled: true,
  fullName: '',
  image: '',
  vendorName: '',
  vendorIcon: '',
  description: '',
  brandBanners: []
};
var getBrands = exports.getBrands = function getBrands(params) {
  return (0, _request.default)({
    url: '/users/getBrands',
    method: 'get',
    params: params
  });
};
var getBrandById = exports.getBrandById = function getBrandById(id) {
  return (0, _request.default)({
    url: "/users/getBrandsById/".concat(id),
    method: 'get'
  });
};
var updateBrand = exports.updateBrand = function updateBrand(id, data) {
  return (0, _request.default)({
    url: "/brands/".concat(id),
    method: 'patch',
    data: data
  });
};
var deleteBrand = exports.deleteBrand = function deleteBrand(id) {
  return (0, _request.default)({
    url: "/brands/".concat(id),
    method: 'delete'
  });
};
var createBrand = exports.createBrand = function createBrand(data) {
  return (0, _request.default)({
    url: '/brands/',
    method: 'post',
    data: data
  });
};
var uploadBrandImages = exports.uploadBrandImages = function uploadBrandImages(data) {
  return (0, _request.default)({
    url: "/brand-banners",
    method: "patch",
    data: data
  });
};
var deleteBrandImages = exports.deleteBrandImages = function deleteBrandImages(id) {
  return (0, _request.default)({
    url: "/brand-banners/".concat(id),
    method: "delete"
  });
};