"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSubscription = exports.getSubscriptions = exports.getSubscriptionById = exports.deleteSubscription = exports.defaultSubscriptionData = exports.createSubscription = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultSubscriptionData = exports.defaultSubscriptionData = {
  id: 0,
  enabled: true,
  referenceNumber: undefined,
  planName: undefined,
  planValidity: undefined,
  planPrice: 0,
  taxRate: 0,
  taxedAmount: 0,
  status: null,
  validFrom: null,
  validTill: null,
  paymentGateway: null,
  userId: null,
  user: {
    id: undefined
  },
  planId: null,
  plan: {
    id: undefined
  }
};
var getSubscriptions = exports.getSubscriptions = function getSubscriptions(params) {
  return (0, _request.default)({
    url: '/subscriptions',
    method: 'get',
    params: params
  });
};
var getSubscriptionById = exports.getSubscriptionById = function getSubscriptionById(id) {
  return (0, _request.default)({
    url: "/subscriptions/".concat(id),
    method: 'get'
  });
};
var updateSubscription = exports.updateSubscription = function updateSubscription(id, data) {
  return (0, _request.default)({
    url: "/subscriptions/".concat(id),
    method: 'patch',
    data: data
  });
};
var deleteSubscription = exports.deleteSubscription = function deleteSubscription(id) {
  return (0, _request.default)({
    url: "/subscriptions/".concat(id),
    method: 'delete'
  });
};
var createSubscription = exports.createSubscription = function createSubscription(data) {
  return (0, _request.default)({
    url: '/subscriptions/',
    method: 'post',
    data: data
  });
};