var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-form",
          {
            ref: "formData",
            staticClass: "demo-form",
            attrs: {
              model: _vm.formData,
              "status-icon": "",
              rules: _vm.rules,
              "label-position": "left"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "Title", prop: "title" } },
              [
                _c("el-input", {
                  attrs: { name: "title", required: "", placeholder: "Title" },
                  model: {
                    value: _vm.formData.title,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "title", $$v)
                    },
                    expression: "formData.title"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Summary", prop: "summary" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "summary",
                    required: "",
                    placeholder: "Summary"
                  },
                  model: {
                    value: _vm.formData.summary,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "summary", $$v)
                    },
                    expression: "formData.summary"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Source Link", prop: "source" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "source",
                    required: "",
                    placeholder: "Source"
                  },
                  model: {
                    value: _vm.formData.source,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "source", $$v)
                    },
                    expression: "formData.source"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Source Name", prop: "sourceName" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "sourceName",
                    required: "",
                    placeholder: "Source Name"
                  },
                  model: {
                    value: _vm.formData.sourceName,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "sourceName", $$v)
                    },
                    expression: "formData.sourceName"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Source Image", prop: "sourceImage" } },
              [
                _c("file-upload", {
                  attrs: {
                    url: "/categories/image",
                    field: "file",
                    multiple: false,
                    "file-list": _vm.sourceImage
                  },
                  on: { change: _vm.mobileImageUploaded }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Cover", prop: "cover" } },
              [
                _c("file-upload", {
                  attrs: {
                    url: "/categories/image",
                    field: "file",
                    "file-list": _vm.cover
                  },
                  on: { change: _vm.desktopImageUploaded }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      },
                      { name: "waves", rawName: "v-waves" }
                    ],
                    attrs: { type: "success" },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    attrs: { type: "reset" },
                    on: { click: _vm.resetForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }