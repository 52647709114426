"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateOrdersTicket = exports.ordersTicketsCount = exports.getOrdersTickets = exports.getOrdersTicketById = exports.deleteOrdersTicket = exports.defaultOrdersTicketData = exports.createOrdersTicket = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultOrdersTicketData = exports.defaultOrdersTicketData = {
  id: 0,
  enabled: true,
  userId: 0,
  orderId: undefined,
  ordersSellerId: null,
  ordersProductId: 0,
  status: 'Open',
  approvalStatus: '',
  addedByType: 'admin',
  closureComments: '',
  comments: '',
  subject: '',
  attachment1: '',
  attachment2: '',
  attachment3: '',
  user: {
    id: null
  },
  order: {
    id: null
  },
  ordersProduct: {
    id: null,
    product: {
      id: null,
      name: ''
    }
  }
};
var getOrdersTickets = exports.getOrdersTickets = function getOrdersTickets(params) {
  return (0, _request.default)({
    url: '/orders-tickets',
    method: 'get',
    params: params
  });
};
var getOrdersTicketById = exports.getOrdersTicketById = function getOrdersTicketById(id) {
  return (0, _request.default)({
    url: "/orders-tickets/".concat(id),
    method: 'get'
  });
};
var updateOrdersTicket = exports.updateOrdersTicket = function updateOrdersTicket(id, data) {
  return (0, _request.default)({
    url: "/orders-tickets/".concat(id),
    method: 'patch',
    data: data
  });
};
var deleteOrdersTicket = exports.deleteOrdersTicket = function deleteOrdersTicket(id) {
  return (0, _request.default)({
    url: "/orders-tickets/".concat(id),
    method: 'delete'
  });
};
var createOrdersTicket = exports.createOrdersTicket = function createOrdersTicket(data) {
  return (0, _request.default)({
    url: '/orders-tickets/',
    method: 'post',
    data: data
  });
};
var ordersTicketsCount = exports.ordersTicketsCount = function ordersTicketsCount(params) {
  return (0, _request.default)({
    url: '/orders-tickets/count',
    method: 'get',
    params: params
  });
};