var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-form",
          {
            ref: "formData",
            staticClass: "demo-form",
            attrs: {
              model: _vm.formData,
              rules: _vm.rules,
              "label-position": "left"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "Enabled", prop: "enabled" } },
              [
                _c("el-switch", {
                  model: {
                    value: _vm.formData.enabled,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "enabled", $$v)
                    },
                    expression: "formData.enabled"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Title", prop: "title" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "title",
                    required: "",
                    maxlength: "30",
                    "show-word-limit": ""
                  },
                  model: {
                    value: _vm.formData.title,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "title", $$v)
                    },
                    expression: "formData.title"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Message", prop: "message" } },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    rows: 3,
                    autosize: { minRows: 3, maxRows: 4 },
                    name: "message",
                    maxlength: "100",
                    "show-word-limit": "",
                    required: ""
                  },
                  model: {
                    value: _vm.formData.message,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "message", $$v)
                    },
                    expression: "formData.message"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Image", prop: "image" } },
              [
                _c("file-upload", {
                  attrs: { "file-list": _vm.image },
                  on: { change: _vm.imageUpload }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticStyle: { "margin-bottom": "20px" },
                attrs: { label: "From category", prop: "category.id" }
              },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      filterable: "",
                      clearable: "",
                      loading: _vm.categoryListLoading,
                      name: "From category",
                      placeholder: "Select From Category"
                    },
                    model: {
                      value: _vm.formData.category.id,
                      callback: function($$v) {
                        _vm.$set(_vm.formData.category, "id", $$v)
                      },
                      expression: "formData.category.id"
                    }
                  },
                  _vm._l(_vm.categoryList, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticStyle: { "margin-bottom": "20px" },
                attrs: { label: "To category", prop: "params" }
              },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      filterable: "",
                      clearable: "",
                      loading: _vm.categoryListLoading,
                      name: "To category",
                      placeholder: "Select To Category"
                    },
                    model: {
                      value: _vm.formData.params,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "params", $$v)
                      },
                      expression: "formData.params"
                    }
                  },
                  _vm._l(_vm.categoryList, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { display: "flex" } },
              [
                _c("el-form-item", {
                  attrs: { label: "Date & time", prop: "scheduleTimestamp" }
                }),
                _c("VueCtkDateTimePicker", {
                  staticClass: "filter-item",
                  staticStyle: { width: "300px", "margin-left": "74px" },
                  attrs: {
                    formatted: "DD-MM-YYYY HH:mm",
                    required: "",
                    name: "scheduleTimestamp",
                    position: "top"
                  },
                  model: {
                    value: _vm.formData.scheduleTimestamp,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "scheduleTimestamp", $$v)
                    },
                    expression: "formData.scheduleTimestamp"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      },
                      { name: "waves", rawName: "v-waves" }
                    ],
                    attrs: { type: "success" },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    attrs: { type: "reset" },
                    on: { click: _vm.resetForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }