"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSurveyForm = exports.getSurveyForms = exports.getSurveyFormExport = exports.getSurveyFormById = exports.getFilters = exports.deleteSurveyForm = exports.defaultSurveyFormData = exports.createSurveyForm = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultSurveyFormData = exports.defaultSurveyFormData = {
  id: 0,
  isActive: true,
  name: "",
  questions: [],
  questionId: null
};
var getSurveyForms = exports.getSurveyForms = function getSurveyForms(params) {
  return (0, _request.default)({
    url: "/surveyform",
    method: "get",
    params: params
  });
};
var getSurveyFormById = exports.getSurveyFormById = function getSurveyFormById(id) {
  return (0, _request.default)({
    url: "/surveyform/".concat(id),
    method: "get"
  });
};
var updateSurveyForm = exports.updateSurveyForm = function updateSurveyForm(id, data) {
  return (0, _request.default)({
    url: "/surveyform/".concat(id),
    method: "patch",
    data: data
  });
};
var deleteSurveyForm = exports.deleteSurveyForm = function deleteSurveyForm(id) {
  return (0, _request.default)({
    url: "/surveyform/".concat(id),
    method: "delete"
  });
};
var createSurveyForm = exports.createSurveyForm = function createSurveyForm(data) {
  return (0, _request.default)({
    url: "/surveyform/saveSurvey",
    method: "post",
    data: data
  });
};
var getFilters = exports.getFilters = function getFilters(data) {
  return (0, _request.default)({
    url: "/surveyform/fetch",
    method: "post",
    data: data
  });
};
var getSurveyFormExport = exports.getSurveyFormExport = function getSurveyFormExport(data) {
  return (0, _request.default)({
    url: "/users-questions-answers/export ",
    method: "post",
    data: data
  });
};