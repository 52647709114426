"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateAdmin = exports.register = exports.logout = exports.login = exports.getAdmins = exports.getAdminInfo = exports.getAdminById = exports.getAdminByEmail = exports.deleteAdminById = exports.deleteAdmin = exports.defaultAdminData = exports.assignOnbAgent = exports.assignAgents = exports.addAgent = void 0;
var _objectSpread2 = _interopRequireDefault(require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/objectSpread2"));
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultAdminData = exports.defaultAdminData = {
  id: 0,
  enabled: true,
  fullName: "",
  emailAddress: "",
  mobileNumber: "",
  password: undefined,
  newPassword: undefined,
  avatar: "",
  passwordChangedTimestamp: null,
  adminsRoleId: 1,
  fileName: "",
  adminsRole: {
    id: null,
    name: ""
  }
};
var getAdmins = exports.getAdmins = function getAdmins(params) {
  return (0, _request.default)({
    url: "/admins",
    method: "get",
    params: params
  });
};
var getAdminById = exports.getAdminById = function getAdminById(id) {
  return (0, _request.default)({
    url: "/admins/".concat(id),
    method: "get"
  });
};
var getAdminInfo = exports.getAdminInfo = function getAdminInfo(data) {
  return (0, _request.default)({
    url: "/admins/me",
    method: "post",
    data: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data), {}, {
      createdBy: 0
    })
  });
};
var getAdminByEmail = exports.getAdminByEmail = function getAdminByEmail(adminemail) {
  return (0, _request.default)({
    url: "/admins/".concat(adminemail),
    method: "get"
  });
};
var updateAdmin = exports.updateAdmin = function updateAdmin(id, data) {
  return (0, _request.default)({
    url: "/admins/".concat(id),
    method: "patch",
    data: data
  });
};
var deleteAdmin = exports.deleteAdmin = function deleteAdmin(adminname) {
  return (0, _request.default)({
    url: "/admins/".concat(adminname),
    method: "delete"
  });
};
var deleteAdminById = exports.deleteAdminById = function deleteAdminById(id) {
  return (0, _request.default)({
    url: "/admins/".concat(id),
    method: "delete"
  });
};
var login = exports.login = function login(data) {
  return (0, _request.default)({
    url: "/admins/login",
    method: "post",
    data: data
  });
};
var logout = exports.logout = function logout() {
  return (0, _request.default)({
    url: "/admins/logout",
    method: "post"
  });
};
var register = exports.register = function register(data) {
  return (0, _request.default)({
    url: "/admins/",
    method: "post",
    data: data
  });
};
var addAgent = exports.addAgent = function addAgent(data) {
  return (0, _request.default)({
    url: "/user-agent-mapping/addAgents",
    method: "post",
    data: data
  });
};
var assignAgents = exports.assignAgents = function assignAgents(data) {
  return (0, _request.default)({
    url: "/user-agent-mapping/assignAgents",
    method: "post",
    data: data
  });
};
var assignOnbAgent = exports.assignOnbAgent = function assignOnbAgent(data) {
  return (0, _request.default)({
    url: "/user-agent-mapping/assignOnbAgent",
    method: "post",
    data: data
  });
};