"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateQuestionsAnswer = exports.getQuestionsAnswers = exports.getQuestionsAnswerById = exports.deleteQuestionsAnswer = exports.defaultQuestionsAnswerData = exports.createQuestionsAnswer = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultQuestionsAnswerData = exports.defaultQuestionsAnswerData = {
  id: 0,
  enabled: true,
  questionId: undefined,
  answer: '',
  question: {
    id: 0
  }
};
var getQuestionsAnswers = exports.getQuestionsAnswers = function getQuestionsAnswers(params) {
  return (0, _request.default)({
    url: '/questions-answers',
    method: 'get',
    params: params
  });
};
var getQuestionsAnswerById = exports.getQuestionsAnswerById = function getQuestionsAnswerById(id) {
  return (0, _request.default)({
    url: "/questions-answers/".concat(id),
    method: 'get'
  });
};
var updateQuestionsAnswer = exports.updateQuestionsAnswer = function updateQuestionsAnswer(id, data) {
  return (0, _request.default)({
    url: "/questions-answers/".concat(id),
    method: 'patch',
    data: data
  });
};
var deleteQuestionsAnswer = exports.deleteQuestionsAnswer = function deleteQuestionsAnswer(id) {
  return (0, _request.default)({
    url: "/questions-answers/".concat(id),
    method: 'delete'
  });
};
var createQuestionsAnswer = exports.createQuestionsAnswer = function createQuestionsAnswer(data) {
  return (0, _request.default)({
    url: '/questions-answers/',
    method: 'post',
    data: data
  });
};