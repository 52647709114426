"use strict";

require("core-js/modules/es.array.slice");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.articleStatusFilter = void 0;
Object.defineProperty(exports, "parseTime", {
  enumerable: true,
  get: function get() {
    return _utils.parseTime;
  }
});
exports.uppercaseFirstChar = void 0;
var _utils = require("@/utils");
// Set utils function parseTime to filter

// Filter for article status
var articleStatusFilter = exports.articleStatusFilter = function articleStatusFilter(status) {
  var statusMap = {
    published: 'success',
    draft: 'info',
    deleted: 'danger'
  };
  return statusMap[status];
};
// Filter to uppercase the first character
var uppercaseFirstChar = exports.uppercaseFirstChar = function uppercaseFirstChar(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
};