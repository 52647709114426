var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-form",
          {
            ref: "formData",
            staticClass: "demo-form",
            attrs: {
              model: _vm.formData,
              rules: _vm.rules,
              "label-position": "left",
              "status-icon": ""
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "Is Active", prop: "isActive" } },
              [
                _c("el-switch", {
                  model: {
                    value: _vm.formData.isActive,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "isActive", $$v)
                    },
                    expression: "formData.isActive"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Name", prop: "name" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "name",
                    required: "",
                    maxlength: "30",
                    "show-word-limit": ""
                  },
                  model: {
                    value: _vm.formData.name,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "name", $$v)
                    },
                    expression: "formData.name"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Questions", prop: "questions" } },
              _vm._l(_vm.questions, function(question) {
                return _c("el-checkbox", {
                  key: question.id,
                  attrs: {
                    prop: "question",
                    name: "question",
                    label: question.questionText,
                    placeholder: question.questionText
                  },
                  model: {
                    value: question.checked,
                    callback: function($$v) {
                      _vm.$set(question, "checked", $$v)
                    },
                    expression: "question.checked"
                  }
                })
              }),
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      },
                      { name: "waves", rawName: "v-waves" }
                    ],
                    attrs: { type: "success" },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    attrs: { type: "reset" },
                    on: { click: _vm.resetForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }