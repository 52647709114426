"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.waves = void 0;
require("./waves.css");
var waves = exports.waves = {
  bind: function bind(el, binding) {
    el.addEventListener('click', function (e) {
      var customOpts = Object.assign({}, binding.value);
      var opts = Object.assign({
        ele: el,
        type: 'hit',
        color: 'rgba(0, 0, 0, 0.15)' // 波纹颜色
      }, customOpts);
      var target = opts.ele;
      if (target) {
        target.style.position = 'relative';
        target.style.overflow = 'hidden';
        var rect = target.getBoundingClientRect();
        var ripple = target.querySelector('.waves-ripple');
        if (!ripple) {
          ripple = document.createElement('span');
          ripple.className = 'waves-ripple';
          ripple.style.height = ripple.style.width = Math.max(rect.width, rect.height) + 'px';
          target.appendChild(ripple);
        } else {
          ripple.className = 'waves-ripple';
        }
        switch (opts.type) {
          case 'center':
            ripple.style.top = rect.height / 2 - ripple.offsetHeight / 2 + 'px';
            ripple.style.left = rect.width / 2 - ripple.offsetWidth / 2 + 'px';
            break;
          default:
            ripple.style.top = (e.pageY - rect.top - ripple.offsetHeight / 2 - document.documentElement.scrollTop || document.body.scrollTop) + 'px';
            ripple.style.left = (e.pageX - rect.left - ripple.offsetWidth / 2 - document.documentElement.scrollLeft || document.body.scrollLeft) + 'px';
        }
        ripple.style.backgroundColor = opts.color;
        ripple.className = 'waves-ripple z-active';
        return false;
      }
    }, false);
  }
};