"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateBid = exports.prevOrder = exports.getBidsByUser = exports.getBidsByProduct = exports.getBids = exports.getBidById = exports.fileTransferHeaders = exports.deleteBid = exports.defaultBidData = exports.createBid = exports.bidsCount = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = exports.fileTransferHeaders = {};
var defaultBidData = exports.defaultBidData = {
  id: 0,
  enabled: true,
  userId: undefined,
  productId: undefined,
  orderId: undefined,
  productsVariationId: undefined,
  sellersProductsVariationId: undefined,
  status: '',
  bidQuantity: '',
  bidPrice: undefined,
  quotedPrice: undefined,
  productPrice: undefined,
  rejectReason: '',
  user: {
    id: null
  },
  product: {
    id: null
  },
  productsVariation: {
    id: null,
    unitPrice: undefined,
    salePrice: undefined
  },
  sellersProductsVariation: {
    id: null,
    unitPrice: undefined,
    salePrice: undefined
  },
  order: {
    id: null
  }
};
var getBids = exports.getBids = function getBids(params) {
  return (0, _request.default)({
    url: '/bids',
    method: 'get',
    params: params
  });
};
var getBidsByUser = exports.getBidsByUser = function getBidsByUser(user) {
  return (0, _request.default)({
    url: "/bids?filter=user.fullName||$contL||".concat(user),
    method: 'get'
  });
};
var getBidsByProduct = exports.getBidsByProduct = function getBidsByProduct(product) {
  return (0, _request.default)({
    url: "/bids?filter=product.name||$contL||".concat(product),
    method: 'get'
  });
};
var getBidById = exports.getBidById = function getBidById(id) {
  return (0, _request.default)({
    url: "/bids/".concat(id),
    method: 'get'
  });
};
var updateBid = exports.updateBid = function updateBid(id, data) {
  return (0, _request.default)({
    url: "/bids/".concat(id),
    method: 'patch',
    data: data
  });
};
var deleteBid = exports.deleteBid = function deleteBid(id) {
  return (0, _request.default)({
    url: "/bids/".concat(id),
    method: 'delete'
  });
};
var createBid = exports.createBid = function createBid(data) {
  return (0, _request.default)({
    url: '/bids/',
    method: 'post',
    data: data
  });
};
var prevOrder = exports.prevOrder = function prevOrder(data) {
  return (0, _request.default)({
    url: '/orders/getPreviousOrder/',
    method: 'post',
    data: data
  });
};
var bidsCount = exports.bidsCount = function bidsCount(params) {
  return (0, _request.default)({
    url: '/bids/count',
    method: 'get',
    params: params
  });
};