"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateState = exports.getStates = exports.getStateById = exports.deleteState = exports.defaultStateData = exports.createState = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultStateData = exports.defaultStateData = {
  id: 0,
  enabled: true,
  countryId: undefined,
  name: '',
  country: {
    id: undefined
  }
};
var getStates = exports.getStates = function getStates(params) {
  return (0, _request.default)({
    url: '/states',
    method: 'get',
    params: params
  });
};
var getStateById = exports.getStateById = function getStateById(id) {
  return (0, _request.default)({
    url: "/states/".concat(id),
    method: 'get'
  });
};
var updateState = exports.updateState = function updateState(id, data) {
  return (0, _request.default)({
    url: "/states/".concat(id),
    method: 'patch',
    data: data
  });
};
var deleteState = exports.deleteState = function deleteState(id) {
  return (0, _request.default)({
    url: "/states/".concat(id),
    method: 'delete'
  });
};
var createState = exports.createState = function createState(data) {
  return (0, _request.default)({
    url: '/states/',
    method: 'post',
    data: data
  });
};