"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uploadImageCategory = exports.updateCategory = exports.getCategoryById = exports.getCategoriesTree = exports.getCategories = exports.fileTransferHeaders = exports.deleteCategory = exports.defaultCategoryData = exports.createCategory = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = exports.fileTransferHeaders = {};
var defaultCategoryData = exports.defaultCategoryData = {
  id: 0,
  enabled: true,
  isMaincategory: true,
  isFOScategory: true,
  name: "",
  name_h: "",
  icon: "",
  image: "",
  description: "",
  priority: 0,
  featured: true,
  parent: null,
  bgcolor: null,
  amountStartingFrom: null
};
var getCategories = exports.getCategories = function getCategories(params) {
  return (0, _request.default)({
    url: "/categories",
    method: "get",
    params: params
  });
};
var getCategoriesTree = exports.getCategoriesTree = function getCategoriesTree() {
  return (0, _request.default)({
    url: "/categories/tree",
    method: "get"
  });
};
var getCategoryById = exports.getCategoryById = function getCategoryById(id) {
  return (0, _request.default)({
    url: "/categories/".concat(id),
    method: "get"
  });
};
var updateCategory = exports.updateCategory = function updateCategory(id, data) {
  return (0, _request.default)({
    url: "/categories/".concat(id),
    method: "patch",
    data: data
  });
};
var deleteCategory = exports.deleteCategory = function deleteCategory(id, data) {
  return (0, _request.default)({
    url: "/categories/".concat(id),
    method: "patch",
    data: data
  });
};
var createCategory = exports.createCategory = function createCategory(data) {
  return (0, _request.default)({
    url: "/categories",
    method: "post",
    data: data
  });
};
var uploadImageCategory = exports.uploadImageCategory = function uploadImageCategory(data) {
  return (0, _request.default)({
    url: "/image",
    method: "post",
    data: data
  });
};