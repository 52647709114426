"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.array.filter");
require("core-js/modules/es.array.for-each");
require("core-js/modules/es.array.includes");
require("core-js/modules/es.array.index-of");
require("core-js/modules/es.array.join");
require("core-js/modules/es.function.name");
require("core-js/modules/es.number.to-fixed");
require("core-js/modules/es.object.entries");
require("core-js/modules/es.object.to-string");
require("core-js/modules/es.regexp.constructor");
require("core-js/modules/es.regexp.exec");
require("core-js/modules/es.regexp.to-string");
require("core-js/modules/es.string.includes");
require("core-js/modules/es.string.match");
require("core-js/modules/es.string.replace");
require("core-js/modules/es.string.split");
require("core-js/modules/web.dom-collections.for-each");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toggleClass = exports.removeClass = exports.parseTime = exports.hasClass = exports.getStateFromGST = exports.getQuery = exports.getDescendantProp = exports.formatJson = exports.addClass = void 0;
var _slicedToArray2 = _interopRequireDefault(require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/slicedToArray"));
var _toConsumableArray2 = _interopRequireDefault(require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/toConsumableArray"));
var _createForOfIteratorHelper2 = _interopRequireDefault(require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/createForOfIteratorHelper"));
var _typeof2 = _interopRequireDefault(require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/typeof"));
var _moment = _interopRequireDefault(require("moment"));
// Parse the time to string
var parseTime = exports.parseTime = function parseTime(time, cFormat) {
  if (time === undefined || time === null) {
    return null;
  }
  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;
  if ((0, _typeof2.default)(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var timeStr = format.replace(/{([ymdhisa])+}/g, function (result, key) {
    var value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    if (result.length > 0 && value < 10) {
      return '0' + value;
    }
    return String(value) || '0';
  });
  return timeStr;
};
var formatJson = exports.formatJson = function formatJson(filterKeys, jsonData) {
  var _prod$seller, _prod$seller2, _prod$seller3;
  var module = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var out = [];
  var _iterator = (0, _createForOfIteratorHelper2.default)(jsonData),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var data = _step.value;
      if (data.amountFromWallet) {
        data['amountFromWallet'] = data.amountFromWallet / data.ordersProduct.length;
      }
      if (data.order && data.order.amountFromWallet) {
        data['amountFromWallet'] = data.order.amountFromWallet / data.ordersProduct.length;
      }
      if (data.order && data.order.platformFee) {
        data['platformFee'] = data.order.platformFee / data.ordersProduct.length;
      }
      if (data && data.convienceFee) {
        data['convienceFee'] = data.convienceFee / data.ordersProduct.length;
      }
      if (data && data.platformFee) {
        data['platformFee'] = data.platformFee / data.ordersProduct.length;
      }
      var dataExport = [];
      var _iterator2 = (0, _createForOfIteratorHelper2.default)(filterKeys),
        _step2;
      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var key = _step2.value;
          if (['createdTimestamp', 'timestamp', 'orderTimestamp'].indexOf(key) > -1) {
            dataExport.push(parseTime(data[key]));
          } else if (['enabled', 'isVerified', 'isSeller', 'isMobile', 'isPaid', 'lrAttachment', 'invoiceAttachment', 'subscriptionRequired'].indexOf(key) > -1) {
            dataExport.push(data[key] ? 'yes' : 'no');
          } else if (key.indexOf('.') > 0) {
            dataExport.push(getDescendantProp(data, key));
          } else if (key === 'balance') {
            var diff = data.grandTotal - data.amountReceived;
            if (diff < 0) {
              dataExport.push(0);
            } else {
              dataExport.push(diff);
            }
          } else if (key === 'products' || key === 'quantity' || key === 'totalAmount' || key === 'taxAmount' || key === 'rate') {
            // var products = ''
            // for (var i = 0; i < data.ordersProduct.length; i++) {
            //   if (data.ordersProduct[i].product) {
            //     products += `${data.ordersProduct[i].product.name}`
            //   }
            // }
            dataExport.push('');
          } else if (key === 'address' || key === 'shippingAddress' || key === 'orderAddress' || key === 'orderShippingAddress') {
            var addressObj = key === 'address' ? data.address : data.shippingAddress;
            if (key === 'orderAddress' || key === 'orderShippingAddress') {
              addressObj = key === 'orderAddress' ? data.order.address : data.order.shippingAddress;
            }
            if (addressObj) {
              var address = [];
              if (key === 'shippingAddress' && data.vrl) {
                var vrlName = 'VRL: ' + data.vrl.name;
                address.push(vrlName);
              }
              if (key === 'orderShippingAddress' && data.order.vrl) {
                var _vrlName = 'VRL: ' + data.order.vrl.name;
                address.push(_vrlName);
              }
              if (addressObj.line1) {
                address.push(addressObj.line1);
              }
              if (addressObj.line2) {
                address.push(addressObj.line2);
              }
              if (addressObj.city) {
                address.push(addressObj.city.name);
              }
              if (addressObj.state) {
                address.push(addressObj.state.name);
              }
              if (addressObj.country) {
                address.push(addressObj.country.name);
              }
              if (addressObj.pincode) {
                address.push(addressObj.pincode.pincode);
              }
              dataExport.push(address.filter(Boolean).join(',  '));
            } else {
              dataExport.push('');
            }
          } else if (key === 'sellerInvoiceNo' && data.seller) {
            // display seller invoice number in specific format
            var financialYear = (0, _moment.default)().format('YY') + '-' + (0, _moment.default)().add(1, 'y').format('YY');
            if (parseInt((0, _moment.default)().format('M')) < 4) {
              financialYear = (0, _moment.default)().subtract(1, 'y').format('YY') + '-' + (0, _moment.default)().format('YY');
            }
            dataExport.push(data.seller.fullName.substr(0, 3) + '/' + data.id + '/' + financialYear);
          } else {
            dataExport.push(data[key]);
          }
          // display catgeory in product export
          if (module === 'Product' && key === 'category' && data.category.length) {
            var categories = [];
            var _iterator4 = (0, _createForOfIteratorHelper2.default)(data.category),
              _step4;
            try {
              for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
                var category = _step4.value;
                categories.push(category.name);
              }
            } catch (err) {
              _iterator4.e(err);
            } finally {
              _iterator4.f();
            }
            dataExport[4] = categories.join(', ');
          }
          // if (module === 'Product' && key === 'isMainCategory' && data.category.length && data.productsCategories && data.productsCategories.length) {
          //   const isMainCategory = [];
          //   for (let i = 0; i < data.productsCategories.length; i++) {
          //     if (data.productsCategories[i].isMainCategory === true) {
          //       isMainCategory.push(data.category[i].name);
          //     }
          //   }
          //   dataExport[5] = isMainCategory
          // }
        }
        // console.log(dataExport)
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
      if (filterKeys.includes('products') && 'ordersProduct' in data) {
        var _iterator3 = (0, _createForOfIteratorHelper2.default)(data.ordersProduct),
          _step3;
        try {
          for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
            var prod = _step3.value;
            var temp = (0, _toConsumableArray2.default)(dataExport);
            switch (module) {
              case 'OrdersSeller':
                var productFreight = parseFloat(prod.freightCharges);
                var productFreightTax = parseFloat(prod.freightTaxAmount);
                temp[9] = getStateFromGST(data.seller.organization.gstNumber);
                temp[22] = prod.id;
                temp[23] = prod.product.id;
                temp[24] = prod.product.name;
                temp[25] = prod.quantity;
                temp[26] = +prod.salePrice > 0 ? prod.salePrice : prod.unitPrice;
                temp[27] = prod.subTotal;
                temp[28] = prod.taxRate;
                temp[29] = (+prod.taxedAmount - +prod.freightTaxAmount).toFixed(2);
                temp[30] = productFreight;
                temp[31] = productFreightTax;
                temp[32] = prod.freightTaxRate;
                temp[33] = (+prod.subTotal + +prod.taxedAmount + productFreight).toFixed(2);
                temp[38] = prod.tdsRate;
                temp[39] = prod.tdsAmount;
                temp[40] = prod.gstTdsRate;
                temp[41] = prod.gstTdsAmount;
                temp[42] = prod.commissionRate;
                temp[43] = prod.commissionAmount;
                temp[44] = prod.gstCommissionRate;
                temp[45] = prod.gstCommissionAmount;
                temp[46] = prod.totalPayableAmount;
                break;
              default:
                if (prod.product) {
                  temp[14] = prod.id;
                  temp[15] = prod.product.id;
                  temp[16] = prod.product.name;
                }
                temp[17] = prod.quantity;
                temp[18] = +prod.salePrice > 0 ? prod.salePrice : prod.unitPrice;
                temp[19] = prod.subTotal;
                temp[20] = prod.taxRate;
                temp[21] = prod.taxedAmount;
                temp[22] = prod.freightCharges;
                temp[23] = prod.freightTaxAmount;
                temp[29] = prod.tdsRate;
                temp[30] = prod.tdsAmount;
                temp[31] = prod.gstTdsRate;
                temp[32] = prod.gstTdsAmount;
                temp[33] = prod.commissionRate;
                temp[34] = prod.commissionAmount;
                temp[35] = prod.gstCommissionRate;
                temp[36] = prod.gstCommissionAmount;
                temp[37] = prod.totalPayableAmount;
                temp[38] = ((_prod$seller = prod.seller) === null || _prod$seller === void 0 ? void 0 : _prod$seller.fullName) || '-';
                temp[39] = ((_prod$seller2 = prod.seller) === null || _prod$seller2 === void 0 ? void 0 : _prod$seller2.emailAddress) || '-';
                temp[40] = ((_prod$seller3 = prod.seller) === null || _prod$seller3 === void 0 ? void 0 : _prod$seller3.mobileNumber) || '-';
                break;
            }
            out.push(temp);
          }
        } catch (err) {
          _iterator3.e(err);
        } finally {
          _iterator3.f();
        }
      } else {
        out.push(dataExport);
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  return out;
};
// Format and filter json data using filterKeys array
// export const formatJson = (filterKeys: any, jsonData: any) =>
//    jsonData.map((data: any) => filterKeys.map((key: string) => {
//     if (['createdTimestamp', 'timestamp'].indexOf(key) > -1) {
//       return parseTime(data[key])
//     } else if (key === 'enabled') {
//       return data[key] ? 'enabled' : 'disabled'
//     } else if (key.indexOf('.') > 0) {
//       return getDescendantProp(data, key)
//       // return data[key.substr(0, key.indexOf('.'))][key.substr(key.indexOf('.') + 1)]
//     } else if (key === 'balance') {
//       const diff = data.grandTotal - data.amountReceived
//       if (diff < 0) {
//         return 0
//       } else {
//         return diff
//       }
//     } else if (key === 'products') {
//       var products = ''
//       for (var i = 0; i < data.ordersProduct.length; i++) {
//         if (data.ordersProduct[i].product) {
//           products += `${data.ordersProduct[i].product.name}`
//         }
//       }
//       return products
//     }
//     else if (key === 'address') {
//       var addressObj = data.address
//       if(addressObj){
//       var address = [addressObj.line1,addressObj.line2]
//       if(addressObj.pincode){
//         address.push(addressObj.pincode.name)
//       }
//       if(addressObj.city){
//         address.push(addressObj.city.name)
//       }
//       if(addressObj.state){
//         address.push(addressObj.state.name)
//       }
//       if(addressObj.country){
//         address.push(addressObj.country.name)
//       }
//       address.filter(Boolean).join(",  ");
//       return address
//       }
//       else{
//         return ''
//       }
//     }
//     else {
//       return data[key]
//     }
//   })
//   )
// Check if an element has a class
var hasClass = exports.hasClass = function hasClass(ele, className) {
  return !!ele.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
};
// Add class to element
var addClass = exports.addClass = function addClass(ele, className) {
  if (!hasClass(ele, className)) ele.className += ' ' + className;
};
// Remove class from element
var removeClass = exports.removeClass = function removeClass(ele, className) {
  if (hasClass(ele, className)) {
    var reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
};
// Toggle class for the selected element
var toggleClass = exports.toggleClass = function toggleClass(ele, className) {
  if (!ele || !className) {
    return;
  }
  var classString = ele.className;
  var nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += '' + className;
  } else {
    classString = classString.substring(0, nameIndex) + classString.substring(nameIndex + className.length);
  }
  ele.className = classString;
};
var getQuery = exports.getQuery = function getQuery(query) {
  var processedQuery = Object.assign({}, query);
  // set filter as empty
  processedQuery.filter = [];
  // check for filter
  if ('filter' in query) {
    // loop over fields and check for values
    Object.entries(query.filter).forEach(function (_ref) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        field = _ref2[0],
        condition = _ref2[1];
      // check if field value has be set
      if (field in query && [undefined, ''].indexOf(query[field]) === -1 && condition !== '$notnull') {
        processedQuery.filter.push(field + '||' + condition + '||' + processedQuery[field]);
        delete processedQuery[field];
      } else if (condition === '$notnull') {
        processedQuery.filter.push(field + '||' + condition);
        delete processedQuery[field];
      }
    });
  }
  return processedQuery;
};
var getDescendantProp = exports.getDescendantProp = function getDescendantProp(obj, desc) {
  var arr = desc.split('.');
  while (arr.length && (obj = obj[arr.shift()])) {
    ;
  }
  return obj;
};
var getStateFromGST = exports.getStateFromGST = function getStateFromGST(gstNumber) {
  if (!gstNumber) {
    return 'NA';
  }
  var stateCode = gstNumber.substring(0, 2);
  var states = {
    '01': 'Jammu & Kashmir',
    '02': 'Himachal Pradesh',
    '03': 'Punjab',
    '04': 'Chandigarh',
    '05': 'Uttarakhand',
    '06': 'Haryana',
    '07': 'Delhi',
    '08': 'Rajasthan',
    '09': 'Uttar Pradesh',
    '10': 'Bihar',
    '11': 'Sikkim',
    '12': 'Arunachal Pradesh',
    '13': 'Nagaland',
    '14': 'Manipur',
    '15': 'Mizoram',
    '16': 'Tripura',
    '17': 'Meghalaya',
    '18': 'Assam',
    '19': 'West Bengal',
    '20': 'Jharkhand',
    '21': 'Orissa',
    '22': 'Chhattisgarh',
    '23': 'Madhya Pradesh',
    '24': 'Gujarat',
    '25': 'Daman & Diu',
    '26': 'Dadra & Nagar Haveli',
    '27': 'Maharashtra',
    '28': 'Andhra Pradesh (Old)',
    '29': 'Karnataka',
    '30': 'Goa',
    '31': 'Lakshadweep',
    '32': 'Kerala',
    '33': 'Tamil Nadu',
    '34': 'Puducherry',
    '35': 'Andaman & Nicobar Islands',
    '36': 'Telengana',
    '37': 'Andhra Pradesh (New)'
  };
  return states[stateCode];
};