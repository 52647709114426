"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'size': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M0 54.9h54.8V73H36.5v55H18.3V73.1H0V55zm127.9-36.6h-36V128H72.5V18.3h-36V0H128v18.3z"/>'
  }
});