"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateOrderMilestone = exports.getOrderMilestones = exports.getOrderMilestoneById = exports.deleteOrderMilestone = exports.defaultOrderMilestoneData = exports.createOrderMilestone = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultOrderMilestoneData = exports.defaultOrderMilestoneData = {
  id: 0,
  enabled: true,
  name: "",
  total: "",
  cartDiscountPrice: "",
  cartDiscountValidity: "",
  productId: null,
  product: {
    id: null,
    name: ""
  }
};
var getOrderMilestones = exports.getOrderMilestones = function getOrderMilestones(params) {
  return (0, _request.default)({
    url: "/order-milestone",
    method: "get",
    params: params
  });
};
var getOrderMilestoneById = exports.getOrderMilestoneById = function getOrderMilestoneById(id) {
  return (0, _request.default)({
    url: "/order-milestone/".concat(id),
    method: "get"
  });
};
var updateOrderMilestone = exports.updateOrderMilestone = function updateOrderMilestone(id, data) {
  return (0, _request.default)({
    url: "/order-milestone/".concat(id),
    method: "patch",
    data: data
  });
};
var deleteOrderMilestone = exports.deleteOrderMilestone = function deleteOrderMilestone(id) {
  return (0, _request.default)({
    url: "/order-milestone/".concat(id),
    method: "delete"
  });
};
var createOrderMilestone = exports.createOrderMilestone = function createOrderMilestone(data) {
  return (0, _request.default)({
    url: "/order-milestone/",
    method: "post",
    data: data
  });
};