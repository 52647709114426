var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-form",
          {
            ref: "formData",
            staticClass: "demo-form",
            attrs: {
              model: _vm.formData,
              "status-icon": "",
              "label-position": "left"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "Select products", prop: "segments" } },
              [
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          loading: _vm.productListLoading,
                          filterable: "",
                          remote: "",
                          "remote-method": _vm.productLists,
                          name: "product",
                          placeholder: "Enter Product Name"
                        },
                        model: {
                          value: _vm.newValue1,
                          callback: function($$v) {
                            _vm.newValue1 = $$v
                          },
                          expression: "newValue1"
                        }
                      },
                      _vm._l(_vm.productList, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "ul",
                  {
                    staticStyle: {
                      display: "flex",
                      "flex-wrap": "wrap",
                      gap: "5px",
                      "margin-top": "2px"
                    }
                  },
                  _vm._l(_vm.products, function(value, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          padding: "1px 6px",
                          "background-color": "#d0e7ff",
                          border: "1px solid #87cefa",
                          "border-radius": "3px",
                          color: "#005f9e",
                          "font-size": "14px"
                        }
                      },
                      [
                        _vm._v(" " + _vm._s(value) + " "),
                        _c(
                          "button",
                          {
                            staticStyle: {
                              "margin-left": "5px",
                              background: "none",
                              border: "none",
                              color: "#005f9e",
                              "font-weight": "bold",
                              cursor: "pointer",
                              "font-size": "14px"
                            },
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.removeValue1(index)
                              }
                            }
                          },
                          [_vm._v(" x ")]
                        )
                      ]
                    )
                  }),
                  0
                ),
                _c(
                  "button",
                  { attrs: { type: "button" }, on: { click: _vm.addValue1 } },
                  [_vm._v("Add Product")]
                )
              ]
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Deals Validity", prop: "validity" } },
              [
                _c("el-date-picker", {
                  attrs: {
                    type: "daterange",
                    "start-placeholder": "Start date",
                    "end-placeholder": "End date",
                    "default-time": ["00:00:00", "23:59:59"]
                  },
                  model: {
                    value: _vm.validity,
                    callback: function($$v) {
                      _vm.validity = $$v
                    },
                    expression: "validity"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      },
                      { name: "waves", rawName: "v-waves" }
                    ],
                    attrs: { type: "success" },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    attrs: { type: "reset" },
                    on: { click: _vm.resetForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }