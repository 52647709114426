"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateRole = exports.updateAdminRole = exports.getRoutes = exports.getRoles = exports.getRoleById = exports.getAdminRole = exports.getAdminPages = exports.deleteRole = exports.deleteAdminRole = exports.defaultAdminRoleData = exports.createRole = exports.createAdminRole = exports.adminRoles = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultAdminRoleData = exports.defaultAdminRoleData = {
  id: 0,
  enabled: true,
  allowExport: true,
  allowEdit: true,
  name: "",
  adminPages: []
};
var getRoles = exports.getRoles = function getRoles(params) {
  return (0, _request.default)({
    url: "/admins-roles",
    method: "get",
    params: params
  });
};
var adminRoles = exports.adminRoles = function adminRoles(data) {
  return (0, _request.default)({
    url: "/admins-roles/getRoles",
    method: "post",
    data: data
  });
};
var getAdminRole = exports.getAdminRole = function getAdminRole(id) {
  return (0, _request.default)({
    url: "/admins-roles/getRolesById",
    method: "post",
    data: {
      id: id
    }
  });
};
var updateAdminRole = exports.updateAdminRole = function updateAdminRole(data) {
  return (0, _request.default)({
    url: "/admins-roles/update",
    method: "post",
    data: data
  });
};
var createAdminRole = exports.createAdminRole = function createAdminRole(data) {
  return (0, _request.default)({
    url: "/admins-roles/add",
    method: "post",
    data: data
  });
};
var deleteAdminRole = exports.deleteAdminRole = function deleteAdminRole(id) {
  return (0, _request.default)({
    url: "/admins-roles/delete",
    method: "post",
    data: {
      id: id
    }
  });
};
var getAdminPages = exports.getAdminPages = function getAdminPages() {
  return (0, _request.default)({
    url: "admin-pages",
    method: "get",
    params: {
      filter: "enabled||eq||true"
    }
  });
};
var getRoleById = exports.getRoleById = function getRoleById(id) {
  return (0, _request.default)({
    url: "/admins-roles/".concat(id),
    method: "get"
  });
};
var updateRole = exports.updateRole = function updateRole(id, data) {
  return (0, _request.default)({
    url: "/admins-roles/".concat(id),
    method: "patch",
    data: data
  });
};
var deleteRole = exports.deleteRole = function deleteRole(id) {
  return (0, _request.default)({
    url: "/admins-roles/".concat(id),
    method: "delete"
  });
};
var createRole = exports.createRole = function createRole(data) {
  return (0, _request.default)({
    url: "/admins-roles/",
    method: "post",
    data: data
  });
};
var getRoutes = exports.getRoutes = function getRoutes(params) {
  return (0, _request.default)({
    url: "/routes",
    method: "get",
    params: params
  });
};