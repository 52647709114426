"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.object.get-own-property-descriptor");
require("core-js/modules/es.object.to-string");
require("core-js/modules/es.string.iterator");
require("core-js/modules/es.weak-map");
require("core-js/modules/web.dom-collections.iterator");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
var _typeof2 = _interopRequireDefault(require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/typeof"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var _admin = _interopRequireDefault(require("./modules/admin"));
var _customer = _interopRequireDefault(require("./modules/customer"));
var _master = _interopRequireDefault(require("./modules/master"));
var _notificationsScheduler = _interopRequireDefault(require("./modules/notifications-scheduler"));
var _order = _interopRequireDefault(require("./modules/order"));
var _orderTraking = _interopRequireDefault(require("./modules/order-traking"));
var _tickets = _interopRequireDefault(require("./modules/tickets"));
var _product = _interopRequireDefault(require("./modules/product"));
var _setting = _interopRequireDefault(require("./modules/setting"));
var _subscriptions = _interopRequireDefault(require("./modules/subscriptions"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != (0, _typeof2.default)(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) { if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } } return n.default = e, t && t.set(e, n), n; } /* Layout */ /* Router modules */ // import locationsRouter from './modules/locations'
// import notificationsRouter from "./modules/notifications";
_vue.default.use(_vueRouter.default);
/*
  Note: sub-menu only appear when children.length>=1
  Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
*/
/*
  name:'router-name'             the name field is required when using <keep-alive>, it should also match its component's name property
                                 detail see : https://vuejs.org/v2/guide/components-dynamic-async.html#keep-alive-with-Dynamic-Components
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    roles: ['superadmin','admin','executive']   will control the page roles (allow setting multiple roles)
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    hidden: true                 if true, this route will not show in the sidebar (default is false)
    alwaysShow: true             if true, will always show the root menu (default is false)
                                 if false, hide the root menu when has less or equal than one children route
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    noCache: true                if true, the page will not be cached (default is false)
    affix: true                  if true, the tag will affix in the tags-view
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
*/
/**
  ConstantRoutes
  a base page that does not have permission requirements
  all roles can be accessed
*/
var constantRoutes = exports.constantRoutes = [{
  path: "/redirect",
  component: _index.default,
  meta: {
    hidden: true
  },
  children: [{
    path: "/redirect/:path*",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/redirect/index.vue"));
      });
    }
  }]
}, {
  path: "/login",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/login/index.vue"));
    });
  },
  meta: {
    hidden: true
  }
}, {
  path: "/recover-password",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/login/password-recovery.vue"));
    });
  },
  meta: {
    hidden: true
  }
}, {
  path: "/reset-password/:hash(.*)",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/login/reset-password.vue"));
    });
  },
  meta: {
    hidden: true
  }
}, {
  path: "/auth-redirect",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/login/auth-redirect.vue"));
    });
  },
  meta: {
    hidden: true
  }
}, {
  path: "/404",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/error-page/404.vue"));
    });
  },
  meta: {
    hidden: true
  }
}, {
  path: "/401",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/error-page/401.vue"));
    });
  },
  meta: {
    hidden: true
  }
}, {
  path: "/",
  component: _index.default,
  redirect: "/dashboard",
  children: [{
    path: "dashboard",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/dashboard/index.vue"));
      });
    },
    name: "Dashboard",
    meta: {
      title: "dashboard",
      icon: "dashboard",
      affix: true
    }
  }]
}, {
  path: "/profile",
  component: _index.default,
  redirect: "/profile/index",
  meta: {
    hidden: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/profile/index.vue"));
      });
    },
    name: "Profile",
    meta: {
      title: "profile",
      icon: "user",
      noCache: true
    }
  }]
}];
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
var asyncRoutes = exports.asyncRoutes = [/** when your routing map is too long, you can split it into small modules **/
_admin.default, _setting.default, _master.default, _product.default, _customer.default, _order.default, _subscriptions.default, _orderTraking.default, _tickets.default,
// locationsRouter,
// notificationsRouter,
_notificationsScheduler.default,
// usersRouter,
{
  path: "*",
  redirect: "/404",
  meta: {
    hidden: true
  }
}];
var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: "history",
    scrollBehavior: function scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return {
          x: 0,
          y: 0
        };
      }
    },
    base: process.env.BASE_URL,
    routes: constantRoutes
  });
};
var router = createRouter();
// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = exports.default = router;