"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.object.get-own-property-descriptor");
require("core-js/modules/es.object.to-string");
require("core-js/modules/es.string.iterator");
require("core-js/modules/es.weak-map");
require("core-js/modules/web.dom-collections.iterator");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userSubMenu = void 0;
var _typeof2 = _interopRequireDefault(require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/typeof"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != (0, _typeof2.default)(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) { if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } } return n.default = e, t && t.set(e, n), n; }
var userSubMenu = exports.userSubMenu = [{
  path: "/users/list",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/users/list.vue"));
    });
  },
  name: "userManagement.users.title",
  meta: {
    title: "userManagement.users.title",
    icon: "user",
    noCache: true
  }
}, {
  path: "/users/add",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/users/manage.vue"));
    });
  },
  name: "userManagement.users.add",
  props: {
    isEdit: false
  },
  meta: {
    title: "userManagement.users.add",
    noCache: true,
    activeMenu: "/users/list",
    hidden: true
  }
}, {
  path: "/users/edit/:id(\\d+)",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/users/manage.vue"));
    });
  },
  name: "userManagement.users.edit",
  props: {
    isEdit: true
  },
  meta: {
    title: "userManagement.users.edit",
    noCache: true,
    activeMenu: "/users/list",
    hidden: true
  }
}, {
  path: "/users-wallet/list/:userId(\\d+)",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/users/wallet-list.vue"));
    });
  },
  name: "userManagement.wallet.title",
  meta: {
    title: "userManagement.wallet.title",
    icon: "user",
    noCache: true,
    activeMenu: "/users/list",
    hidden: true
  }
}, {
  path: "/users-wallet/add/:userId(\\d+)",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/users/wallet-manage.vue"));
    });
  },
  name: "userManagement.wallet.manage",
  props: {
    isEdit: true
  },
  meta: {
    title: "userManagement.wallet.manage",
    noCache: true,
    activeMenu: "/users/list",
    hidden: true
  }
}, {
  path: "seller-list",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/users/seller-list.vue"));
    });
  },
  name: "userManagement.seller.title",
  meta: {
    title: "userManagement.seller.title",
    noCache: true,
    icon: "user"
  }
}, {
  path: "/sellers/manage/:userId(\\d+)",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/users/seller-manage.vue"));
    });
  },
  name: "userManagement.seller.manage",
  props: {
    isEdit: true
  },
  meta: {
    title: "userManagement.seller.manage",
    noCache: true,
    activeMenu: "/users/list",
    hidden: true
  }
}, {
  path: "/users-documents/list",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/users-documents/list.vue"));
    });
  },
  name: "userManagement.usersDocuments.title",
  meta: {
    title: "userManagement.usersDocuments.title",
    icon: "documentation",
    noCache: true
  }
}, {
  path: "/validate-documents/list",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/validate-documents/list.vue"));
    });
  },
  name: "userManagement.validateDocuments.title",
  meta: {
    title: "userManagement.validateDocuments.title",
    icon: "documentation",
    noCache: true
  }
}, {
  path: "/users-documents/add",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/users-documents/manage.vue"));
    });
  },
  name: "userManagement.usersDocuments.add",
  props: {
    isEdit: false
  },
  meta: {
    title: "userManagement.usersDocuments.add",
    noCache: true,
    activeMenu: "/users/list",
    hidden: true
  }
}, {
  path: "/users-documents/edit/:id(\\d+)",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/users-documents/manage.vue"));
    });
  },
  name: "userManagement.usersDocuments.edit",
  props: {
    isEdit: true
  },
  meta: {
    title: "userManagement.usersDocuments.edit",
    noCache: true,
    activeMenu: "/users/list",
    hidden: true
  }
}, {
  path: "/validate-documents/edit/:id(\\d+)",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/validate-documents/manage.vue"));
    });
  },
  name: "userManagement.validateDocuments.edit",
  props: {
    isEdit: true
  },
  meta: {
    title: "userManagement.validateDocuments.edit",
    noCache: true,
    activeMenu: "/users/list",
    hidden: true
  }
}, {
  path: "/sellers-outstandings/list/:userId(\\d+)",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/sellers-outstandings/list.vue"));
    });
  },
  name: "userManagement.sellersOutstanding.title",
  meta: {
    title: "userManagement.sellersOutstanding.title",
    icon: "user",
    noCache: true,
    activeMenu: "/seller-list/list",
    hidden: true
  }
}, {
  path: "/sellers-outstandings/add/:ordersSellerId(\\d+)",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/sellers-outstandings/manage.vue"));
    });
  },
  name: "userManagement.sellersOutstanding.manage",
  props: {
    isEdit: false
  },
  meta: {
    title: "userManagement.sellersOutstanding.manage",
    noCache: true,
    activeMenu: "/seller-list/list",
    hidden: true
  }
}];