var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-form",
          {
            ref: "formData",
            staticClass: "demo-form",
            attrs: {
              model: _vm.formData,
              "status-icon": "",
              rules: _vm.rules,
              "label-position": "left"
            }
          },
          [
            _vm.isSuperAdmin()
              ? _c(
                  "el-form-item",
                  { attrs: { label: "Enabled", prop: "enabled" } },
                  [
                    _c("el-switch", {
                      model: {
                        value: _vm.formData.isBrandVerfied,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "isBrandVerfied", $$v)
                        },
                        expression: "formData.isBrandVerfied"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-form-item",
              { attrs: { label: "Seller Name", prop: "name" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "name",
                    required: "",
                    placeholder: "Name",
                    disabled: true
                  },
                  model: {
                    value: _vm.formData.fullName,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "fullName", $$v)
                    },
                    expression: "formData.fullName"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "User Mobile", prop: "mobileNumber" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "mobileNumber",
                    placeholder: "User Mobile",
                    disabled: true
                  },
                  model: {
                    value: _vm.formData.mobileNumber,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "mobileNumber", $$v)
                    },
                    expression: "formData.mobileNumber"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Brand Name", prop: "vendorName" } },
              [
                _c("el-input", {
                  attrs: { name: "vendorName", placeholder: "Brand Name" },
                  model: {
                    value: _vm.formData.vendorName,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "vendorName", $$v)
                    },
                    expression: "formData.vendorName"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Brand Image", prop: "vendorIcon" } },
              [
                _c("el-image", {
                  staticStyle: { width: "100px", height: "100px" },
                  attrs: {
                    src: _vm.formData.vendorIcon,
                    "preview-src-list": [_vm.formData.vendorIcon],
                    fit: "scale-down",
                    lazy: ""
                  }
                }),
                _c("file-upload", {
                  attrs: {
                    url: "/categories/image",
                    field: "file",
                    multiple: false,
                    "file-list": [_vm.formData.vendorIcon]
                  },
                  on: { change: _vm.mobileImageUploaded }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Brand Banners:" } },
              [
                _c("br"),
                _c("file-upload", {
                  attrs: {
                    url: "/common/upload",
                    drag: true,
                    "file-list": [],
                    "max-size": 1
                  },
                  on: { change: _vm.fileUploaded }
                }),
                _c("el-divider")
              ],
              1
            ),
            _c(
              "el-table",
              {
                ref: "dragTable",
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.formData.brandBanners,
                  "row-key": "id",
                  border: "",
                  fit: "",
                  "highlight-current-row": ""
                }
              },
              [
                _c("el-table-column", {
                  attrs: { width: "80", align: "center", label: "Drag" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("i", {
                            staticClass: "el-icon-rank drag-icon",
                            attrs: { "data-index": scope.$index }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { align: "center", label: "Image", width: "180" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("el-image", {
                            staticStyle: { width: "100px", height: "100px" },
                            attrs: {
                              src: scope.row.mobileImage,
                              "preview-src-list": [scope.row.mobileImage],
                              fit: "scale-down",
                              lazy: ""
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: _vm.$t("table.actions"),
                    width: "100"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          !scope.row.isCover
                            ? _c("el-button", {
                                attrs: {
                                  size: "small",
                                  type: "danger",
                                  icon: "el-icon-delete"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleDelete(
                                      scope.row,
                                      scope.$index
                                    )
                                  }
                                }
                              })
                            : _vm._e()
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      },
                      { name: "waves", rawName: "v-waves" }
                    ],
                    attrs: { type: "success" },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    attrs: { type: "reset" },
                    on: { click: _vm.resetForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }