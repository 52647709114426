var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-form",
          {
            ref: "formData",
            staticClass: "demo-form",
            attrs: {
              model: _vm.formData,
              "status-icon": "",
              rules: _vm.rules,
              "label-position": "left"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "Enabled", prop: "enabled" } },
              [
                _c("el-switch", {
                  model: {
                    value: _vm.formData.enabled,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "enabled", $$v)
                    },
                    expression: "formData.enabled"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Strip Banner", prop: "isStripBanner" } },
              [
                _c("el-switch", {
                  model: {
                    value: _vm.formData.isStripBanner,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "isStripBanner", $$v)
                    },
                    expression: "formData.isStripBanner"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "FOS Banner", prop: "isForFOS" } },
              [
                _c("el-switch", {
                  model: {
                    value: _vm.formData.isForFOS,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "isForFOS", $$v)
                    },
                    expression: "formData.isForFOS"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Desktop Image", prop: "desktopImage" } },
              [
                _c("file-upload", {
                  attrs: {
                    url: "/categories/image",
                    field: "file",
                    "file-list": _vm.desktopImage
                  },
                  on: { change: _vm.desktopImageUploaded }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Mobile Image", prop: "mobileImage" } },
              [
                _c("file-upload", {
                  attrs: {
                    url: "/categories/image",
                    field: "file",
                    multiple: false,
                    "file-list": _vm.mobileImage
                  },
                  on: { change: _vm.mobileImageUploaded }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Priority", prop: "priority" } },
              [
                _c("el-input-number", {
                  attrs: {
                    name: "priority",
                    required: "",
                    placeholder: "Priority"
                  },
                  model: {
                    value: _vm.formData.priority,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "priority", $$v)
                    },
                    expression: "formData.priority"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Name", prop: "name" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "name",
                    required: "",
                    placeholder: "Name",
                    maxlength: "25",
                    "show-word-limit": ""
                  },
                  model: {
                    value: _vm.formData.name,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "name", $$v)
                    },
                    expression: "formData.name"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Description", prop: "description" } },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    name: "description",
                    placeholder: "Description",
                    maxlength: "100",
                    "show-word-limit": ""
                  },
                  model: {
                    value: _vm.formData.description,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "description", $$v)
                    },
                    expression: "formData.description"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Validity", prop: "validity" } },
              [
                _c("el-date-picker", {
                  attrs: {
                    type: "daterange",
                    "start-placeholder": "Start date",
                    "end-placeholder": "End date",
                    "default-time": ["00:00:00", "23:59:59"]
                  },
                  model: {
                    value: _vm.validity,
                    callback: function($$v) {
                      _vm.validity = $$v
                    },
                    expression: "validity"
                  }
                })
              ],
              1
            ),
            _vm.formData.isStripBanner === false
              ? _c(
                  "el-form-item",
                  { attrs: { label: "Page", prop: "page" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          name: "page",
                          clearable: "",
                          required: "",
                          filterable: "",
                          "auto-complete": "off"
                        },
                        on: { change: _vm.resetParams },
                        model: {
                          value: _vm.formData.page,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "page", $$v)
                          },
                          expression: "formData.page"
                        }
                      },
                      _vm._l(_vm.pageList, function(page) {
                        return _c("el-option", {
                          key: page.key,
                          attrs: { label: page.value, value: page.key }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.formData.page || _vm.formData.isStripBanner
              ? _c(
                  "el-form-item",
                  { attrs: { label: "Type", prop: "type" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          name: "type",
                          clearable: "",
                          required: "",
                          filterable: "",
                          "auto-complete": "off"
                        },
                        on: { change: _vm.resetParams },
                        model: {
                          value: _vm.formData.type,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "type", $$v)
                          },
                          expression: "formData.type"
                        }
                      },
                      _vm._l(_vm.typeList, function(type) {
                        return _c("el-option", {
                          key: type,
                          attrs: { label: type, value: type }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.formData.type === "Product" || _vm.formData.type === "Category"
              ? _c(
                  "el-form-item",
                  {
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: { label: "Seller", prop: "sellerId" }
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          loading: _vm.sellerListLoading,
                          filterable: "",
                          remote: "",
                          clearable: "",
                          "remote-method": _vm.getSellerList,
                          name: "Seller",
                          placeholder: "Enter Product Name"
                        },
                        model: {
                          value: _vm.formData.sellerId,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "sellerId", $$v)
                          },
                          expression: "formData.sellerId"
                        }
                      },
                      _vm._l(_vm.sellerList, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.fullName, value: item.id }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.formData.type === "Category"
              ? _c(
                  "el-form-item",
                  {
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: { label: "Category redirect to", prop: "categoryId" }
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          filterable: "",
                          clearable: "",
                          loading: _vm.categoryListLoading,
                          name: "Category",
                          placeholder: "Select Category"
                        },
                        model: {
                          value: _vm.formData.categoryId,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "categoryId", $$v)
                          },
                          expression: "formData.categoryId"
                        }
                      },
                      _vm._l(_vm.categoryList, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.formData.page === "buyerProductDetails" ||
            _vm.formData.page === "buyerProductDetails1"
              ? _c(
                  "el-form-item",
                  {
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: { label: "Visibility", prop: "pageCategoryId" }
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          filterable: "",
                          clearable: "",
                          loading: _vm.categoryListLoading,
                          name: "Page Category",
                          placeholder: "Select Page Category"
                        },
                        model: {
                          value: _vm.formData.pageCategoryId,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "pageCategoryId", $$v)
                          },
                          expression: "formData.pageCategoryId"
                        }
                      },
                      _vm._l(_vm.categoryList, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.formData.type === "Product"
              ? _c(
                  "el-form-item",
                  {
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: {
                      label: "Product redirect to",
                      required: "",
                      prop: "productId"
                    }
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          loading: _vm.productListLoading,
                          filterable: "",
                          remote: "",
                          "remote-method": _vm.getProductList,
                          name: "Product",
                          placeholder: "Enter Product Name"
                        },
                        model: {
                          value: _vm.formData.productId,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "productId", $$v)
                          },
                          expression: "formData.productId"
                        }
                      },
                      _vm._l(_vm.productList, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.formData.type === "Link"
              ? _c(
                  "el-form-item",
                  { attrs: { label: "Link", prop: "link" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: { type: "link", name: "link", required: "" },
                        model: {
                          value: _vm.formData.link,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "link", $$v)
                          },
                          expression: "formData.link"
                        }
                      },
                      [
                        _c("template", { slot: "prepend" }, [
                          _vm._v(" https:// ")
                        ])
                      ],
                      2
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      },
                      { name: "waves", rawName: "v-waves" }
                    ],
                    attrs: { type: "success" },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    attrs: { type: "reset" },
                    on: { click: _vm.resetForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }