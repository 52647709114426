"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateBanner = exports.getBanners = exports.getBannerImpressions = exports.getBannerById = exports.fileTransferHeaders = exports.deleteBanner = exports.defaultBannerData = exports.createBanner = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = exports.fileTransferHeaders = {};
var defaultBannerData = exports.defaultBannerData = {
  id: 0,
  enabled: true,
  isStripBanner: false,
  isForFOS: false,
  name: "",
  desktopImage: "",
  mobileImage: "",
  description: "",
  priority: 0,
  validFrom: undefined,
  validTill: undefined,
  link: undefined,
  page: undefined,
  params: null,
  sellerId: null,
  type: null,
  seller: {
    id: undefined
  },
  categoryId: null,
  pageCategoryId: null,
  category: {
    id: undefined
  },
  productId: null,
  product: {
    id: undefined,
    name: undefined
  }
};
var getBanners = exports.getBanners = function getBanners(params) {
  return (0, _request.default)({
    url: "/banners",
    method: "get",
    params: params
  });
};
var getBannerById = exports.getBannerById = function getBannerById(id) {
  return (0, _request.default)({
    url: "/banners/".concat(id),
    method: "get"
  });
};
var updateBanner = exports.updateBanner = function updateBanner(id, data) {
  return (0, _request.default)({
    url: "/banners/".concat(id),
    method: "patch",
    data: data
  });
};
var deleteBanner = exports.deleteBanner = function deleteBanner(id) {
  return (0, _request.default)({
    url: "/banners/".concat(id),
    method: "delete"
  });
};
var createBanner = exports.createBanner = function createBanner(data) {
  return (0, _request.default)({
    url: "/banners/",
    method: "post",
    data: data
  });
};
var getBannerImpressions = exports.getBannerImpressions = function getBannerImpressions(params) {
  return (0, _request.default)({
    url: "/banners-impressions/",
    method: "get",
    params: params
  });
};