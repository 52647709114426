"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
var _typeof = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/typeof");
require("core-js/modules/es.array.concat");
require("core-js/modules/es.object.to-string");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/toConsumableArray"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var _couponSubMenu = require("./coupon-sub-menu");
var _bidSubMenu = require("./subMenus/bid-sub-menu");
var _ordersTicketsSubMenu = require("./subMenus/orders-tickets-sub-menu");
var _walletTransactionSubMenu = require("./subMenus/wallet-transaction-sub-menu");
var _orderMilestoneSubMenu = require("./subMenus/order-milestone-sub-menu");
var _ordersValidationSubMenu = require("./subMenus/orders-validation-sub-menu");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) { if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } } return n.default = e, t && t.set(e, n), n; }
var orderRouter = {
  path: "/order",
  component: _index.default,
  redirect: "noredirect",
  name: "orderManagement.title",
  meta: {
    title: "orderManagement.title",
    icon: "shopping"
  },
  children: [{
    path: "/order/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/order/list.vue"));
      });
    },
    name: "Orders",
    meta: {
      title: "orderManagement.orders.title",
      icon: "shopping",
      noCache: true
    }
  }, {
    path: "/order/edit/:id(.*)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/order/manage.vue"));
      });
    },
    name: "orderManagement.orders.edit",
    props: {
      isEdit: true
    },
    meta: {
      title: "orderManagement.orders.edit",
      noCache: true,
      activeMenu: "/order/list",
      hidden: true
    }
  }, {
    path: "/order/change/:id(.*)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/order/components/OrdersChangeDetails.vue"));
      });
    },
    name: "orderManagement.orders.change",
    props: {
      isEdit: false
    },
    meta: {
      title: "orderManagement.orders.change",
      noCache: true,
      activeMenu: "/order/change",
      hidden: true
    }
  }, {
    path: "/order/addnew",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/order/components/OrdersAdd.vue"));
      });
    },
    name: "orderManagement.orders.addnew",
    props: {
      isEdit: false
    },
    meta: {
      title: "Add order",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/order/:id(.*)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/order/components/OrdersInfoDetails.vue"));
      });
    },
    name: "orderManagement.orders.info",
    props: {
      isEdit: true
    },
    meta: {
      title: "orderManagement.orders.info",
      noCache: true,
      activeMenu: "/order/list",
      hidden: true
    }
  }, {
    path: "/order/add",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/order/manage.vue"));
      });
    },
    name: "orderManagement.orders.add",
    props: {
      isEdit: false
    },
    meta: {
      title: "orderManagement.orders.add",
      noCache: true,
      activeMenu: "/order/list",
      hidden: true
    }
  }, {
    path: "/orders-sellers/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/orders-sellers/list.vue"));
      });
    },
    name: "Seller Orders",
    meta: {
      title: "orderManagement.ordersSeller.title",
      icon: "shopping",
      noCache: true
    }
  }, {
    path: "/orders-sellers/edit/:id(.*)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/orders-sellers/manage.vue"));
      });
    },
    name: "orderManagement.ordersSeller.manage",
    props: {
      isEdit: true
    },
    meta: {
      title: "orderManagement.ordersSeller.manage",
      noCache: true,
      activeMenu: "/order-sellers/list",
      hidden: true
    }
  }, {
    path: "/orders-products/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/orders-products/list.vue"));
      });
    },
    name: "Product Orders",
    meta: {
      title: "orderManagement.ordersProduct.title",
      icon: "shopping",
      noCache: true
    }
  }, {
    path: "/orders-products/edit/:id(.*)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/orders-products/manage.vue"));
      });
    },
    name: "orderManagement.ordersProduct.manage",
    props: {
      isEdit: true
    },
    meta: {
      title: "orderManagement.ordersProduct.manage",
      noCache: true,
      activeMenu: "/order-products/list",
      hidden: true
    }
  }, {
    path: "/transaction/list/",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/transaction/list.vue"));
      });
    },
    name: "Transaction",
    meta: {
      title: "orderManagement.transaction.title",
      noCache: true,
      icon: "inr"
    }
  }, {
    path: "/transaction/list/:orderId(.*)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/transaction/list.vue"));
      });
    },
    name: "Transaction",
    meta: {
      title: "orderManagement.transaction.title",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/transaction/add/:orderId(.*)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/transaction/manage.vue"));
      });
    },
    name: "orderManagement.transaction.add",
    props: {
      isEdit: false
    },
    meta: {
      title: "orderManagement.transaction.add",
      noCache: true,
      activeMenu: "/transaction/list/:id(.*)",
      hidden: true
    }
  }, {
    path: "/transaction/edit/:id(.*)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/transaction/manage.vue"));
      });
    },
    name: "orderManagement.transaction.edit",
    props: {
      isEdit: true
    },
    meta: {
      title: "orderManagement.transaction.edit",
      noCache: true,
      activeMenu: "/transaction/list/:id(.*)",
      hidden: true
    }
  }].concat((0, _toConsumableArray2.default)(_walletTransactionSubMenu.walletTransactionSubMenu), (0, _toConsumableArray2.default)(_bidSubMenu.bidsSubMenu), (0, _toConsumableArray2.default)(_ordersTicketsSubMenu.ordersTicketsSubMenu), (0, _toConsumableArray2.default)(_couponSubMenu.couponsSubMenu), (0, _toConsumableArray2.default)(_orderMilestoneSubMenu.orderMilestoneSubMenu), (0, _toConsumableArray2.default)(_ordersValidationSubMenu.ordersValidationSubMenu))
};
var _default = exports.default = orderRouter;