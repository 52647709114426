"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateWalletTransaction = exports.getWalletTransactions = exports.getWalletTransactionById = exports.deleteWalletTransaction = exports.defaultUserWalletData = exports.createWalletTransaction = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultUserWalletData = exports.defaultUserWalletData = {
  id: 0,
  enabled: true,
  amount: undefined,
  action: undefined,
  type: undefined,
  comments: undefined,
  orderReference: undefined,
  vendorIds: [],
  productIds: [],
  vendorNames: [],
  productNames: [],
  bearer: [],
  expiryTimestamp: undefined,
  userId: 0,
  isVerified: false,
  user: {
    id: 0
  },
  orderId: 0,
  order: {
    id: 0
  }
};
var getWalletTransactions = exports.getWalletTransactions = function getWalletTransactions(params) {
  return (0, _request.default)({
    url: "/wallet-transactions",
    method: "get",
    params: params
  });
};
var createWalletTransaction = exports.createWalletTransaction = function createWalletTransaction(data) {
  return (0, _request.default)({
    url: "/wallet-transactions",
    method: "post",
    data: data
  });
};
var getWalletTransactionById = exports.getWalletTransactionById = function getWalletTransactionById(id) {
  return (0, _request.default)({
    url: "/wallet-transactions/".concat(id),
    method: "get"
  });
};
var updateWalletTransaction = exports.updateWalletTransaction = function updateWalletTransaction(id, data) {
  return (0, _request.default)({
    url: "/wallet-transactions/".concat(id),
    method: "patch",
    data: data
  });
};
var deleteWalletTransaction = exports.deleteWalletTransaction = function deleteWalletTransaction(id) {
  return (0, _request.default)({
    url: "/wallet-transactions/".concat(id),
    method: "delete"
  });
};