var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-form",
          {
            ref: "formData",
            staticClass: "demo-form",
            attrs: {
              model: _vm.formData,
              "status-icon": "",
              rules: _vm.rules,
              "label-position": "left"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "OrderId", prop: "orderId" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "orderId",
                    required: "",
                    placeholder: "OrderId",
                    disabled: ""
                  },
                  model: {
                    value: _vm.formData.orderId,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "orderId", $$v)
                    },
                    expression: "formData.orderId"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Product", prop: "product" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "product",
                    required: "",
                    disbaled: "",
                    placeholder: "Product",
                    disabled: ""
                  },
                  model: {
                    value: _vm.orderProductData.product.name,
                    callback: function($$v) {
                      _vm.$set(_vm.orderProductData.product, "name", $$v)
                    },
                    expression: "orderProductData.product.name"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Subject", prop: "subject" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "subject",
                    required: "",
                    placeholder: "Subject",
                    disabled: ""
                  },
                  model: {
                    value: _vm.formData.subject,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "subject", $$v)
                    },
                    expression: "formData.subject"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Complaint", prop: "comments" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "comments",
                    required: "",
                    placeholder: "Complaint",
                    disabled: ""
                  },
                  model: {
                    value: _vm.formData.comments,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "comments", $$v)
                    },
                    expression: "formData.comments"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: { label: "Voice of customer", prop: "voiceOfCustomer" }
              },
              [
                _c("el-input", {
                  attrs: {
                    name: "voiceOfCustomer",
                    required: "",
                    placeholder: "Voice of customer",
                    disabled: ""
                  },
                  model: {
                    value: _vm.formData.voiceOfCustomer,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "voiceOfCustomer", $$v)
                    },
                    expression: "formData.voiceOfCustomer"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Expectation of customer",
                  prop: "expectationsComments"
                }
              },
              [
                _c("el-input", {
                  attrs: {
                    name: "expectationsComments",
                    required: "",
                    placeholder: "Expectation of customer",
                    disabled: ""
                  },
                  model: {
                    value: _vm.formData.expectationsComments,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "expectationsComments", $$v)
                    },
                    expression: "formData.expectationsComments"
                  }
                })
              ],
              1
            ),
            _c("el-form-item", {
              attrs: { label: "Attachments", prop: "attachments" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return _vm._l([1, 2, 3], function(number, index) {
                      return _c(
                        "div",
                        { key: number },
                        [
                          _vm.formData["attachment" + number]
                            ? [
                                _c(
                                  "div",
                                  { staticClass: "attachment-preview" },
                                  [
                                    _vm.formData["attachment" + number].match(
                                      /\.(jpeg|jpg|gif|png)$/
                                    )
                                      ? _c("img", {
                                          staticClass: "attachment-image",
                                          staticStyle: {
                                            "max-width": "150px",
                                            "max-height": "150px",
                                            width: "auto",
                                            height: "auto",
                                            "margin-right": "10px"
                                          },
                                          attrs: {
                                            src:
                                              _vm.formData[
                                                "attachment" + number
                                              ],
                                            alt: "Attachment preview"
                                          }
                                        })
                                      : _c(
                                          "a",
                                          {
                                            staticClass: "download-file",
                                            attrs: {
                                              href:
                                                _vm.formData[
                                                  "attachment" + number
                                                ],
                                              download: "",
                                              target: "_blank"
                                            }
                                          },
                                          [
                                            _c("thumb", {
                                              attrs: {
                                                shape: "square",
                                                fit: "cover",
                                                size: 50,
                                                src:
                                                  _vm.formData[
                                                    "attachment" + number
                                                  ]
                                              }
                                            })
                                          ],
                                          1
                                        )
                                  ]
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    })
                  }
                }
              ])
            }),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Ticket Status",
                  name: "status",
                  prop: "status"
                }
              },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "Select status" },
                    model: {
                      value: _vm.formData.status,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "status", $$v)
                      },
                      expression: "formData.status"
                    }
                  },
                  [
                    _c("el-option", {
                      attrs: { label: "Open", value: "Open" }
                    }),
                    _c("el-option", {
                      attrs: { label: "Closed", value: "Closed" }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm.formData.status === "Closed"
              ? _c(
                  "el-form-item",
                  { attrs: { label: "Closure Comments", prop: "status" } },
                  [
                    _c("el-input", {
                      attrs: {
                        name: "closureComments",
                        required: "",
                        placeholder: "Closure Comments"
                      },
                      model: {
                        value: _vm.formData.closureComments,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "closureComments", $$v)
                        },
                        expression: "formData.closureComments"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-form-item",
              {
                staticStyle: { "margin-bottom": "20px" },
                attrs: { label: "Asignee to", prop: "asigneeId" }
              },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      filterable: "",
                      clearable: "",
                      loading: _vm.asigneeListLoading,
                      name: "Asignee",
                      placeholder: "Select Asignee"
                    },
                    model: {
                      value: _vm.formData.asigneeId,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "asigneeId", $$v)
                      },
                      expression: "formData.asigneeId"
                    }
                  },
                  _vm._l(_vm.asigneeList, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.fullName, value: item.id }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Approval Status",
                  name: "approvalStatus",
                  prop: "approvalStatus"
                }
              },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "Select status" },
                    model: {
                      value: _vm.formData.approvalStatus,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "approvalStatus", $$v)
                      },
                      expression: "formData.approvalStatus"
                    }
                  },
                  [
                    _c("el-option", {
                      attrs: { label: "Approved", value: "Approved" }
                    }),
                    _c("el-option", {
                      attrs: { label: "Not Approved", value: "Not Approved" }
                    }),
                    _c("el-option", {
                      attrs: { label: "Queried", value: "Queried" }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm.formData.approvalStatus === "Approved"
              ? _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "Approval Comments",
                      prop: "approvedComments"
                    }
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        name: "approvedComments",
                        required: "",
                        placeholder: "Approval Comments"
                      },
                      model: {
                        value: _vm.formData.approvedComments,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "approvedComments", $$v)
                        },
                        expression: "formData.approvedComments"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.formData.approvalStatus === "Not Approved"
              ? _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "Not Approved Comments",
                      prop: "notApprovedComments"
                    }
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        name: "notApprovedComments",
                        required: "",
                        placeholder: "Not Approved Comments"
                      },
                      model: {
                        value: _vm.formData.notApprovedComments,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "notApprovedComments", $$v)
                        },
                        expression: "formData.notApprovedComments"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.formData.approvalStatus === "Queried"
              ? _c(
                  "el-form-item",
                  {
                    attrs: { label: "Queried Comments", prop: "queryComments" }
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        name: "queryComments",
                        required: "",
                        placeholder: "Queried Comments"
                      },
                      model: {
                        value: _vm.formData.queryComments,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "queryComments", $$v)
                        },
                        expression: "formData.queryComments"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      },
                      { name: "waves", rawName: "v-waves" }
                    ],
                    attrs: { type: "success" },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    attrs: { type: "reset" },
                    on: { click: _vm.resetForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }