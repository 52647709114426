var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-form",
          {
            ref: "formData",
            staticClass: "demo-form",
            attrs: {
              model: _vm.formData,
              "status-icon": "",
              rules: _vm.rules,
              "label-position": "left"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "Enabled", prop: "enabled" } },
              [
                _c("el-switch", {
                  model: {
                    value: _vm.formData.enabled,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "enabled", $$v)
                    },
                    expression: "formData.enabled"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Image", prop: "image" } },
              [
                _c("file-upload", {
                  attrs: {
                    url: "/categories/image",
                    field: "file",
                    "file-list": _vm.image
                  },
                  on: { change: _vm.desktopImageUploaded }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticClass: "file",
                attrs: { label: "Upload Users", prop: "usersList" }
              },
              [
                _c("file-upload", {
                  attrs: {
                    multiple: false,
                    "max-size": 1,
                    "list-type": "text",
                    "file-list": _vm.fileName,
                    url: "/common/upload",
                    accept: ".csv",
                    type: ".csv"
                  },
                  on: { change: _vm.uploadFile }
                }),
                _c("el-button", {
                  staticStyle: {
                    border: "0px white",
                    "pointer-events": "none",
                    "padding-left": "0px"
                  },
                  attrs: { icon: "el-icon-download" }
                }),
                _c(
                  "a",
                  {
                    staticStyle: { color: "red", border: "1px lightgray" },
                    attrs: {
                      href:
                        "https://ashapura.s3.ap-south-1.amazonaws.com/api/common/27de3ab6-542b-4946-b903-0858b0f261df.csv",
                      download: ""
                    }
                  },
                  [_vm._v("Sample CSV file")]
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Title", prop: "title" } },
              [
                _c("el-input", {
                  attrs: { name: "title", required: "", placeholder: "Title" },
                  model: {
                    value: _vm.formData.title,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "title", $$v)
                    },
                    expression: "formData.title"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Message", prop: "message" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "message",
                    required: "",
                    placeholder: "Message"
                  },
                  model: {
                    value: _vm.formData.message,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "message", $$v)
                    },
                    expression: "formData.message"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Page", prop: "page" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      name: "page",
                      required: "",
                      filterable: "",
                      "auto-complete": "off"
                    },
                    model: {
                      value: _vm.formData.page,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "page", $$v)
                      },
                      expression: "formData.page"
                    }
                  },
                  _vm._l(_vm.pageList, function(page) {
                    return _c("el-option", {
                      key: page.key,
                      attrs: { label: page.key, value: page.value }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm.formData.page === "buyerCategoryProducts"
              ? _c(
                  "el-form-item",
                  { attrs: { label: "Category", prop: "categoryId" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          name: "categoryId",
                          filterable: "",
                          "auto-complete": "off",
                          placeholder: "Category"
                        },
                        model: {
                          value: _vm.formData.params,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "params", $$v)
                          },
                          expression: "formData.params"
                        }
                      },
                      _vm._l(_vm.categories, function(category) {
                        return _c("el-option", {
                          key: category.id,
                          attrs: { label: category.name, value: category.id }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.formData.page === "buyerProductDetails"
              ? _c(
                  "el-form-item",
                  { attrs: { label: "Product", prop: "productId" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          loading: _vm.productListLoading,
                          filterable: "",
                          remote: "",
                          "remote-method": _vm.getProductList,
                          name: "Product",
                          placeholder: "Enter Product Name"
                        },
                        model: {
                          value: _vm.formData.params,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "params", $$v)
                          },
                          expression: "formData.params"
                        }
                      },
                      _vm._l(_vm.productList, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id }
                        })
                      }),
                      1
                    ),
                    _c("el-button", {
                      attrs: { slot: "append", icon: "el-icon-search" },
                      slot: "append"
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      },
                      { name: "waves", rawName: "v-waves" }
                    ],
                    attrs: { type: "success" },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    attrs: { type: "reset" },
                    on: { click: _vm.resetForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }