"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSetting = exports.getSettings = exports.getSettingById = exports.fileTransferHeaders = exports.fetchSettings = exports.deleteSetting = exports.defaultSettingData = exports.createSetting = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = exports.fileTransferHeaders = {};
var defaultSettingData = exports.defaultSettingData = {
  id: 0,
  bankInformation: '',
  bidExpiryHours: 4,
  bidApprovalHours: 4,
  automatedCreditNoteGenerationDays: 0,
  expiryDays: 0,
  bookingAmountThreshold: 0,
  transactionPercent: 0,
  bookingPercent: 0,
  cancellationCharges: 0,
  referralAmount: 0,
  codRate: 0,
  fosReferralCode: '',
  backgroundValidFrom: '',
  backgroundValidTill: '',
  homepageBannerImageTemp: '',
  appbarImageTemp: '',
  minimumCodCharge: 0,
  segments: [],
  searchTexts: []
};
var getSettings = exports.getSettings = function getSettings(params) {
  return (0, _request.default)({
    url: '/settings',
    method: 'get',
    params: params
  });
};
var fetchSettings = exports.fetchSettings = function fetchSettings() {
  return (0, _request.default)({
    url: '/settings',
    method: 'get'
  });
};
var getSettingById = exports.getSettingById = function getSettingById(id) {
  return (0, _request.default)({
    url: "/settings/".concat(id),
    method: 'get'
  });
};
var updateSetting = exports.updateSetting = function updateSetting(id, data) {
  return (0, _request.default)({
    url: "/settings/".concat(id),
    method: 'patch',
    data: data
  });
};
var deleteSetting = exports.deleteSetting = function deleteSetting(id) {
  return (0, _request.default)({
    url: "/settings/".concat(id),
    method: 'delete'
  });
};
var createSetting = exports.createSetting = function createSetting(data) {
  return (0, _request.default)({
    url: '/settings/',
    method: 'post',
    data: data
  });
};