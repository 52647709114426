"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.array.concat");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userCount = exports.updateUsersDocument = exports.updateUser = exports.register = exports.logout = exports.login = exports.getUsersWallets = exports.getUsersExport = exports.getUsersDocuments = exports.getUsersDocumentById = exports.getUsersCohortExport = exports.getUsers = exports.getUserRoles = exports.getUserCards = exports.getUserById = exports.getUserByEmail = exports.getOrderReference = exports.getDocumentNames = exports.getDailyReportExport = exports.forceLogout = exports.deleteUser = exports.defaultUsersDocumentData = exports.defaultUserWalletData = exports.defaultUserData = exports.defaultSellerData = exports.createUsersDocument = exports.addFundsToUserWaller = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultUserData = exports.defaultUserData = {
  id: 0,
  enabled: true,
  fullName: "",
  lang: "en",
  emailAddress: "",
  mobileNumber: "",
  avatar: "",
  vrlOnly: undefined,
  doorToDoorOnly: undefined,
  isFOSuser: undefined,
  organizationId: undefined,
  broker: {
    id: null
  },
  organization: {
    id: null,
    name: ""
  },
  usersRole: {
    id: null,
    name: ""
  },
  brokerName: "",
  // fssaiNumber: '',
  // gstNumber: '',
  // panNumber: '',
  usersRoleId: undefined,
  brokerId: undefined,
  // countryCode: '',
  // preferredLanguage: '',
  // isEmailVerified: undefined,
  // isMobileVerified: undefined,
  // gender: '',
  password: "",
  // referralCode: '',
  // canUseReferralCode: undefined,
  // lastLocation: '',
  // pushId: '',
  // platform: '',
  // version: '',
  // uuid: '',
  // model: '',
  // deviceId: '',
  // device: '',
  // OSVersion: '',
  // uniqueID: '',
  // manufacturer: '',
  // appVersion: '',
  referalCode: ""
};
var defaultUserWalletData = exports.defaultUserWalletData = {
  id: 0,
  enabled: true,
  amount: 0,
  action: "credit",
  type: null,
  expiryTimestamp: null,
  userId: null,
  orderId: null,
  isVerified: false,
  comments: null,
  user: {
    id: null
  },
  order: {
    id: null
  },
  orderReference: null,
  vendorIds: [],
  productIds: [],
  vendorNames: [],
  productNames: [],
  bearer: []
};
var defaultSellerData = exports.defaultSellerData = {
  id: 0,
  sellerDepositAmount: 0,
  sellerTradeLimit: 0,
  sellerAvailableAmount: 0,
  sellerUsedAmount: 0,
  sellerOutstandingAmount: 0,
  commission: 0,
  agreementValidFrom: '',
  agreementValidTill: '',
  vendorName: '',
  vendorIcon: '',
  isSellerVerified: true,
  isSellerTemp: true,
  isSeller: false,
  isSellerRegistered: false,
  interestedProducts: null,
  monthlyCapacity: 0,
  location: null,
  sellersDocument: []
};
var defaultUsersDocumentData = exports.defaultUsersDocumentData = {
  id: 0,
  enabled: true,
  name: "",
  file: "",
  status: "Pending",
  tempStatus: "",
  verified: "",
  comments: null,
  documentNo: null,
  panNumber: "",
  user: {
    id: null,
    organization: {
      id: 0,
      name: ""
    }
  }
};
var getUsers = exports.getUsers = function getUsers(params) {
  return (0, _request.default)({
    url: "/users",
    method: "get",
    params: params
  });
};
var getUsersExport = exports.getUsersExport = function getUsersExport(params) {
  return (0, _request.default)({
    url: "/users/usersForExport",
    method: "get",
    params: params
  });
};
var getUsersCohortExport = exports.getUsersCohortExport = function getUsersCohortExport(params) {
  return (0, _request.default)({
    url: "/users/userCohort",
    method: "get",
    params: params
  });
};
var getDailyReportExport = exports.getDailyReportExport = function getDailyReportExport(params) {
  return (0, _request.default)({
    url: "/users/dailyReport",
    method: "get",
    params: params
  });
};
var getUserById = exports.getUserById = function getUserById(id) {
  return (0, _request.default)({
    url: "/users/".concat(id),
    method: "get"
  });
};
var getUserByEmail = exports.getUserByEmail = function getUserByEmail(adminemail) {
  return (0, _request.default)({
    url: "/users/".concat(adminemail),
    method: "get"
  });
};
var updateUser = exports.updateUser = function updateUser(id, data) {
  return (0, _request.default)({
    url: "/users/".concat(id),
    method: "patch",
    data: data
  });
};
var deleteUser = exports.deleteUser = function deleteUser(adminname) {
  return (0, _request.default)({
    url: "/users/".concat(adminname),
    method: "delete"
  });
};
var login = exports.login = function login(data) {
  return (0, _request.default)({
    url: "/users/login",
    method: "post",
    data: data
  });
};
var logout = exports.logout = function logout() {
  return (0, _request.default)({
    url: "/users/logout",
    method: "post"
  });
};
var forceLogout = exports.forceLogout = function forceLogout(data) {
  return (0, _request.default)({
    url: "/users/force-logout",
    method: "post",
    data: data
  });
};
var register = exports.register = function register(data) {
  return (0, _request.default)({
    url: "/users",
    method: "post",
    data: data
  });
};
var userCount = exports.userCount = function userCount(params) {
  return (0, _request.default)({
    url: "/users/count",
    method: "get",
    params: params
  });
};
var getUserCards = exports.getUserCards = function getUserCards() {
  return (0, _request.default)({
    url: "/users-cards",
    method: "get"
  });
};
var getUserRoles = exports.getUserRoles = function getUserRoles(params) {
  return (0, _request.default)({
    url: "/users-roles",
    method: "get",
    params: params
  });
};
var getUsersWallets = exports.getUsersWallets = function getUsersWallets(params) {
  return (0, _request.default)({
    url: "/wallet-transactions",
    method: "get",
    params: params
  });
};
var addFundsToUserWaller = exports.addFundsToUserWaller = function addFundsToUserWaller(data) {
  return (0, _request.default)({
    url: "/wallet-transactions",
    method: "post",
    data: data
  });
};
// user documents
var getUsersDocuments = exports.getUsersDocuments = function getUsersDocuments(params) {
  return (0, _request.default)({
    url: "/users-documents",
    method: "get",
    params: params
  });
};
var getUsersDocumentById = exports.getUsersDocumentById = function getUsersDocumentById(id) {
  return (0, _request.default)({
    url: "/users-documents/".concat(id),
    method: "get"
  });
};
var getDocumentNames = exports.getDocumentNames = function getDocumentNames() {
  return (0, _request.default)({
    url: "/users-documents/list-names",
    method: "get"
  });
};
var updateUsersDocument = exports.updateUsersDocument = function updateUsersDocument(id, data) {
  return (0, _request.default)({
    url: "/users-documents/".concat(id),
    method: "patch",
    data: data
  });
};
var createUsersDocument = exports.createUsersDocument = function createUsersDocument(data) {
  return (0, _request.default)({
    url: "/users-documents/",
    method: "post",
    data: data
  });
};
var getOrderReference = exports.getOrderReference = function getOrderReference(data, userId) {
  return (0, _request.default)({
    url: "/orders/reference/".concat(data, "/").concat(userId),
    method: "get"
  });
};