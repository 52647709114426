"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateOrder = exports.orderExport = exports.orderCount = exports.getRevenues = exports.getOrders = exports.getOrderInfo = exports.getOrderById = exports.deleteOrderProducts = exports.deleteOrder = exports.defaultOrderData = exports.createOrder = exports.confirmOrder = exports.changeOrder = exports.changeNewOrder = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultOrderData = exports.defaultOrderData = {
  id: 0,
  orderReference: '',
  customerIP: '',
  status: '',
  orderedBy: '',
  isAdminChanges: false,
  comments: '',
  deliveryOption: '',
  total: 0,
  shippingCharges: 0,
  shippingId: 0,
  taxedAmount: 0,
  discountAmount: 0,
  couponId: 0,
  grandTotal: 0,
  totalProducts: 0,
  totalQuantity: 0,
  orderTimestamp: '',
  lastLocation: '',
  platform: '',
  version: '',
  uuid: '',
  model: '',
  deviceId: '',
  device: '',
  OSVersion: '',
  uniqueID: '',
  manufacturer: '',
  appVersion: '',
  userId: 0,
  user: {
    id: null,
    isFOSuser: false
  },
  countryId: 0,
  country: {
    id: null
  },
  stateId: 0,
  state: {
    id: null
  },
  cityId: 0,
  city: {
    id: null
  },
  pincodeId: 0,
  pincode: {
    id: null
  },
  vrlId: 0,
  vrl: {
    id: null
  },
  admin: {
    id: 0,
    fullName: ''
  }
};
var getOrders = exports.getOrders = function getOrders(params) {
  return (0, _request.default)({
    url: '/orders',
    method: 'get',
    params: params
  });
};
var getOrderById = exports.getOrderById = function getOrderById(id) {
  return (0, _request.default)({
    url: "/orders/".concat(id),
    method: 'get'
  });
};
var updateOrder = exports.updateOrder = function updateOrder(id, data) {
  return (0, _request.default)({
    url: "/orders/".concat(id),
    method: 'patch',
    data: data
  });
};
var deleteOrder = exports.deleteOrder = function deleteOrder(id) {
  return (0, _request.default)({
    url: "/orders/".concat(id),
    method: 'delete'
  });
};
var deleteOrderProducts = exports.deleteOrderProducts = function deleteOrderProducts(id) {
  return (0, _request.default)({
    url: "/orders-products/".concat(id),
    method: 'delete'
  });
};
var createOrder = exports.createOrder = function createOrder(data) {
  return (0, _request.default)({
    url: '/orders/',
    method: 'post',
    data: data
  });
};
var changeOrder = exports.changeOrder = function changeOrder(data) {
  return (0, _request.default)({
    url: '/orders-products/newAdd',
    method: 'post',
    data: data
  });
};
var changeNewOrder = exports.changeNewOrder = function changeNewOrder(data) {
  return (0, _request.default)({
    url: '/orders-products/push',
    method: 'post',
    data: data
  });
};
var orderCount = exports.orderCount = function orderCount(params) {
  return (0, _request.default)({
    url: '/orders/count/status',
    method: 'get',
    params: params
  });
};
var getRevenues = exports.getRevenues = function getRevenues(params) {
  return (0, _request.default)({
    url: '/orders-sellers/getRevenue',
    method: 'get',
    params: params
  });
};
var orderExport = exports.orderExport = function orderExport(data) {
  return (0, _request.default)({
    url: '/orders/export',
    method: 'post',
    data: data
  });
};
var confirmOrder = exports.confirmOrder = function confirmOrder() {
  var orderId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  return (0, _request.default)({
    url: '/transactions/update-order/' + orderId,
    method: 'get'
  });
};
var getOrderInfo = exports.getOrderInfo = function getOrderInfo(id) {
  return (0, _request.default)({
    url: "/orders/info/".concat(id),
    method: 'get'
  });
};