"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUserSearchLogs = exports.getUserSearchLogById = exports.getCountByUser = exports.defaultUserSearchLogData = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultUserSearchLogData = exports.defaultUserSearchLogData = {
  id: 0,
  enabled: true,
  userId: 0,
  searchtext: undefined,
  user: {
    id: undefined,
    fullName: undefined,
    mobileNumber: undefined,
    emailAddress: undefined
  }
};
var getUserSearchLogs = exports.getUserSearchLogs = function getUserSearchLogs(params) {
  return (0, _request.default)({
    url: "/user-search-logs",
    method: "get",
    params: params
  });
};
var getUserSearchLogById = exports.getUserSearchLogById = function getUserSearchLogById(id) {
  return (0, _request.default)({
    url: "/user-search-logs/".concat(id),
    method: "get"
  });
};
var getCountByUser = exports.getCountByUser = function getCountByUser(data) {
  return (0, _request.default)({
    url: "/user-search-logs/list",
    method: "post",
    data: data
  });
};