var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-form",
          {
            ref: "formData",
            staticClass: "demo-form",
            attrs: {
              model: _vm.formData,
              "status-icon": "",
              rules: _vm.rules,
              "label-position": "left"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "OrderId", prop: "orderId" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "orderId",
                    required: "",
                    placeholder: "OrderId",
                    disabled: ""
                  },
                  model: {
                    value: _vm.formData.orderId,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "orderId", $$v)
                    },
                    expression: "formData.orderId"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Product", prop: "product" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "product",
                    required: "",
                    disbaled: "",
                    placeholder: "Product",
                    disabled: ""
                  },
                  model: {
                    value: _vm.orderProductData.product.name,
                    callback: function($$v) {
                      _vm.$set(_vm.orderProductData.product, "name", $$v)
                    },
                    expression: "orderProductData.product.name"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Subject", prop: "subject" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "subject",
                    required: "",
                    placeholder: "Subject"
                  },
                  model: {
                    value: _vm.formData.subject,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "subject", $$v)
                    },
                    expression: "formData.subject"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Complaint", prop: "comments" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "comments",
                    required: "",
                    placeholder: "Complaint"
                  },
                  model: {
                    value: _vm.formData.comments,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "comments", $$v)
                    },
                    expression: "formData.comments"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: { label: "Voice of customer", prop: "voiceOfCustomer" }
              },
              [
                _c("el-input", {
                  attrs: {
                    name: "voiceOfCustomer",
                    required: "",
                    placeholder: "Voice of customer"
                  },
                  model: {
                    value: _vm.formData.voiceOfCustomer,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "voiceOfCustomer", $$v)
                    },
                    expression: "formData.voiceOfCustomer"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Expectation of customer",
                  prop: "expectationsComments"
                }
              },
              [
                _c("el-input", {
                  attrs: {
                    name: "expectationsComments",
                    required: "",
                    placeholder: "Expectation of customer"
                  },
                  model: {
                    value: _vm.formData.expectationsComments,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "expectationsComments", $$v)
                    },
                    expression: "formData.expectationsComments"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Attachments", prop: "attachments" } },
              [
                _c("file-upload", {
                  attrs: {
                    "file-list": _vm.image,
                    "max-size": 3,
                    multiple: true
                  },
                  on: { change: _vm.fileUploaded }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticStyle: { "margin-bottom": "20px" },
                attrs: { label: "Asignee to", prop: "asigneeId" }
              },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      filterable: "",
                      clearable: "",
                      loading: _vm.asigneeListLoading,
                      name: "Asignee",
                      placeholder: "Select Asignee"
                    },
                    model: {
                      value: _vm.formData.asigneeId,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "asigneeId", $$v)
                      },
                      expression: "formData.asigneeId"
                    }
                  },
                  _vm._l(_vm.asigneeList, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.fullName, value: item.id }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      },
                      { name: "waves", rawName: "v-waves" }
                    ],
                    attrs: { type: "success" },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    attrs: { type: "reset" },
                    on: { click: _vm.resetForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }