"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uploadFile = exports.toggleEnable = exports.getAppVersions = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var toggleEnable = exports.toggleEnable = function toggleEnable(url, data) {
  return (0, _request.default)({
    url: url,
    method: 'patch',
    data: data
  });
};
var uploadFile = exports.uploadFile = function uploadFile(data) {
  var url = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '/common/upload';
  var onUploadProgressCallBack = arguments.length > 2 ? arguments[2] : undefined;
  return (0, _request.default)({
    url: url,
    method: 'post',
    data: data,
    onUploadProgress: onUploadProgressCallBack
  });
};
var getAppVersions = exports.getAppVersions = function getAppVersions() {
  return (0, _request.default)({
    url: '/common/app_version',
    method: 'get'
  });
};