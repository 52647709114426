"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateStories = exports.getStoriesViews = exports.getStoriesById = exports.getStories = exports.fileTransferHeaders = exports.deleteStories = exports.defaultStoriesData = exports.createStories = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = exports.fileTransferHeaders = {};
var defaultStoriesData = exports.defaultStoriesData = {
  id: 0,
  enabled: true,
  isForFOS: false,
  videoUrl: "",
  previewUrl: "",
  validFrom: "",
  page: "",
  status: "Pending",
  validTill: undefined,
  params: undefined,
  priority: null,
  sellerId: null,
  categoryId: null,
  productId: null,
  viewCount: 0,
  seller: {
    id: undefined,
    fullName: undefined,
    mobileNumber: undefined
  },
  category: {
    id: undefined
  },
  product: {
    id: undefined,
    name: undefined
  }
};
var getStories = exports.getStories = function getStories(params) {
  return (0, _request.default)({
    url: '/stories',
    method: 'get',
    params: params
  });
};
var getStoriesById = exports.getStoriesById = function getStoriesById(id) {
  return (0, _request.default)({
    url: "/stories/".concat(id),
    method: 'get'
  });
};
var getStoriesViews = exports.getStoriesViews = function getStoriesViews(params) {
  return (0, _request.default)({
    url: '/story-views',
    method: 'get',
    params: params
  });
};
var updateStories = exports.updateStories = function updateStories(id, data) {
  return (0, _request.default)({
    url: "/stories/".concat(id),
    method: 'patch',
    data: data
  });
};
var deleteStories = exports.deleteStories = function deleteStories(id) {
  return (0, _request.default)({
    url: "/stories/".concat(id),
    method: 'delete'
  });
};
var createStories = exports.createStories = function createStories(data) {
  return (0, _request.default)({
    url: '/stories/',
    method: 'post',
    data: data
  });
};