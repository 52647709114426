"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updatePlan = exports.getPlans = exports.getPlanById = exports.deletePlan = exports.defaultPlanData = exports.createPlan = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultPlanData = exports.defaultPlanData = {
  id: 0,
  enabled: true,
  name: undefined,
  validity: 1,
  salePrice: 0,
  regularPrice: 1,
  taxId: null,
  icon: null,
  tax: {
    id: null
  }
};
var getPlans = exports.getPlans = function getPlans(params) {
  return (0, _request.default)({
    url: '/plans',
    method: 'get',
    params: params
  });
};
var getPlanById = exports.getPlanById = function getPlanById(id) {
  return (0, _request.default)({
    url: "/plans/".concat(id),
    method: 'get'
  });
};
var updatePlan = exports.updatePlan = function updatePlan(id, data) {
  return (0, _request.default)({
    url: "/plans/".concat(id),
    method: 'patch',
    data: data
  });
};
var deletePlan = exports.deletePlan = function deletePlan(id) {
  return (0, _request.default)({
    url: "/plans/".concat(id),
    method: 'delete'
  });
};
var createPlan = exports.createPlan = function createPlan(data) {
  return (0, _request.default)({
    url: '/plans/',
    method: 'post',
    data: data
  });
};