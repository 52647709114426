"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
var _typeof = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/typeof");
require("core-js/modules/es.array.concat");
require("core-js/modules/es.object.to-string");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/toConsumableArray"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var _bannerSubMenu = _interopRequireDefault(require("./subMenus/banner-sub-menu"));
var _homeNotificationsSubMenu = _interopRequireDefault(require("./subMenus/home-notifications-sub-menu"));
var _news = _interopRequireDefault(require("./subMenus/news"));
var _brokerSubMenu = require("./subMenus/broker-sub-menu");
var _codBasedPincode = _interopRequireDefault(require("./subMenus/cod-based-pincode"));
var _locationSubMenu = require("./subMenus/location-sub-menu");
var _measurementUnitSubMenu = require("./subMenus/measurement-unit-sub-menu");
var _mustTrySubMenu = require("./subMenus/mustTry-sub-menu");
var _officeSubMenu = require("./subMenus/office-sub-menu");
var _questionsSubMenuCopy = _interopRequireDefault(require("./subMenus/questions-sub-menu copy"));
var _surveyForm = _interopRequireDefault(require("./subMenus/survey-form"));
var _taxSubMenu = require("./subMenus/tax-sub-menu");
var _vrlSubMenu = _interopRequireDefault(require("./subMenus/vrl-sub-menu"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) { if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } } return n.default = e, t && t.set(e, n), n; }
var masterRouter = {
  path: "/masters",
  component: _index.default,
  redirect: "noredirect",
  name: "Masters",
  meta: {
    title: "master.title",
    icon: "star"
  },
  children: [].concat((0, _toConsumableArray2.default)(_bannerSubMenu.default), (0, _toConsumableArray2.default)(_taxSubMenu.taxSubMenu), (0, _toConsumableArray2.default)(_mustTrySubMenu.mustTrySubMenu), (0, _toConsumableArray2.default)(_locationSubMenu.locationSubMenu), (0, _toConsumableArray2.default)(_brokerSubMenu.brokersSubMenu), (0, _toConsumableArray2.default)(_measurementUnitSubMenu.measurementUnitSubMenu), (0, _toConsumableArray2.default)(_officeSubMenu.officeSubMenu), (0, _toConsumableArray2.default)(_vrlSubMenu.default), (0, _toConsumableArray2.default)(_questionsSubMenuCopy.default), (0, _toConsumableArray2.default)(_codBasedPincode.default), (0, _toConsumableArray2.default)(_surveyForm.default), [{
    path: "/banner-impressions",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/banner-impressions/list.vue"));
      });
    },
    name: "Banner Impressions",
    meta: {
      icon: "list",
      title: "bannerManagement.bannerImpressions.title",
      noCache: true
    }
  }, {
    path: "/validate-points",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/validate-points/list.vue"));
      });
    },
    name: "Validate Points",
    meta: {
      icon: "money",
      title: "bannerManagement.validatePoints.title",
      noCache: true
    }
  }, {
    path: "/notificationreport",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/notifications/notificationreport.vue"));
      });
    },
    name: "Notifications Report",
    meta: {
      icon: "excel",
      title: "bannerManagement.notificationreport.title",
      noCache: true
    }
  }], (0, _toConsumableArray2.default)(_homeNotificationsSubMenu.default), (0, _toConsumableArray2.default)(_news.default))
};
var _default = exports.default = masterRouter;