var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: {
                multiple: "",
                clearable: "",
                "allow-create": "",
                "collapse-tags": "",
                "default-first-option": "",
                placeholder: "Columns"
              },
              on: { change: _vm.handleColumn },
              model: {
                value: _vm.value,
                callback: function($$v) {
                  _vm.value = $$v
                },
                expression: "value"
              }
            },
            _vm._l(_vm.options, function(item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value }
              })
            }),
            1
          ),
          _c(
            "router-link",
            {
              staticClass: "margin-horizontal",
              attrs: { to: "/category/add/0" }
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "success", icon: "el-icon-edit" }
                },
                [_vm._v(" " + _vm._s(_vm.$t("global.form.add")) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": ""
          },
          on: { "sort-change": _vm.sortChange }
        },
        [
          _c("el-table-column", {
            attrs: { width: "50", align: "center", label: "ID", prop: "id" }
          }),
          _c("el-table-column", {
            attrs: {
              width: "180px",
              align: "center",
              prop: "name",
              label: _vm.$t("Name")
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("enabled", {
                      attrs: { url: "/categories/" + scope.row.id },
                      model: {
                        value: scope.row.enabled,
                        callback: function($$v) {
                          _vm.$set(scope.row, "enabled", $$v)
                        },
                        expression: "scope.row.enabled"
                      }
                    }),
                    _vm._v(" " + _vm._s(scope.row.name) + " "),
                    _c("br"),
                    _vm._v(" " + _vm._s(scope.row.name_h) + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "180px",
              align: "center",
              prop: "icon",
              label: "Icon"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { width: "50px", height: "50px" },
                      attrs: { src: scope.row.icon, fit: "fill" }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "180px",
              align: "center",
              prop: "image",
              label: "Image"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { width: "100px", height: "100px" },
                      attrs: { src: scope.row.image, fit: "fill" }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "100px",
              align: "center",
              label: "Priority",
              prop: "priority"
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "100px",
              align: "center",
              label: "BG Color",
              prop: "bgcolor"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-color-picker", {
                      attrs: { value: scope.row.bgcolor, disabled: "" }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "150px",
              align: "center",
              label: "Starting From",
              prop: "amountStartingFrom"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.row.amountStartingFrom) + " ")
                  ]
                }
              }
            ])
          }),
          _vm.columns.description
            ? _c("el-table-column", {
                attrs: {
                  width: "200px",
                  align: "center",
                  label: "Description",
                  prop: "description"
                }
              })
            : _vm._e(),
          _vm.columns.createdDate
            ? _c("el-table-column", {
                attrs: {
                  label: _vm.$t("table.createdDate"),
                  width: "110px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("moment")(
                                  scope.row.createdTimestamp,
                                  "from"
                                )
                              )
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  912526870
                )
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { align: "center", label: "Actions", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "router-link",
                      { attrs: { to: "/category/edit/" + scope.row.id } },
                      [
                        _c("el-button", {
                          attrs: {
                            type: "primary",
                            size: "small",
                            icon: "el-icon-edit"
                          }
                        })
                      ],
                      1
                    ),
                    _c("el-button", {
                      attrs: {
                        size: "small",
                        type: "danger",
                        icon: "el-icon-delete"
                      },
                      on: {
                        click: function($event) {
                          return _vm.handleDelete(scope.row)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }