"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateAttributesValues = exports.getAttributesValuesById = exports.getAttributesValues = exports.fileTransferHeaders = exports.deleteAttributesValues = exports.defaultAttributeValuesData = exports.createAttributesValues = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = exports.fileTransferHeaders = {};
var defaultAttributeValuesData = exports.defaultAttributeValuesData = {
  id: 0,
  enabled: true,
  name: '',
  attributeId: '',
  attribute: {
    id: null
  }
};
var getAttributesValues = exports.getAttributesValues = function getAttributesValues(params) {
  return (0, _request.default)({
    url: '/attributes-values',
    method: 'get',
    params: params
  });
};
var getAttributesValuesById = exports.getAttributesValuesById = function getAttributesValuesById(id) {
  return (0, _request.default)({
    url: "/attributes-values/".concat(id),
    method: 'get'
  });
};
var updateAttributesValues = exports.updateAttributesValues = function updateAttributesValues(id, data) {
  return (0, _request.default)({
    url: "/attributes-values/".concat(id),
    method: 'patch',
    data: data
  });
};
var deleteAttributesValues = exports.deleteAttributesValues = function deleteAttributesValues(id) {
  return (0, _request.default)({
    url: "/attributes-values/".concat(id),
    method: 'delete'
  });
};
var createAttributesValues = exports.createAttributesValues = function createAttributesValues(data) {
  return (0, _request.default)({
    url: '/attributes-values/',
    method: 'post',
    data: data
  });
};