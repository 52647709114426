"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateOffices = exports.getOfficesById = exports.getOffices = exports.fileTransferHeaders = exports.deleteOffices = exports.defaultOfficeData = exports.createOffices = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = exports.fileTransferHeaders = {};
var defaultOfficeData = exports.defaultOfficeData = {
  id: 0,
  enabled: true,
  name: '',
  emailAddress: '',
  mobileNumber: '',
  address: '',
  priority: 0
};
var getOffices = exports.getOffices = function getOffices(params) {
  return (0, _request.default)({
    url: '/offices',
    method: 'get',
    params: params
  });
};
var getOfficesById = exports.getOfficesById = function getOfficesById(id) {
  return (0, _request.default)({
    url: "/offices/".concat(id),
    method: 'get'
  });
};
var updateOffices = exports.updateOffices = function updateOffices(id, data) {
  return (0, _request.default)({
    url: "/offices/".concat(id),
    method: 'patch',
    data: data
  });
};
var deleteOffices = exports.deleteOffices = function deleteOffices(id) {
  return (0, _request.default)({
    url: "/offices/".concat(id),
    method: 'delete'
  });
};
var createOffices = exports.createOffices = function createOffices(data) {
  return (0, _request.default)({
    url: '/offices/',
    method: 'post',
    data: data
  });
};