"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateQuestion = exports.getQuestions = exports.getQuestionById = exports.deleteQuestion = exports.defaultQuestionData = exports.createQuestion = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultQuestionData = exports.defaultQuestionData = {
  id: 0,
  enabled: true,
  allowCustomAnswer: false,
  questionText: "",
  allowMultiSelect: true,
  maximumAllowedSelections: 1
};
var getQuestions = exports.getQuestions = function getQuestions(params) {
  return (0, _request.default)({
    url: "/questions",
    method: "get",
    params: params
  });
};
var getQuestionById = exports.getQuestionById = function getQuestionById(id) {
  return (0, _request.default)({
    url: "/questions/".concat(id),
    method: "get"
  });
};
var updateQuestion = exports.updateQuestion = function updateQuestion(id, data) {
  return (0, _request.default)({
    url: "/questions/".concat(id),
    method: "patch",
    data: data
  });
};
var deleteQuestion = exports.deleteQuestion = function deleteQuestion(id) {
  return (0, _request.default)({
    url: "/questions/".concat(id),
    method: "delete"
  });
};
var createQuestion = exports.createQuestion = function createQuestion(data) {
  return (0, _request.default)({
    url: "/questions/",
    method: "post",
    data: data
  });
};