"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.object.get-own-property-descriptor");
require("core-js/modules/es.object.to-string");
require("core-js/modules/es.string.iterator");
require("core-js/modules/es.weak-map");
require("core-js/modules/web.dom-collections.iterator");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _typeof2 = _interopRequireDefault(require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/typeof"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != (0, _typeof2.default)(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) { if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } } return n.default = e, t && t.set(e, n), n; }
var subscriptionsRouter = {
  path: "/subscriptions",
  component: _index.default,
  redirect: "noredirect",
  name: "subscriptionManagement.title",
  meta: {
    title: "subscriptionManagement.title",
    icon: "tab"
  },
  children: [{
    path: "/plans/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/plans/list.vue"));
      });
    },
    name: "Plans",
    meta: {
      title: "subscriptionManagement.plans.title",
      noCache: true,
      icon: "star"
    }
  }, {
    path: "/plans/add",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/plans/manage.vue"));
      });
    },
    name: "subscriptionManagement.plans.manage",
    props: {
      isEdit: false
    },
    meta: {
      title: "subscriptionManagement.plans.manage",
      noCache: true,
      activeMenu: "/plans/list",
      hidden: true
    }
  }, {
    path: "/plans/edit/:id(.*)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/plans/manage.vue"));
      });
    },
    name: "subscriptionManagement.plans.edit",
    props: {
      isEdit: true
    },
    meta: {
      title: "subscriptionManagement.plans.edit",
      noCache: true,
      activeMenu: "/plans/list",
      hidden: true
    }
  }, {
    path: "/subscriptions/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/subscriptions/list.vue"));
      });
    },
    name: "Subscriptions",
    meta: {
      title: "subscriptionManagement.subscriptions.title",
      noCache: true,
      icon: "list"
    }
  }, {
    path: "/subscriptions/add",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/subscriptions/manage.vue"));
      });
    },
    name: "subscriptionManagement.subscriptions.manage",
    props: {
      isEdit: false
    },
    meta: {
      title: "subscriptionManagement.subscriptions.manage",
      noCache: true,
      activeMenu: "/subscriptions/list",
      hidden: true
    }
  }, {
    path: "/subscriptions/edit/:id(.*)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/subscriptions/manage.vue"));
      });
    },
    name: "subscriptionManagement.subscriptions.edit",
    props: {
      isEdit: true
    },
    meta: {
      title: "subscriptionManagement.subscriptions.edit",
      noCache: true,
      activeMenu: "/subscriptions/list",
      hidden: true
    }
  }]
};
var _default = exports.default = subscriptionsRouter;