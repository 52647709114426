var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-form",
          {
            ref: "formData",
            staticClass: "demo-form",
            attrs: {
              model: _vm.formData,
              "status-icon": "",
              rules: _vm.rules,
              "label-position": "left"
            }
          },
          [
            _vm.isSuperAdmin()
              ? _c(
                  "el-form-item",
                  { attrs: { label: "Enabled", prop: "enabled" } },
                  [
                    _c("el-switch", {
                      model: {
                        value: _vm.formData.enabled,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "enabled", $$v)
                        },
                        expression: "formData.enabled"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-form-item",
              { attrs: { label: "Action", prop: "action" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "Select" },
                    on: { change: _vm.handleActionChange },
                    model: {
                      value: _vm.selectedAction,
                      callback: function($$v) {
                        _vm.selectedAction = $$v
                      },
                      expression: "selectedAction"
                    }
                  },
                  [
                    _c("el-option", {
                      attrs: { label: "Verification", value: "Verification" }
                    }),
                    _c("el-option", {
                      attrs: { label: "Rejection", value: "Rejection" }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Organization Name", prop: "name" } },
              [
                _c("el-input", {
                  attrs: { name: "name", required: "", placeholder: "Name" },
                  model: {
                    value: _vm.formData.name,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "name", $$v)
                    },
                    expression: "formData.name"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "GST Number", prop: "gstNumber" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "gstNumber",
                    required: "",
                    placeholder: "GST Number"
                  },
                  model: {
                    value: _vm.formData.gstNumber,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "gstNumber", $$v)
                    },
                    expression: "formData.gstNumber"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "PAN Number", prop: "panNumber" } },
              [
                _c("el-input", {
                  attrs: { name: "panNumber", placeholder: "PAN Number" },
                  model: {
                    value: _vm.formData.panNumber,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "panNumber", $$v)
                    },
                    expression: "formData.panNumber"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "FSSAI Number", prop: "fssaiNumber" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "fssaiNumber",
                    required: "",
                    placeholder: "FSSAI Number"
                  },
                  model: {
                    value: _vm.formData.fssaiNumber,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "fssaiNumber", $$v)
                    },
                    expression: "formData.fssaiNumber"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "User Name", prop: "users.fullName" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "fullName",
                    placeholder: "User Name",
                    disabled: true
                  },
                  model: {
                    value: _vm.formData.users.fullName,
                    callback: function($$v) {
                      _vm.$set(_vm.formData.users, "fullName", $$v)
                    },
                    expression: "formData.users.fullName"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "User Mobile", prop: "users.mobileNumber" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "mobileNumber",
                    placeholder: "User Mobile",
                    disabled: true
                  },
                  model: {
                    value: _vm.formData.users.mobileNumber,
                    callback: function($$v) {
                      _vm.$set(_vm.formData.users, "mobileNumber", $$v)
                    },
                    expression: "formData.users.mobileNumber"
                  }
                })
              ],
              1
            ),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading"
                  }
                ],
                key: _vm.tableKey,
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.documentList,
                  border: "",
                  fit: "",
                  "highlight-current-row": ""
                }
              },
              [
                _c("el-table-column", {
                  attrs: { width: "auto", label: "Name", prop: "name" }
                }),
                _c("el-table-column", {
                  attrs: { label: "File", prop: "file" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-link",
                            {
                              attrs: {
                                type: "primary",
                                href: scope.row.file || null,
                                target: "_blank"
                              }
                            },
                            [_vm._v(" view file ")]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      },
                      { name: "waves", rawName: "v-waves" }
                    ],
                    attrs: { type: "success" },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    attrs: { type: "reset" },
                    on: { click: _vm.resetForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }