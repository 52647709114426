var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-form",
          {
            ref: "formData",
            staticClass: "demo-form",
            attrs: {
              model: _vm.formData,
              "status-icon": "",
              rules: _vm.rules,
              "label-position": "left"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "Disable", prop: "enabled" } },
              [
                _c("el-switch", {
                  model: {
                    value: _vm.formData.isDeleted,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "isDeleted", $$v)
                    },
                    expression: "formData.isDeleted"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "FOS Category", prop: "isFOScategory" } },
              [
                _c("el-switch", {
                  model: {
                    value: _vm.formData.isFOScategory,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "isFOScategory", $$v)
                    },
                    expression: "formData.isFOScategory"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Name", prop: "name" } },
              [
                _c("el-input", {
                  attrs: { name: "name", required: "", placeholder: "Name" },
                  model: {
                    value: _vm.formData.name,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "name", $$v)
                    },
                    expression: "formData.name"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Name Hindi", prop: "name_h" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "name_h",
                    required: "",
                    placeholder: "Name in Hindi"
                  },
                  model: {
                    value: _vm.formData.name_h,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "name_h", $$v)
                    },
                    expression: "formData.name_h"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Icon", prop: "icon" } },
              [
                _c("file-upload", {
                  attrs: {
                    multiple: false,
                    "file-list": _vm.icon,
                    "max-size": 1,
                    url: "/common/upload"
                  },
                  on: { change: _vm.iconUploaded }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Image", prop: "image" } },
              [
                _c("file-upload", {
                  attrs: {
                    multiple: false,
                    "file-list": _vm.image,
                    "max-size": 1,
                    url: "/common/upload"
                  },
                  on: { change: _vm.imageUploaded }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Priority", prop: "priority" } },
              [
                _c("el-input-number", {
                  attrs: {
                    name: "priority",
                    placeholder: "Priority",
                    min: 0,
                    precision: 0
                  },
                  model: {
                    value: _vm.formData.priority,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "priority", $$v)
                    },
                    expression: "formData.priority"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Description", prop: "description" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "description",
                    type: "textarea",
                    placeholder: "Description"
                  },
                  model: {
                    value: _vm.formData.description,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "description", $$v)
                    },
                    expression: "formData.description"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Starting From", prop: "amountStartingFrom" } },
              [
                _c("el-input-number", {
                  attrs: {
                    name: "amountStartingFrom",
                    placeholder: "Starting From",
                    min: 1,
                    precision: 0
                  },
                  model: {
                    value: _vm.formData.amountStartingFrom,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "amountStartingFrom", $$v)
                    },
                    expression: "formData.amountStartingFrom"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "BG Color", prop: "bgcolor" } },
              [
                _c("el-color-picker", {
                  attrs: { name: "bgcolor" },
                  model: {
                    value: _vm.formData.bgcolor,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "bgcolor", $$v)
                    },
                    expression: "formData.bgcolor"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      },
                      { name: "waves", rawName: "v-waves" }
                    ],
                    attrs: { type: "success" },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    attrs: { type: "reset" },
                    on: { click: _vm.resetForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }