"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  route: {
    dashboard: "Dashboard",
    documentation: "Documentation",
    guide: "Guide",
    permission: "Permission",
    pagePermission: "Page Permission",
    rolePermission: "Role Permission",
    directivePermission: "Directive Permission",
    icons: "Icons",
    components: "Components",
    tinymce: "Tinymce",
    markdown: "Markdown",
    jsonEditor: "JSON Editor",
    splitPane: "SplitPane",
    avatarUpload: "Avatar Upload",
    dropzone: "Dropzone",
    sticky: "Sticky",
    countTo: "Count To",
    componentMixin: "Mixin",
    backToTop: "Back To Top",
    draggableDialog: "Draggable Dialog",
    draggableKanban: "Draggable Kanban",
    draggableList: "Draggable List",
    draggableSelect: "Draggable Select",
    charts: "Charts",
    barChart: "Bar Chart",
    lineChart: "Line Chart",
    mixedChart: "Mixed Chart",
    example: "Example",
    nested: "Nested Routes",
    menu1: "Menu 1",
    "menu1-1": "Menu 1-1",
    "menu1-2": "Menu 1-2",
    "menu1-2-1": "Menu 1-2-1",
    "menu1-2-2": "Menu 1-2-2",
    "menu1-3": "Menu 1-3",
    menu2: "Menu 2",
    table: "Table",
    dynamicTable: "Dynamic Table",
    draggableTable: "Draggable Table",
    inlineEditTable: "Inline Edit Table",
    complexTable: "Complex Table",
    tab: "Tab",
    form: "Form",
    createArticle: "Create Article",
    editArticle: "Edit Article",
    articleList: "Article List",
    errorPages: "Error Pages",
    page401: "401",
    page404: "404",
    errorLog: "Error Log",
    excel: "Excel",
    exportExcel: "Export Excel",
    selectExcel: "Export Selected",
    mergeHeader: "Merge Header",
    uploadExcel: "Upload Excel",
    zip: "Zip",
    pdf: "PDF",
    exportZip: "Export Zip",
    theme: "Theme",
    clipboard: "Clipboard",
    i18n: "I18n",
    externalLink: "External Link",
    profile: "Profile",
    adminManagement: {
      title: "Admin Management",
      admins: {
        title: "Admins",
        manage: "Manage Admin"
      },
      roles: {
        title: "Roles",
        manage: "Manage Role"
      },
      assignAgent: {
        title: "Assign Agent",
        vrl: {
          title: "Assign Agent",
          add: "Add Assign Agent",
          edit: "Edit Assign Agent"
        }
      },
      assignOnboardingAgent: {
        title: "Assign Onboarding Agent",
        vrl: {
          title: "Assign Onboarding Agent",
          add: "Add Assign Onboarding Agent",
          edit: "Edit Assign Onboarding Agent"
        }
      }
    },
    userManagement: {
      title: "User Management",
      users: {
        title: "Users",
        add: "Add User",
        edit: "Edit User"
      },
      wallet: {
        title: "Wallet",
        add: "Add Wallet",
        edit: "Edit Wallet",
        manage: "Manage Wallet"
      },
      seller: {
        title: "Sellers",
        add: "Add Seller",
        edit: "Edit Seller",
        manage: "Manage Seller"
      },
      sellersOutstanding: {
        title: "Outstandings",
        add: "Add Outstandings",
        manage: "Manage Outstandings"
      },
      usersDocuments: {
        title: "Users Documents",
        add: "Add Users Documents",
        edit: "Edit Users Documents",
        manage: "Manage Users Documents"
      },
      validateDocuments: {
        title: "Validate KYC Documents",
        edit: "Edit KYC Documents",
        manage: "Manage KYC Documents"
      },
      validateSeller: {
        title: "Validate Seller"
      },
      validateOrganization: {
        title: "Validate Organization"
      },
      stories: {
        title: "Stories"
      },
      brands: {
        title: "Manage Brands"
      }
    },
    bannerManagement: {
      title: "Banner Management",
      banners: {
        title: "Banners",
        add: "Add Banner",
        edit: "Edit Banner"
      },
      bannerImpressions: {
        title: "Banner Impressions"
      },
      validatePoints: {
        title: "Validate Points"
      },
      validateDiscountProducts: {
        title: "Discounted Products"
      },
      notificationreport: {
        title: "Notification Reports",
        message: "Count"
      }
    },
    homeNotificationsManagement: {
      title: "Home Notification Management",
      homeNotifications: {
        title: "Home Notifications",
        add: "Add Home Notifications",
        edit: "Edit Home Notifications"
      }
    },
    newsManagement: {
      title: "News Management",
      news: {
        title: "News",
        add: "Add News",
        edit: "Edit News"
      }
    },
    questionManagement: {
      title: "Questions",
      questions: {
        title: "Question",
        add: "Add Question",
        edit: "Edit Question"
      },
      answers: {
        title: "Answers",
        add: "Add Answer",
        edit: "Edit Answer"
      }
    },
    vrlManagement: {
      title: "VRL",
      vrl: {
        title: "VRL",
        add: "Add VRL",
        edit: "Edit VRL"
      }
    },
    codPincode: {
      title: "COD Based Pincode",
      vrl: {
        title: "VRL",
        add: "Add VRL",
        edit: "Edit VRL"
      }
    },
    settingManagement: {
      title: "Setting Management",
      settings: {
        title: "Setting",
        add: "Add Setting",
        edit: "Edit Setting"
      }
    },
    productManagement: {
      title: "Product Management",
      brand: {
        title: "Brands",
        add: "Add Brand",
        manage: "Manage Brand"
      },
      attribute: {
        title: "Attributes",
        add: "Add Attribute",
        edit: "Edit Attribute"
      },
      attributevalue: {
        title: "Attribute Value",
        add: "Add Attribute Value",
        edit: "Edit Attribute Value",
        manage: "Manage Attribute Value"
      },
      product: {
        title: "Products",
        manage: "Manage Product",
        request: "Product Requests"
      },
      sellerVariation: {
        title: "Sellers Products",
        add: "Add Product",
        manage: "Manage Product"
      },
      category: {
        title: "Categories",
        manage: "Manage Category"
      },
      categoryNotifications: {
        title: "Category Notifications",
        manage: "Category Notifications Manage"
      },
      price: {
        title: "Prices",
        add: "Add Price",
        edit: "Edit Price",
        manage: "Manage Price"
      },
      validateProducts: {
        title: "Validate Products"
      },
      productImpressions: {
        title: "Products Impressions"
      },
      productBulkUpload: {
        title: "Products Bulk Upload"
      },
      dealsCampaign: {
        title: "Deals Of the day Campaign"
      },
      productPrices: {
        title: "Products Prices"
      }
    },
    orderManagement: {
      title: "Order Management",
      orders: {
        title: "Orders",
        add: "Add Order",
        edit: "Edit Order",
        info: "Order Info",
        change: "Change Order"
      },
      ordersSeller: {
        title: "Seller Orders",
        add: "Add Seller Orders",
        edit: "Edit Seller Orders",
        manage: "Manage Seller Orders",
        info: "Seller Orders Info"
      },
      ordersProduct: {
        title: "Product Orders",
        add: "Add Product Orders",
        edit: "Edit Product Orders",
        manage: "Manage Product Orders",
        info: "Product Orders Info"
      },
      transaction: {
        title: "Transactions",
        edit: "Edit Transaction",
        add: "Add Transaction"
      },
      walletTransaction: {
        title: "Wallet Transactions",
        edit: "Edit Transaction",
        add: "Add Transaction"
      },
      coupons: {
        title: "Coupons",
        add: "Add Coupon",
        edit: "Edit Coupon"
      },
      bid: {
        title: "Bids",
        add: "Add Bid",
        edit: "Edit Bid",
        manage: "Manage Bid"
      },
      sellersBids: {
        title: "Sellers Bids",
        add: "Add Sellers Bid",
        edit: "Edit Sellers Bid",
        manage: "Manage Sellers Bid"
      },
      ordersTickets: {
        title: "Support",
        manage: "Manage Ticket"
      },
      orderMilestone: {
        title: "Order Milestone",
        add: "Add Order Milestone",
        edit: "Edit Order Milestone"
      },
      ordersValidation: {
        title: "Orders Validation",
        edit: "Edit Orders"
      }
    },
    customerManagement: {
      title: "Customer Management",
      organization: {
        title: "Organizations",
        add: "Add Organization",
        edit: "Edit Organization"
      },
      stories: {
        title: "Stories",
        add: "Add Story",
        edit: "Edit Story"
      },
      brands: {
        title: "Brands",
        add: "Add Brand",
        edit: "Edit Brand"
      },
      contact: {
        title: "Contacts",
        add: "Add Contact",
        edit: "Edit Contact"
      },
      address: {
        title: "Address",
        add: "Add Address",
        edit: "Edit Address"
      },
      broker: {
        title: "Brokers",
        add: "Add Broker",
        edit: "Edit Broker",
        manage: "Manage Broker"
      },
      pincode: {
        title: "Pincode",
        add: "Add Pincode",
        edit: "Edit Pincode",
        manage: "Manage Pincode"
      },
      userLog: {
        title: "Logs",
        add: "Add Address",
        edit: "Edit Address"
      },
      payLater: {
        title: "Pay Later",
        add: "Add Pay Later",
        edit: "Edit Pay Later"
      },
      userSearchLog: {
        title: "User search logs"
      },
      leads: {
        title: "Leads"
      }
    },
    master: {
      title: "Master",
      tax: {
        title: "Tax",
        add: "Add Tax",
        edit: "Edit Tax",
        manage: "Manage"
      },
      office: {
        title: "Office",
        add: "Add Office",
        edit: "Edit Office",
        manage: "Manage"
      },
      mustTry: {
        title: "Must Try",
        add: "Add Must Try",
        edit: "Edit Must Try",
        manage: "Manage"
      },
      measurementunits: {
        title: "Measurement Unit",
        add: "Add Measurement Unit",
        edit: "Edit Measurement Unit",
        manage: "Manage "
      }
    },
    subscriptionManagement: {
      title: "Subscription Management",
      plans: {
        title: "Plans",
        add: "Add Plan",
        edit: "Edit Plan",
        manage: "Manage Plan"
      },
      subscriptions: {
        title: "Subscriptions",
        add: "Add Subscription",
        edit: "Edit Subscription",
        manage: "Manage Subscription"
      }
    },
    // follow this
    locations: {
      title: "Location Management",
      countries: {
        title: "Country",
        add: "Add Country",
        edit: "Edit Country"
      },
      states: {
        title: "State",
        add: "Add State",
        edit: "Edit State"
      },
      cities: {
        title: "City",
        add: "Add City",
        edit: "Edit City",
        vrls: {
          title: "VRL",
          add: "Add VRL",
          edit: "Edit VRL"
        }
      }
    },
    orderTracking: {
      title: "Order Tracking",
      info: "Order Tracking Info",
      ashapura: {
        title: "Placed Orders Ashapura"
      },
      others: {
        title: "Placed Orders Others"
      },
      dispatchDeliveryTracker: {
        title: "Dispatched Orders Ashapura"
      },
      dispatchOtherTracker: {
        title: "Dispatched Orders Others"
      }
    },
    ticketsManagement: {
      title: "Tickets Management",
      info: "Tickets Management Info",
      add: "Create Ticket",
      myTickets: {
        title: "My tickets"
      }
    },
    notifications: {
      title: "Notifications",
      manage: "Manage Notification"
    },
    notificationsScheduler: {
      title: "Notifications Scheduler",
      manage: "Manage Notification"
    },
    surveyForm: {
      title: "Survey Form",
      manage: "Manage Survey"
    }
  },
  navbar: {
    logOut: "Log Out",
    dashboard: "Dashboard",
    theme: "Theme",
    size: "Global Size",
    profile: "Profile"
  },
  login: {
    title: "Admin Login Form",
    logIn: "Login",
    email: "Email",
    password: "Password",
    confirmPassword: "Confirm Password",
    any: "any",
    recover: "Send Recovery Mail",
    recoverTitle: " Password recovery",
    thirdparty: "Or connect with",
    thirdpartyTips: "Can not be simulated on local, so please combine you own business simulation! ! !"
  },
  documentation: {
    documentation: "Documentation"
  },
  permission: {
    createRole: "New Role",
    editPermission: "Edit",
    roles: "Your roles",
    switchRoles: "Switch roles",
    tips: "In some cases, using v-permission will have no effect. For example: Element-UI  el-tab or el-table-column and other scenes that dynamically render dom. You can only do this with v-if.",
    delete: "Delete",
    confirm: "Confirm",
    cancel: "Cancel"
  },
  guide: {
    description: "The guide page is useful for some people who entered the project for the first time. You can briefly introduce the features of the project. Demo is based on ",
    button: "Show Guide"
  },
  components: {
    documentation: "Documentation",
    tinymceTips: "Rich text is a core feature of the management backend, but at the same time it is a place with lots of pits. In the process of selecting rich texts, I also took a lot of detours. The common rich texts on the market have been basically used, and I finally chose Tinymce. See the more detailed rich text comparison and introduction.",
    stickyTips: "when the page is scrolled to the preset position will be sticky on the top.",
    backToTopTips1: "When the page is scrolled to the specified position, the Back to Top button appears in the lower right corner",
    backToTopTips2: "You can customize the style of the button, show / hide, height of appearance, height of the return. If you need a text prompt, you can use element-ui el-tooltip elements externally",
    imageUploadTips: "Since I was using only the vue@1 version, and it is not compatible with mockjs at the moment, I modified it myself, and if you are going to use it, it is better to use official version."
  },
  table: {
    dynamicTips1: "Fixed header, sorted by header order",
    dynamicTips2: "Not fixed header, sorted by click order",
    dragTips1: "The default order",
    dragTips2: "The after dragging order",
    title: "Title",
    importance: "Importance",
    type: "Type",
    remark: "Remark",
    search: "Search",
    add: "Add",
    export: "Export",
    reviewer: "Reviewer",
    id: "ID",
    date: "Date",
    author: "Author",
    readings: "Readings",
    status: "Status",
    actions: "Actions",
    edit: "Edit",
    publish: "Publish",
    draft: "Draft",
    delete: "Delete",
    logout: "Logout",
    cancel: "Cancel",
    confirm: "Confirm",
    createdDate: "Created",
    modifiedDate: "Modified",
    deleteConfirmation: "Do you want to delete{tag}, {name}?",
    forceLogoutConfirm: "Do you want to force logout, {name}?",
    deleteCanceled: "Delete canceled",
    actionCanceled: "Action canceled",
    sort: {
      id: {
        asc: "ID Ascending",
        desc: "ID Descending"
      },
      enable: {
        enabled: "Enabled",
        disabled: "Disabled"
      }
    }
  },
  example: {
    warning: "Creating and editing pages cannot be cached by keep-alive because keep-alive's 'include' currently does not support caching based on routes, so now it's cached based on component name. If you want to achieve a similar caching effect, you can use a browser caching scheme such as localStorage. Or don't use keep-alive's 'include' to cache all pages directly. See details"
  },
  errorLog: {
    tips: "Please click the bug icon in the upper right corner",
    description: "Now the management system are basically the form of the spa, it enhances the user experience, but it also increases the possibility of page problems, a small negligence may lead to the entire page deadlock. Fortunately Vue provides a way to catch handling exceptions, where you can handle errors or report exceptions.",
    documentation: "Document introduction"
  },
  excel: {
    export: "Export",
    selectedExport: "Export Selected Items",
    placeholder: "Please enter the file name (default excel-list)"
  },
  zip: {
    export: "Export",
    placeholder: "Please enter the file name (default file)"
  },
  pdf: {
    tips: "Here we use window.print() to implement the feature of downloading PDF."
  },
  theme: {
    change: "Change Theme",
    documentation: "Theme documentation",
    tips: "Tips: It is different from the theme-pick on the navbar is two different skinning methods, each with different application scenarios. Refer to the documentation for details."
  },
  tagsView: {
    refresh: "Refresh",
    close: "Close",
    closeOthers: "Close Others",
    closeAll: "Close All"
  },
  settings: {
    title: "Page style setting",
    theme: "Theme Color",
    showTagsView: "Open Tags-View",
    showSidebarLogo: "Sidebar Logo",
    fixedHeader: "Fixed Header",
    sidebarTextTheme: "Sidebar Text Theme"
  },
  admin: {
    fullName: "Full Name",
    email: "Email",
    contactNumber: "Contact Number",
    enabled: "Enabled",
    role: {
      name: "Name",
      enabled: "Enabled"
    }
  },
  location: {
    country: {
      name: "Name",
      code: "Code",
      enabled: "Enabled"
    },
    state: {
      name: "Name",
      code: "Code",
      enabled: "Enabled",
      country: "Country"
    },
    city: {
      name: "Name",
      code: "Code",
      allowCod: "COD",
      enabled: "Enabled",
      country: "Country",
      state: "State",
      vrl: {
        name: "Name",
        enabled: "Enabled",
        country: "Country",
        state: "State"
      }
    }
  },
  geolocation: {
    prompt: {
      title: "Geolocation Permission",
      message: "Kindly allow us to access your current location",
      nonCompatibleBrowser: "You are using non compatible browser"
    }
  },
  master: {
    title: "Master",
    tax: {
      name: "Tax",
      enabled: "Enabled"
    },
    office: {
      name: "Office Name",
      enabled: "Enabled"
    },
    mustTry: {
      name: "Page Name",
      enabled: "Enabled"
    }
  },
  question: {
    enabled: "Enabled",
    questionText: "Question"
  },
  questionsAnswer: {
    enabled: "Enabled",
    answer: "Answer"
  },
  address: {
    name: "Name",
    enabled: "Enabled"
  },
  plan: {
    name: "Name",
    enabled: "Enabled"
  },
  subscription: {
    name: "Name",
    enabled: "Enabled",
    username: "Username",
    usermobile: "User Mobile",
    useremail: "User Email",
    referenceNumber: "Ref No",
    status: "Status",
    plan: "Plan"
  },
  attribute: {
    name: "Attribute",
    enabled: "Enabled"
  },
  attributeValue: {
    name: "Attribute Value",
    enabled: "Enabled"
  },
  brand: {
    name: "Brand",
    enabled: "Enabled"
  },
  category: {
    name: "Category",
    enabled: "Enabled"
  },
  contact: {
    name: "Contact",
    enabled: "Enabled"
  },
  order: {
    name: "Order Reference",
    enabled: "Enabled",
    userName: "User Name",
    mobile: "User Mobile Number",
    email: "User Email Address"
  },
  ordersSeller: {
    name: "Orders Seller ID",
    enabled: "Enabled",
    seller: "Seller Name",
    mobile: "Seller Mobile Number",
    email: "Seller Email Address"
  },
  ordersTicket: {
    enabled: "Enabled",
    userName: "Username",
    status: "Status"
  },
  bid: {
    name: "Bid",
    enabled: "Enabled",
    userName: "User",
    status: "Status",
    product: "Product",
    seller: "Seller"
  },
  measurementunits: {
    name: "Measurement Unit",
    enabled: "Enabled"
  },
  organization: {
    name: "Organization",
    enabled: "Enabled"
  },
  pincode: {
    name: "Name",
    code: "Pincode",
    enabled: "Enabled"
  },
  broker: {
    name: "Broker",
    enabled: "Enabled",
    mobileNumber: "Mobile Number"
  },
  coupon: {
    enabled: "Enabled",
    name: "Name",
    code: "Code"
  },
  user: {
    id: "ID",
    enabled: "Enabled",
    fullName: "Full Name",
    email: "Email",
    mobileNumber: "Mobile Number",
    walletAction: "Wallet Action",
    subscriptionRequired: "Subscription Required"
  },
  usersDocuments: {
    id: "ID",
    enabled: "Enabled",
    name: "Name",
    file: "File",
    status: "Status"
  },
  validateDocuments: {
    id: "ID",
    enabled: "Enabled",
    name: "Name",
    file: "File",
    status: "Status"
  },
  product: {
    enabled: "Enabled",
    name: "Name",
    status: "Status",
    category: "Category",
    attribute: "Attribute",
    seller: "Seller"
  },
  sellersVariationsCitiesPrice: {
    enabled: "Enabled",
    product: "Product",
    city: "City",
    unitPrice: "Unit Price"
  },
  global: {
    form: {
      save: "Save",
      add: "Add",
      edit: "Edit",
      delete: "Delete",
      reset: "Reset"
    },
    common: {
      ok: "Ok",
      error: "Error"
    }
  },
  notifications: {
    enabled: "Enabled",
    title: "Title",
    message: "Sub Title",
    state: "State",
    city: "City",
    count: "Count",
    send: "Send"
  }
};