var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("user.id") },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery.id,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "id", $$v)
              },
              expression: "listQuery.id"
            }
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("user.fullName") },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery.fullName,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "fullName", $$v)
              },
              expression: "listQuery.fullName"
            }
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("user.email") },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery.emailAddress,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "emailAddress", $$v)
              },
              expression: "listQuery.emailAddress"
            }
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("user.mobileNumber") },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery.mobileNumber,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "mobileNumber", $$v)
              },
              expression: "listQuery.mobileNumber"
            }
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "130px" },
              attrs: { placeholder: _vm.$t("user.enabled"), clearable: "" },
              on: { change: _vm.handleFilter },
              model: {
                value: _vm.listQuery.enabled,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "enabled", $$v)
                },
                expression: "listQuery.enabled"
              }
            },
            _vm._l(_vm.enableTypeOptions, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.value, value: item.key }
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "130px" },
              attrs: {
                placeholder: _vm.$t("user.subscriptionRequired"),
                clearable: ""
              },
              on: { change: _vm.handleFilter },
              model: {
                value: _vm.listQuery.subscriptionRequired,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "subscriptionRequired", $$v)
                },
                expression: "listQuery.subscriptionRequired"
              }
            },
            _vm._l(_vm.enableTypeOptions, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.value, value: item.key }
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "140px" },
              on: { change: _vm.handleFilter },
              model: {
                value: _vm.listQuery.sort,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "sort", $$v)
                },
                expression: "listQuery.sort"
              }
            },
            _vm._l(_vm.sortOptions, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.label, value: item.key }
              })
            }),
            1
          ),
          _c(
            "el-checkbox",
            {
              staticClass: "filter-item",
              on: { change: _vm.handleFilter },
              model: {
                value: _vm.showVendor,
                callback: function($$v) {
                  _vm.showVendor = $$v
                },
                expression: "showVendor"
              }
            },
            [_vm._v(" Show Vendor ")]
          ),
          _c(
            "el-checkbox",
            {
              staticClass: "filter-item",
              on: { change: _vm.handleFilter },
              model: {
                value: _vm.showFOS,
                callback: function($$v) {
                  _vm.showFOS = $$v
                },
                expression: "showFOS"
              }
            },
            [_vm._v(" Show FOS users ")]
          ),
          !_vm.isOnbAgentAdmin()
            ? _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  attrs: { clearable: "", placeholder: "Onboarding agent" },
                  on: { change: _vm.handleFilter },
                  model: {
                    value: _vm.listQuery.onboardingAgentId,
                    callback: function($$v) {
                      _vm.$set(_vm.listQuery, "onboardingAgentId", $$v)
                    },
                    expression: "listQuery.onboardingAgentId"
                  }
                },
                _vm._l(_vm.onboardingOptions, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.fullName, value: item.id }
                  })
                }),
                1
              )
            : _vm._e(),
          _vm.isSuperAdmin()
            ? _c(
                "router-link",
                {
                  staticClass: "margin-horizontal",
                  attrs: { to: "/users/add" }
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      attrs: {
                        type: "success",
                        size: "small",
                        icon: "el-icon-edit"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("global.form.add")) + " ")]
                  )
                ],
                1
              )
            : _vm._e(),
          _c("br"),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "140px" },
              attrs: { placeholder: "Date Type", clearable: "" },
              on: { change: _vm.handleFilter },
              model: {
                value: _vm.dateType,
                callback: function($$v) {
                  _vm.dateType = $$v
                },
                expression: "dateType"
              }
            },
            _vm._l(_vm.dateTypeOptions, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.label, value: item.key }
              })
            }),
            1
          ),
          _c("VueCtkDateTimePicker", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { formatted: "DD-MM-YYYY", range: "" },
            on: { change: _vm.dateval },
            model: {
              value: _vm.dateRange,
              callback: function($$v) {
                _vm.dateRange = $$v
              },
              expression: "dateRange"
            }
          }),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [_vm._v(" " + _vm._s(_vm.$t("table.search")) + " ")]
          ),
          _vm.isSuperAdmin() || _vm.isExportAllowed()
            ? _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: {
                    loading: _vm.downloadLoading,
                    type: "primary",
                    icon: "el-icon-download"
                  },
                  on: { click: _vm.handleDownload }
                },
                [_vm._v(" " + _vm._s(_vm.$t("table.export")) + " ")]
              )
            : _vm._e(),
          _vm.isSuperAdmin() || _vm.isExportAllowed()
            ? _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: {
                    loading: _vm.downloadCohortLoading,
                    type: "primary",
                    icon: "el-icon-download"
                  },
                  on: { click: _vm.handleCohortDownload }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Cohort export")) + " ")]
              )
            : _vm._e(),
          _vm.isSuperAdmin() || _vm.isExportAllowed()
            ? _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: {
                    loading: _vm.downloadDailyreportLoading,
                    type: "primary",
                    icon: "el-icon-download"
                  },
                  on: { click: _vm.handleDailyReportDownload }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Download Today's Report")) + " ")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": ""
          },
          on: { "sort-change": _vm.sortChange }
        },
        [
          _c("el-table-column", {
            attrs: { width: "100", align: "center", label: "ID", prop: "id" }
          }),
          _c("el-table-column", {
            attrs: { width: "200px", label: "Full Name", prop: "fullName" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("enabled", {
                      attrs: {
                        disabled: !_vm.isSuperAdmin(),
                        url: "/users/" + scope.row.id
                      },
                      model: {
                        value: scope.row.enabled,
                        callback: function($$v) {
                          _vm.$set(scope.row, "enabled", $$v)
                        },
                        expression: "scope.row.enabled"
                      }
                    }),
                    scope.row.usersRole && scope.row.usersRole.name === "Broker"
                      ? _c(
                          "router-link",
                          { attrs: { to: "/contact/list/" + scope.row.id } },
                          [
                            _c("el-link", { attrs: { type: "primary" } }, [
                              _vm._v(" " + _vm._s(scope.row.fullName) + " ")
                            ])
                          ],
                          1
                        )
                      : scope.row.usersRole &&
                        scope.row.usersRole.name === "Customer"
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: "/address/list/Customer/" + scope.row.id
                            }
                          },
                          [
                            _c("el-link", { attrs: { type: "primary" } }, [
                              _vm._v(" " + _vm._s(scope.row.fullName) + " "),
                              scope.row.isVerified
                                ? _c("i", { staticClass: "el-icon-success" })
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      : _c("el-link", { attrs: { type: "primary" } }, [
                          _vm._v(" " + _vm._s(scope.row.fullName) + " ")
                        ]),
                    scope.row.usersRole
                      ? _c("div", [
                          _vm._v(
                            " ( " + _vm._s(scope.row.usersRole.name) + " ) "
                          )
                        ])
                      : _vm._e(),
                    scope.row.referralCode
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "Referal Code",
                                  placement: "top"
                                }
                              },
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      icon: "el-icon-s-ticket",
                                      type: "warning"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(scope.row.referralCode) + " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-tag",
                      { attrs: { type: scope.row.isMobile ? "" : "warning" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.isMobile ? "Mobile" : "Website") +
                            " "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "200px",
              align: "left",
              label: "Wallet",
              prop: "walletBalance"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(" Balance: "),
                    _c("inr", { attrs: { number: scope.row.walletBalance } }),
                    _c("br"),
                    _vm._v(" Usable Amount: "),
                    _c("inr", {
                      attrs: { number: scope.row.walletUsageAmount }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "220px",
              align: "left",
              label: "Contact Details",
              prop: "emailAddress"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: {
                          icon: "el-icon-message",
                          target: "_blank",
                          href: "mailto:" + scope.row.emailAddress
                        }
                      },
                      [_vm._v(" " + _vm._s(scope.row.emailAddress) + " ")]
                    ),
                    _c("br"),
                    _c(
                      "el-link",
                      {
                        attrs: {
                          icon: "el-icon-phone",
                          target: "_blank",
                          href: "tel:" + scope.row.mobileNumber
                        }
                      },
                      [_vm._v(" " + _vm._s(scope.row.mobileNumber) + " ")]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "150px",
              align: "center",
              label: "Broker",
              prop: "broker.name"
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "250px",
              align: "left",
              label: "Organization",
              prop: "organization"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.organization
                      ? _c("div", [
                          _vm._v(" " + _vm._s(scope.row.organization.name)),
                          _c("br"),
                          _vm._v(
                            " GST: " +
                              _vm._s(scope.row.organization.gstNumber) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(
                            " PAN: " +
                              _vm._s(scope.row.organization.panNumber) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(
                            " FSSAI: " +
                              _vm._s(scope.row.organization.fssaiNumber) +
                              " "
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.createdDate"),
              width: "140px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("moment")(
                            scope.row.createdTimestamp,
                            "DD-MM-YYYY"
                          )
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Approved", width: "140px", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.usersDocument.length !== 0 &&
                    scope.row.isVerified === true
                      ? _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: _vm._f("moment")(
                                scope.row.usersDocument[0].updatedTimestamp,
                                "MMMM Do YYYY, hh:mm a"
                              ),
                              placement: "top"
                            }
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("moment")(
                                    scope.row.usersDocument[0].updatedTimestamp,
                                    "DD-MM-YYYY"
                                  )
                                )
                              )
                            ])
                          ]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "Actions", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "router-link",
                      { attrs: { to: "/users-wallet/list/" + scope.row.id } },
                      [
                        !_vm.isOnbAgentAdmin()
                          ? _c(
                              "el-button",
                              { attrs: { type: "warning", size: "small" } },
                              [_c("svg-icon", { attrs: { name: "inr" } })],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm.isSuperAdmin()
                      ? _c("el-button", {
                          attrs: {
                            type: "primary",
                            size: "small",
                            icon: "el-icon-edit"
                          },
                          on: {
                            click: function($event) {
                              return _vm.editUser(scope.row.id)
                            }
                          }
                        })
                      : _vm._e(),
                    _vm.isSuperAdmin()
                      ? _c("el-button", {
                          directives: [{ name: "waves", rawName: "v-waves" }],
                          attrs: {
                            size: "small",
                            type: "info",
                            icon: "el-icon-switch-button"
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleLogout(scope.row)
                            }
                          }
                        })
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }