"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateContact = exports.getContacts = exports.getContactById = exports.fileTransferHeaders = exports.deleteContact = exports.defaultContactData = exports.createContact = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = exports.fileTransferHeaders = {};
var defaultContactData = exports.defaultContactData = {
  id: 0,
  enabled: true,
  userId: 0,
  user: {
    id: null
  },
  organizationId: 0,
  organization: {
    id: null
  },
  emailAddress: '',
  mobileNumber: '',
  contactPersonName: ''
};
var getContacts = exports.getContacts = function getContacts(params) {
  return (0, _request.default)({
    url: '/contacts',
    method: 'get',
    params: params
  });
};
var getContactById = exports.getContactById = function getContactById(id) {
  return (0, _request.default)({
    url: "/contacts/".concat(id),
    method: 'get'
  });
};
var updateContact = exports.updateContact = function updateContact(id, data) {
  return (0, _request.default)({
    url: "/contacts/".concat(id),
    method: 'patch',
    data: data
  });
};
var deleteContact = exports.deleteContact = function deleteContact(id) {
  return (0, _request.default)({
    url: "/contacts/".concat(id),
    method: 'delete'
  });
};
var createContact = exports.createContact = function createContact(data) {
  return (0, _request.default)({
    url: '/contacts/',
    method: 'post',
    data: data
  });
};