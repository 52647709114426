import { render, staticRenderFns } from "./password-recovery.vue?vue&type=template&id=3c30a892&scoped=true&"
import script from "./password-recovery.vue?vue&type=script&lang=ts&"
export * from "./password-recovery.vue?vue&type=script&lang=ts&"
import style0 from "./password-recovery.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./password-recovery.vue?vue&type=style&index=1&id=3c30a892&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c30a892",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\nasib\\Downloads\\desktop (1)\\tbadmin\\tradebridge-admin\\tradebridgeadmin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3c30a892')) {
      api.createRecord('3c30a892', component.options)
    } else {
      api.reload('3c30a892', component.options)
    }
    module.hot.accept("./password-recovery.vue?vue&type=template&id=3c30a892&scoped=true&", function () {
      api.rerender('3c30a892', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/login/password-recovery.vue"
export default component.exports