"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateTransaction = exports.initEpayRefund = exports.getTransactions = exports.getTransactionById = exports.fileTransferHeaders = exports.deleteTransaction = exports.defaultTransactionData = exports.createTransaction = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = exports.fileTransferHeaders = {};
var defaultTransactionData = exports.defaultTransactionData = {
  id: 0,
  orderId: undefined,
  txnid: '',
  mihpayid: '',
  status: '',
  unmappedstatus: '',
  cardCategory: '',
  amount: undefined,
  net_amount_debit: undefined,
  payment_source: ''
};
var getTransactions = exports.getTransactions = function getTransactions(params) {
  return (0, _request.default)({
    url: '/transactions',
    method: 'get',
    params: params
  });
};
var createTransaction = exports.createTransaction = function createTransaction(data) {
  return (0, _request.default)({
    url: '/transactions',
    method: 'post',
    data: data
  });
};
var getTransactionById = exports.getTransactionById = function getTransactionById(id) {
  return (0, _request.default)({
    url: "/transactions/".concat(id),
    method: 'get'
  });
};
var updateTransaction = exports.updateTransaction = function updateTransaction(id, data) {
  return (0, _request.default)({
    url: "/transactions/".concat(id),
    method: 'patch',
    data: data
  });
};
var deleteTransaction = exports.deleteTransaction = function deleteTransaction(id) {
  return (0, _request.default)({
    url: "/transactions/".concat(id),
    method: 'delete'
  });
};
var initEpayRefund = exports.initEpayRefund = function initEpayRefund(data) {
  return (0, _request.default)({
    url: '/transactions/init-epay-refund',
    method: 'post',
    data: data
  });
};