"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateOrdersSeller = exports.getSellerOrderInvoiceById = exports.getOrdersSellers = exports.getOrdersSellerByOrderId = exports.getOrdersSellerById = exports.fileTransferHeaders = exports.exportOrdersSellers = exports.exportAgingData = exports.defaultOrdersSellerData = exports.countOrdersSellers = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = exports.fileTransferHeaders = {};
var defaultOrdersSellerData = exports.defaultOrdersSellerData = {
  id: 0,
  enabled: true,
  orderId: 0,
  sellerId: 0,
  status: "",
  comments: "",
  total: 0,
  shippingCharges: 0,
  taxedAmount: 0,
  discountAmount: 0,
  grandTotal: 0,
  totalProducts: 0,
  totalQuantity: 0,
  orderTimestamp: "",
  freightCharges: 0,
  rating: 0,
  orderDispatchedTimestamp: null,
  deliveredTimestamp: null,
  deliveryConfirmedTimestamp: null,
  notDeliveredTimestamp: null,
  cancelledTimestamp: null,
  lrNumber: null,
  lrReceipt: null,
  invoiceAttachment: null,
  qualityCoa: null,
  nominatedTransportDetails: null,
  notDeliveredReason: null,
  returnAmount: 0,
  returnQuantity: 0,
  returnDate: null,
  isPaid: false,
  sellerPaymentDate: null,
  userId: 0,
  user: {
    id: null
  },
  order: {
    id: null
  },
  seller: {
    id: null
  }
};
var getOrdersSellers = exports.getOrdersSellers = function getOrdersSellers(params) {
  return (0, _request.default)({
    url: "/orders-sellers",
    method: "get",
    params: params
  });
};
var getOrdersSellerById = exports.getOrdersSellerById = function getOrdersSellerById(id) {
  return (0, _request.default)({
    url: "/orders-sellers/".concat(id),
    method: "get"
  });
};
var getOrdersSellerByOrderId = exports.getOrdersSellerByOrderId = function getOrdersSellerByOrderId(orderId) {
  return (0, _request.default)({
    url: "/orders-sellers/order/".concat(orderId),
    method: "get"
  });
};
var updateOrdersSeller = exports.updateOrdersSeller = function updateOrdersSeller(id, data) {
  return (0, _request.default)({
    url: "/orders-sellers/".concat(id),
    method: "patch",
    data: data
  });
};
var countOrdersSellers = exports.countOrdersSellers = function countOrdersSellers(params) {
  return (0, _request.default)({
    url: "/orders-sellers/count",
    method: "get",
    params: params
  });
};
var exportOrdersSellers = exports.exportOrdersSellers = function exportOrdersSellers(data) {
  return (0, _request.default)({
    url: "/orders-sellers/export",
    method: "post",
    data: data
  });
};
var exportAgingData = exports.exportAgingData = function exportAgingData(data) {
  return (0, _request.default)({
    url: "/orders-sellers/exportAgingData",
    method: "post",
    data: data
  });
};
var getSellerOrderInvoiceById = exports.getSellerOrderInvoiceById = function getSellerOrderInvoiceById(id) {
  return (0, _request.default)({
    url: "/orders-sellers/pdf/".concat(id, "/1"),
    method: "get"
  });
};