"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uploadProductImages = exports.updateProductVariations = exports.updateProductInventories = exports.updateProductImages = exports.updateProductCategories = exports.updateProductAttributes = exports.updateProduct = exports.productCount = exports.getProductsPrices = exports.getProductsImpressions = exports.getProductsCat = exports.getProducts = exports.getProductById = exports.getDiscountedproducts = exports.deleteProductVariation = exports.deleteProductImages = exports.deleteProduct = exports.defaultProductData = exports.createProduct = exports.bulkUpload = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultProductData = exports.defaultProductData = {
  id: 0,
  enabled: true,
  name: null,
  addedByType: 'admin',
  sku: "",
  productType: "simple",
  status: "Pending",
  priority: null,
  segment: null,
  measurementUnitId: null,
  multiplier: 1,
  featured: false,
  isFeaturedInCategory: false,
  isTopFeatured: false,
  isVerified: true,
  isBestSeller: false,
  isfreightAllowed: true,
  suppliedBy: "",
  tags: null,
  shortDescription: null,
  description: null,
  isTaxable: true,
  taxId: null,
  sellerId: null,
  cityId: null,
  stateId: null,
  pincodeId: null,
  length: null,
  breadth: null,
  height: null,
  weight: null,
  hsnCode: null,
  manageStock: false,
  stockStatus: "In Stock",
  countryOfOrigin: undefined,
  packingSize: undefined,
  packingType: undefined,
  moq: undefined,
  shelfLife: undefined,
  storageCondition: undefined,
  minimumPrice: null,
  closingPrice: null,
  color: undefined,
  grade: undefined,
  dimensions: undefined,
  mrp: undefined,
  ratings: null,
  ratingToShow: null,
  moisturePercent: undefined,
  availableInAllCities: true,
  validFrom: undefined,
  validTill: undefined,
  expiryDatetime: undefined,
  isFOSproduct: undefined,
  tax: {
    id: undefined
  },
  measurementUnit: {
    id: undefined
  },
  seller: {
    id: null
  },
  city: {
    id: null
  },
  state: {
    id: null
  },
  pincode: {
    id: null,
    stateId: null,
    cityId: null
  },
  unitOfMeasurementId: null,
  category: [],
  productsCategories: [],
  attribute: [],
  cities: [],
  productsImage: [],
  productsVariation: [],
  sellersProductsVariation: []
};
var getProducts = exports.getProducts = function getProducts(params) {
  return (0, _request.default)({
    url: "/products",
    method: "get",
    params: params
  });
};
var getProductsCat = exports.getProductsCat = function getProductsCat(params) {
  return (0, _request.default)({
    url: "/products-categories",
    method: "get",
    params: params
  });
};
var getProductById = exports.getProductById = function getProductById(id) {
  return (0, _request.default)({
    url: "/products/".concat(id),
    method: "get"
  });
};
var updateProduct = exports.updateProduct = function updateProduct(id, data) {
  return (0, _request.default)({
    url: "/products/".concat(id),
    method: "patch",
    data: data
  });
};
var deleteProduct = exports.deleteProduct = function deleteProduct(id) {
  return (0, _request.default)({
    url: "/products/".concat(id),
    method: "delete"
  });
};
var createProduct = exports.createProduct = function createProduct(data) {
  return (0, _request.default)({
    url: "/products/add/",
    method: "post",
    data: data
  });
};
var bulkUpload = exports.bulkUpload = function bulkUpload(data) {
  return (0, _request.default)({
    url: "/products/bulkUpload/",
    method: "post",
    data: data
  });
};
var productCount = exports.productCount = function productCount(params) {
  return (0, _request.default)({
    url: "/products/count",
    method: "get",
    params: params
  });
};
var updateProductCategories = exports.updateProductCategories = function updateProductCategories(id, data) {
  return (0, _request.default)({
    url: "/products/categories/".concat(id),
    method: "patch",
    data: data
  });
};
var updateProductAttributes = exports.updateProductAttributes = function updateProductAttributes(id, data) {
  return (0, _request.default)({
    url: "/products/attributes/".concat(id),
    method: "patch",
    data: data
  });
};
var updateProductVariations = exports.updateProductVariations = function updateProductVariations(id, data) {
  return (0, _request.default)({
    url: "/products-variations/manage",
    method: "post",
    data: data
  });
};
var deleteProductVariation = exports.deleteProductVariation = function deleteProductVariation(id) {
  return (0, _request.default)({
    url: "/products-variations/".concat(id),
    method: "delete"
  });
};
var updateProductImages = exports.updateProductImages = function updateProductImages(id, data) {
  return (0, _request.default)({
    url: "/products/images/".concat(id),
    method: "patch",
    data: data
  });
};
var deleteProductImages = exports.deleteProductImages = function deleteProductImages(id) {
  return (0, _request.default)({
    url: "/products-images/".concat(id),
    method: "delete"
  });
};
var updateProductInventories = exports.updateProductInventories = function updateProductInventories(id, data) {
  return (0, _request.default)({
    url: "/products/inventories/".concat(id),
    method: "patch",
    data: data
  });
};
var uploadProductImages = exports.uploadProductImages = function uploadProductImages(data) {
  return (0, _request.default)({
    url: "/products/images",
    method: "post",
    data: data
  });
};
var getProductsImpressions = exports.getProductsImpressions = function getProductsImpressions(params) {
  return (0, _request.default)({
    url: "/products-views/",
    method: "get",
    params: params
  });
};
var getProductsPrices = exports.getProductsPrices = function getProductsPrices(params) {
  return (0, _request.default)({
    url: "/products-price-logs/",
    method: "get",
    params: params
  });
};
var getDiscountedproducts = exports.getDiscountedproducts = function getDiscountedproducts(params) {
  return (0, _request.default)({
    url: "/products/discountList",
    method: "get",
    params: params
  });
};