"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateNotifications = exports.getNotificationsById = exports.getNotificationScheduler = exports.getFilters = exports.deleteNotifications = exports.defaultNotificationsSchedulerData = exports.createNotifications = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultNotificationsSchedulerData = exports.defaultNotificationsSchedulerData = {
  id: 0,
  enabled: true,
  title: "",
  message: "",
  image: null,
  link: null,
  page: null,
  params: null,
  sendToAll: false,
  appVersion: null,
  scheduleTimestamp: null,
  platform: null,
  stateId: null,
  onlyPush: false,
  state: {
    id: null
  },
  countryId: null,
  country: {
    id: null
  },
  cityId: null,
  city: {
    id: null
  }
};
var getNotificationScheduler = exports.getNotificationScheduler = function getNotificationScheduler(params) {
  return (0, _request.default)({
    url: "/notification-scheduler",
    method: "get",
    params: params
  });
};
var getNotificationsById = exports.getNotificationsById = function getNotificationsById(id) {
  return (0, _request.default)({
    url: "/notification-scheduler/".concat(id),
    method: "get"
  });
};
var updateNotifications = exports.updateNotifications = function updateNotifications(id, data) {
  return (0, _request.default)({
    url: "/notification-scheduler/".concat(id),
    method: "patch",
    data: data
  });
};
var deleteNotifications = exports.deleteNotifications = function deleteNotifications(id) {
  return (0, _request.default)({
    url: "/notification-scheduler/".concat(id),
    method: "delete"
  });
};
var createNotifications = exports.createNotifications = function createNotifications(data) {
  return (0, _request.default)({
    url: "/notification-scheduler/",
    method: "post",
    data: data
  });
};
var getFilters = exports.getFilters = function getFilters(data) {
  return (0, _request.default)({
    url: "/notification-scheduler/fetch",
    method: "post",
    data: data
  });
};