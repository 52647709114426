"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateTax = exports.getTaxes = exports.getTaxById = exports.fileTransferHeaders = exports.deleteTax = exports.defaultTaxData = exports.createTax = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = exports.fileTransferHeaders = {};
var defaultTaxData = exports.defaultTaxData = {
  id: 0,
  enabled: true,
  name: "",
  countryId: undefined,
  rate: 0
};
var getTaxes = exports.getTaxes = function getTaxes(params) {
  return (0, _request.default)({
    url: "/taxes",
    method: "get",
    params: params
  });
};
var getTaxById = exports.getTaxById = function getTaxById(id) {
  return (0, _request.default)({
    url: "/taxes/".concat(id),
    method: "get"
  });
};
var updateTax = exports.updateTax = function updateTax(id, data) {
  return (0, _request.default)({
    url: "/taxes/".concat(id),
    method: "patch",
    data: data
  });
};
var deleteTax = exports.deleteTax = function deleteTax(id) {
  return (0, _request.default)({
    url: "/taxes/".concat(id),
    method: "delete"
  });
};
var createTax = exports.createTax = function createTax(data) {
  return (0, _request.default)({
    url: "/taxes/",
    method: "post",
    data: data
  });
};