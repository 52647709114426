var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-form",
          {
            ref: "formData",
            staticClass: "demo-form",
            attrs: {
              model: _vm.formData,
              "status-icon": "",
              rules: _vm.rules,
              "label-position": "left"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "Amount", prop: "amount" } },
              [
                _c("el-input-number", {
                  attrs: {
                    name: "amount",
                    required: "",
                    placeholder: "Amount",
                    min: 1,
                    precision: 2,
                    step: 1
                  },
                  model: {
                    value: _vm.formData.amount,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "amount", $$v)
                    },
                    expression: "formData.amount"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Action", prop: "action" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { name: "action" },
                    model: {
                      value: _vm.formData.action,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "action", $$v)
                      },
                      expression: "formData.action"
                    }
                  },
                  _vm._l(["credit", "debit"], function(item) {
                    return _c("el-option", {
                      key: item,
                      attrs: { label: item, value: item }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm.formData.action === "credit"
              ? _c(
                  "el-form-item",
                  { attrs: { label: "Type", prop: "type" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { name: "s" },
                        model: {
                          value: _vm.formData.type,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "type", $$v)
                          },
                          expression: "formData.type"
                        }
                      },
                      _vm._l(
                        [
                          "Grievance",
                          "Cashback",
                          "TAT of Delivery amount",
                          "Extra Freight amount"
                        ],
                        function(item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item }
                          })
                        }
                      ),
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-form-item",
              { attrs: { label: "Comments", prop: "comments" } },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    name: "comments",
                    placeholder: "share comments",
                    maxlength: "200",
                    "show-word-limit": ""
                  },
                  model: {
                    value: _vm.formData.comments,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "comments", $$v)
                    },
                    expression: "formData.comments"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Order Reference", prop: "orderReference" } },
              [
                _c("el-input", {
                  staticStyle: {
                    "margin-right": "10px",
                    "margin-bottom": "10px"
                  },
                  attrs: {
                    type: "text",
                    name: "orderReference",
                    placeholder: "Your order reference"
                  },
                  model: {
                    value: _vm.formData.orderReference,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "orderReference", $$v)
                    },
                    expression: "formData.orderReference"
                  }
                }),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      },
                      { name: "waves", rawName: "v-waves" }
                    ],
                    attrs: { type: "primary" },
                    on: { click: _vm.fetchOrderReferenceData }
                  },
                  [_vm._v(" Fetch ")]
                )
              ],
              1
            ),
            _vm.orderReferenceData
              ? _c(
                  "el-form-item",
                  { attrs: { label: "Products", prop: "products" } },
                  _vm._l(_vm.orderReferenceData.products, function(product) {
                    return _c("el-checkbox", {
                      key: product.id,
                      attrs: {
                        prop: "question",
                        name: "question",
                        label: product.name,
                        placeholder: product.name
                      },
                      on: {
                        change: function($event) {
                          return _vm.productFunc(product, $event)
                        }
                      }
                    })
                  }),
                  1
                )
              : _vm._e(),
            _vm.orderReferenceData
              ? _c(
                  "el-form-item",
                  { attrs: { label: "Vendors", prop: "vendors" } },
                  _vm._l(_vm.orderReferenceData.vendors, function(vendor) {
                    return _c("el-checkbox", {
                      key: vendor.id,
                      attrs: {
                        prop: "question",
                        name: "question",
                        label: vendor.name,
                        placeholder: vendor.name
                      },
                      on: {
                        change: function($event) {
                          return _vm.vendorFunc(vendor, $event)
                        }
                      }
                    })
                  }),
                  1
                )
              : _vm._e(),
            _vm.orderReferenceData
              ? _c(
                  "el-form-item",
                  {
                    attrs: { label: "Bearer", prop: "bearer", name: "bearer" },
                    model: {
                      value: _vm.formData.bearer,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "bearer", $$v)
                      },
                      expression: "formData.bearer"
                    }
                  },
                  [
                    _c(
                      "el-checkbox",
                      {
                        attrs: {
                          checked: _vm.formData.bearer.includes(
                            "TradeBridge Expenses"
                          )
                        },
                        on: {
                          change: function($event) {
                            return _vm.updateBearer(
                              "TradeBridge Expenses",
                              $event
                            )
                          }
                        }
                      },
                      [_vm._v(" TradeBridge Expenses ")]
                    ),
                    _c(
                      "el-checkbox",
                      {
                        attrs: {
                          checked: _vm.formData.bearer.includes(
                            "Deduction to be done from vendor"
                          )
                        },
                        on: {
                          change: function($event) {
                            return _vm.updateBearer(
                              "Deduction to be done from vendor",
                              $event
                            )
                          }
                        }
                      },
                      [_vm._v(" Deduction to be done from vendor ")]
                    ),
                    _c(
                      "el-checkbox",
                      {
                        attrs: {
                          checked: _vm.formData.bearer.includes(
                            "Deduction to be done from logistics partner"
                          )
                        },
                        on: {
                          change: function($event) {
                            return _vm.updateBearer(
                              "Deduction to be done from logistics partner",
                              $event
                            )
                          }
                        }
                      },
                      [_vm._v(" Deduction to be done from logistics partner ")]
                    ),
                    _c(
                      "el-checkbox",
                      {
                        attrs: {
                          checked: _vm.formData.bearer.includes(
                            "Double Payment"
                          )
                        },
                        on: {
                          change: function($event) {
                            return _vm.updateBearer("Double Payment", $event)
                          }
                        }
                      },
                      [_vm._v(" Double Payment ")]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      },
                      { name: "waves", rawName: "v-waves" }
                    ],
                    attrs: { type: "success" },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    attrs: { type: "reset" },
                    on: { click: _vm.resetForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }