"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateCategoryNotifications = exports.getCategoryNotificationsById = exports.getCategoryNotifications = exports.fileTransferHeaders = exports.defaultCategoryNotificationsData = exports.createCategoryNotifications = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = exports.fileTransferHeaders = {};
var defaultCategoryNotificationsData = exports.defaultCategoryNotificationsData = {
  id: 0,
  enabled: true,
  title: "",
  message: "",
  image: null,
  page: null,
  params: null,
  categoryId: null,
  link: null,
  scheduleTimestamp: null,
  category: {
    id: null,
    name: null
  }
};
var getCategoryNotifications = exports.getCategoryNotifications = function getCategoryNotifications(params) {
  return (0, _request.default)({
    url: "/category-notification",
    method: "get",
    params: params
  });
};
var updateCategoryNotifications = exports.updateCategoryNotifications = function updateCategoryNotifications(id, data) {
  return (0, _request.default)({
    url: "/category-notification/".concat(id),
    method: "patch",
    data: data
  });
};
var createCategoryNotifications = exports.createCategoryNotifications = function createCategoryNotifications(data) {
  return (0, _request.default)({
    url: "/category-notification/",
    method: "post",
    data: data
  });
};
var getCategoryNotificationsById = exports.getCategoryNotificationsById = function getCategoryNotificationsById(id) {
  return (0, _request.default)({
    url: "/category-notification/".concat(id),
    method: "get"
  });
};