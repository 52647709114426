"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
var _typeof = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/typeof");
require("core-js/modules/es.array.concat");
require("core-js/modules/es.object.to-string");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/toConsumableArray"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var _categoryNotificationsSubMenu = require("./subMenus/category-notifications-sub-menu");
var _categorySubMenu = require("./subMenus/category-sub-menu");
var _sellersVariationsCitiesPricesSubMenu = require("./subMenus/sellers-variations-cities-prices-sub-menu");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) { if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } } return n.default = e, t && t.set(e, n), n; }
var productRouter = {
  path: "/product",
  component: _index.default,
  redirect: "noredirect",
  name: "productManagement.title",
  meta: {
    title: "productManagement.title",
    icon: "almond"
  },
  children: [{
    path: "/brand/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/brand/list.vue"));
      });
    },
    name: "Brands",
    meta: {
      title: "productManagement.brand.title",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/brand/edit/:id(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/brand/manage.vue"));
      });
    },
    name: "productManagement.brand.manage",
    props: {
      isEdit: true
    },
    meta: {
      title: "productManagement.brand.manage",
      noCache: true,
      activeMenu: "/brand/list",
      hidden: true
    }
  }, {
    path: "/brand/add",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/brand/manage.vue"));
      });
    },
    name: "productManagement.brand.manage",
    props: {
      isEdit: false
    },
    meta: {
      title: "productManagement.brand.manage",
      noCache: true,
      activeMenu: "/brand/list",
      hidden: true
    }
  }, {
    path: "/attribute/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/attribute/list.vue"));
      });
    },
    name: "productManagement.attribute.title",
    meta: {
      title: "productManagement.attribute.title",
      noCache: true,
      icon: "sack"
    }
  }, {
    path: "/attribute/add",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/attribute/manage.vue"));
      });
    },
    name: "productManagement.attribute.add",
    props: {
      isEdit: false
    },
    meta: {
      title: "productManagement.attribute.add",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/attribute/edit/:id(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/attribute/manage.vue"));
      });
    },
    name: "productManagement.attribute.edit",
    props: {
      isEdit: true
    },
    meta: {
      title: "productManagement.attribute.edit",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/attribute-value/list/:id",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/attribute-value/list.vue"));
      });
    },
    name: "Attribute Value",
    meta: {
      title: "productManagement.attributevalue.title",
      noCache: true,
      activeMenu: "/attribute/list",
      hidden: true
    }
  }, {
    path: "/attribute-value/add/:id(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/attribute-value/manage.vue"));
      });
    },
    name: "Manage Attribute Value",
    props: {
      isEdit: false
    },
    meta: {
      title: "productManagement.attributevalue.manage",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/attribute-value/edit/:id(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/attribute-value/manage.vue"));
      });
    },
    name: "productManagement.attributevalue.manage",
    props: {
      isEdit: true
    },
    meta: {
      title: "productManagement.attributevalue.manage",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/product/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/products/list.vue"));
      });
    },
    name: "productManagement.product.title",
    meta: {
      title: "productManagement.product.title",
      icon: "almond",
      noCache: true
    }
  }, {
    path: "/product/add",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/products/manage.vue"));
      });
    },
    name: "productManagement.product.manage",
    props: {
      isEdit: false
    },
    meta: {
      title: "productManagement.product.manage",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/product/edit/:id(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/products/manage.vue"));
      });
    },
    name: "productManagement.product.manage",
    props: {
      isEdit: true
    },
    meta: {
      title: "productManagement.product.manage",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/sellers-products-variations/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/sellers-products-variations/list.vue"));
      });
    },
    name: "productManagement.sellerVariation.title",
    meta: {
      title: "productManagement.sellerVariation.title",
      icon: "almond",
      noCache: true
    }
  }, {
    path: "/validate-products",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/validate-products/list.vue"));
      });
    },
    name: "Validate Products",
    meta: {
      icon: "list",
      title: "productManagement.validateProducts.title",
      noCache: true
    }
  }, {
    path: "/validate-discount-products",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/validate-discount-products/list.vue"));
      });
    },
    name: "Validate Discount Products",
    meta: {
      icon: "documentation",
      title: "bannerManagement.validateDiscountProducts.title",
      noCache: true
    }
  }, {
    path: "/products-impressions",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/products-impressions/list.vue"));
      });
    },
    name: "Products Impressions",
    meta: {
      icon: "list",
      title: "productManagement.productImpressions.title",
      noCache: true
    }
  }, {
    path: "/products-prices",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/products-prices/list.vue"));
      });
    },
    name: "Products prices",
    meta: {
      icon: "money",
      title: "productManagement.productPrices.title",
      noCache: true
    }
  }, {
    path: "/products-bulk-upload",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/product-bulk-upload/list.vue"));
      });
    },
    name: "Products bulk upload",
    meta: {
      icon: "edit",
      title: "productManagement.productBulkUpload.title",
      noCache: true
    }
  }, {
    path: "/deals-campaign",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/deals-campaign/list.vue"));
      });
    },
    name: "Deals of the day Campaign",
    meta: {
      icon: "shopping",
      title: "productManagement.dealsCampaign.title",
      noCache: true
    }
  }].concat((0, _toConsumableArray2.default)(_categorySubMenu.categoriesSubMenu), (0, _toConsumableArray2.default)(_categoryNotificationsSubMenu.categoryNotificationsSubMenu), (0, _toConsumableArray2.default)(_sellersVariationsCitiesPricesSubMenu.sellersVariationsCitiesPriceSubMenu))
};
var _default = exports.default = productRouter;