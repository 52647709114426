"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateOffices = exports.updateMustTry = exports.getMustTrys = exports.getMustTryById = exports.fileTransferHeaders = exports.deleteOffices = exports.defaultMustTryData = exports.createOffices = exports.createMustTry = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = exports.fileTransferHeaders = {};
var defaultMustTryData = exports.defaultMustTryData = {
  id: 0,
  enabled: true,
  isFOS: true,
  pageName: "",
  title: "",
  text: "",
  image: "",
  priority: 0,
  categoryId: null,
  productId: null
};
var getMustTrys = exports.getMustTrys = function getMustTrys(params) {
  return (0, _request.default)({
    url: "/must-try-category",
    method: "get",
    params: params
  });
};
var getMustTryById = exports.getMustTryById = function getMustTryById(id) {
  return (0, _request.default)({
    url: "/must-try-category/".concat(id),
    method: "get"
  });
};
var updateMustTry = exports.updateMustTry = function updateMustTry(id, data) {
  return (0, _request.default)({
    url: "/must-try-category/".concat(id),
    method: "patch",
    data: data
  });
};
var createMustTry = exports.createMustTry = function createMustTry(data) {
  return (0, _request.default)({
    url: "/must-try-category/",
    method: "post",
    data: data
  });
};
var updateOffices = exports.updateOffices = function updateOffices(id, data) {
  return (0, _request.default)({
    url: "/offices/".concat(id),
    method: "patch",
    data: data
  });
};
var deleteOffices = exports.deleteOffices = function deleteOffices(id) {
  return (0, _request.default)({
    url: "/offices/".concat(id),
    method: "delete"
  });
};
var createOffices = exports.createOffices = function createOffices(data) {
  return (0, _request.default)({
    url: "/offices/",
    method: "post",
    data: data
  });
};