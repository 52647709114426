var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-form",
          {
            ref: "formData",
            staticClass: "demo-form",
            attrs: {
              model: _vm.formData,
              "status-icon": "",
              rules: _vm.rules,
              "label-position": "left"
            }
          },
          [
            _c("el-divider", { attrs: { "content-position": "left" } }, [
              _vm._v(" Order Settings ")
            ]),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Booking Amount Threshold",
                  prop: "bookingAmountThreshold"
                }
              },
              [
                _c("el-input-number", {
                  attrs: {
                    name: "bookingAmountThreshold",
                    precision: 2,
                    step: 1
                  },
                  model: {
                    value: _vm.formData.bookingAmountThreshold,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "bookingAmountThreshold", $$v)
                    },
                    expression: "formData.bookingAmountThreshold"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Minimum Order Amount",
                  prop: "minimumOrderAmount"
                }
              },
              [
                _c("el-input-number", {
                  attrs: { name: "minimumOrderAmount", precision: 2, step: 1 },
                  model: {
                    value: _vm.formData.minimumOrderAmount,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "minimumOrderAmount", $$v)
                    },
                    expression: "formData.minimumOrderAmount"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Transaction Percent (%)",
                  prop: "transactionPercent"
                }
              },
              [
                _c("el-input-number", {
                  attrs: {
                    name: "transactionPercent",
                    precision: 2,
                    step: 1,
                    max: 100
                  },
                  model: {
                    value: _vm.formData.transactionPercent,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "transactionPercent", $$v)
                    },
                    expression: "formData.transactionPercent"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Cancellation Charges",
                  prop: "cancellationCharges"
                }
              },
              [
                _c("el-input-number", {
                  attrs: { name: "cancellationCharges", precision: 2, step: 1 },
                  model: {
                    value: _vm.formData.cancellationCharges,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "cancellationCharges", $$v)
                    },
                    expression: "formData.cancellationCharges"
                  }
                })
              ],
              1
            ),
            _c("el-divider", { attrs: { "content-position": "left" } }, [
              _vm._v(" Whatsapp Numbers ")
            ]),
            _c(
              "el-form-item",
              {
                attrs: { label: "Whatsapp Enquiry No", prop: "whatsappNumber" }
              },
              [
                _c("el-input", {
                  model: {
                    value: _vm.formData.whatsappNumber,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "whatsappNumber", $$v)
                    },
                    expression: "formData.whatsappNumber"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Whatsapp API No",
                  prop: "whatsappContactNumber"
                }
              },
              [
                _c("el-input", {
                  model: {
                    value: _vm.formData.whatsappContactNumber,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "whatsappContactNumber", $$v)
                    },
                    expression: "formData.whatsappContactNumber"
                  }
                })
              ],
              1
            ),
            _c("el-divider", { attrs: { "content-position": "left" } }, [
              _vm._v(" Commission Settings ")
            ]),
            _c(
              "el-form-item",
              { attrs: { label: "TDS Rate (%)", prop: "tdsRate" } },
              [
                _c("el-input-number", {
                  attrs: { name: "tdsRate", precision: 2, step: 1, max: 100 },
                  model: {
                    value: _vm.formData.tdsRate,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "tdsRate", $$v)
                    },
                    expression: "formData.tdsRate"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "GST TCS Rate (%)", prop: "gstTdsRate" } },
              [
                _c("el-input-number", {
                  attrs: {
                    name: "gstTdsRate",
                    precision: 2,
                    step: 1,
                    max: 100
                  },
                  model: {
                    value: _vm.formData.gstTdsRate,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "gstTdsRate", $$v)
                    },
                    expression: "formData.gstTdsRate"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "GST Commission Rate (%)",
                  prop: "gstCommissionRate"
                }
              },
              [
                _c("el-input-number", {
                  attrs: {
                    name: "gstCommissionRate",
                    precision: 2,
                    step: 1,
                    max: 100
                  },
                  model: {
                    value: _vm.formData.gstCommissionRate,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "gstCommissionRate", $$v)
                    },
                    expression: "formData.gstCommissionRate"
                  }
                })
              ],
              1
            ),
            _c("el-divider", { attrs: { "content-position": "left" } }, [
              _vm._v(" General Settings ")
            ]),
            _c(
              "el-form-item",
              { attrs: { label: "Bank Information", prop: "bankInformation" } },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    name: "bankInformation",
                    placeholder: "Information"
                  },
                  model: {
                    value: _vm.formData.bankInformation,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "bankInformation", $$v)
                    },
                    expression: "formData.bankInformation"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Order Placed Message",
                  prop: "orderPlacedMessage"
                }
              },
              [
                _c("editor", {
                  attrs: {
                    "api-key": _vm.tinymceKey,
                    init: {
                      height: 300,
                      plugins: [
                        "lists link image paste help wordcount table code"
                      ],
                      toolbar:
                        "undo redo | formatselect | bold italic | table | code | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help"
                    }
                  },
                  model: {
                    value: _vm.formData.orderPlacedMessage,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "orderPlacedMessage", $$v)
                    },
                    expression: "formData.orderPlacedMessage"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Order Placed Message In Hindi",
                  prop: "orderPlacedMessage_h"
                }
              },
              [
                _c("editor", {
                  attrs: {
                    "api-key": _vm.tinymceKey,
                    init: {
                      height: 300,
                      plugins: [
                        "lists link image paste help wordcount table code"
                      ],
                      toolbar:
                        "undo redo | formatselect | bold italic | table | code | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help"
                    }
                  },
                  model: {
                    value: _vm.formData.orderPlacedMessage_h,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "orderPlacedMessage_h", $$v)
                    },
                    expression: "formData.orderPlacedMessage_h"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Vendor Bank Details",
                  prop: "vendorBankDetails"
                }
              },
              [
                _c("editor", {
                  attrs: {
                    "api-key": _vm.tinymceKey,
                    init: {
                      height: 300,
                      plugins: [
                        "lists link image paste help wordcount table code"
                      ],
                      toolbar:
                        "undo redo | formatselect | bold italic | table | code | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help"
                    }
                  },
                  model: {
                    value: _vm.formData.vendorBankDetails,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "vendorBankDetails", $$v)
                    },
                    expression: "formData.vendorBankDetails"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Vendor Bank Details In Hindi",
                  prop: "vendorBankDetails_h"
                }
              },
              [
                _c("editor", {
                  attrs: {
                    "api-key": _vm.tinymceKey,
                    init: {
                      height: 300,
                      plugins: [
                        "lists link image paste help wordcount table code"
                      ],
                      toolbar:
                        "undo redo | formatselect | bold italic | table | code | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help"
                    }
                  },
                  model: {
                    value: _vm.formData.vendorBankDetails_h,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "vendorBankDetails_h", $$v)
                    },
                    expression: "formData.vendorBankDetails_h"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Expiry Days", prop: "expiryDays" } },
              [
                _c("el-input-number", {
                  attrs: { name: "expiryDays", precision: 2, step: 1 },
                  model: {
                    value: _vm.formData.expiryDays,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "expiryDays", $$v)
                    },
                    expression: "formData.expiryDays"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Website version", prop: "webVersion" } },
              [
                _c("el-input", {
                  attrs: { name: "webVersion" },
                  model: {
                    value: _vm.formData.webVersion,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "webVersion", $$v)
                    },
                    expression: "formData.webVersion"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Referral Amount", prop: "referralAmount" } },
              [
                _c("el-input-number", {
                  attrs: { name: "referralAmount", precision: 2, step: 1 },
                  model: {
                    value: _vm.formData.referralAmount,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "referralAmount", $$v)
                    },
                    expression: "formData.referralAmount"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Survey Form", prop: "showFeedbackForm" } },
              [
                _c("el-switch", {
                  model: {
                    value: _vm.formData.showFeedbackForm,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "showFeedbackForm", $$v)
                    },
                    expression: "formData.showFeedbackForm"
                  }
                }),
                _c("help-text", {
                  attrs: {
                    content:
                      "This will enable survey form for users who haven't filled it yet"
                  }
                })
              ],
              1
            ),
            _c("el-divider", { attrs: { "content-position": "left" } }, [
              _vm._v(" Bid Settings ")
            ]),
            _c(
              "el-form-item",
              {
                attrs: { label: "Bid Approval Hours", prop: "bidApprovalHours" }
              },
              [
                _c("el-input-number", {
                  attrs: { name: "bidApprovalHours", precision: 2, step: 1 },
                  model: {
                    value: _vm.formData.bidApprovalHours,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "bidApprovalHours", $$v)
                    },
                    expression: "formData.bidApprovalHours"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Bid Expiry Hours", prop: "bidExpiryHours" } },
              [
                _c("el-input-number", {
                  attrs: { name: "bidExpiryHours", precision: 2, step: 1 },
                  model: {
                    value: _vm.formData.bidExpiryHours,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "bidExpiryHours", $$v)
                    },
                    expression: "formData.bidExpiryHours"
                  }
                })
              ],
              1
            ),
            _c("el-divider", { attrs: { "content-position": "left" } }, [
              _vm._v(" COD Settings ")
            ]),
            _c(
              "el-form-item",
              {
                attrs: { label: "Advance COD (%)", prop: "codAdvancePercent" }
              },
              [
                _c("el-input-number", {
                  attrs: { name: "codAdvancePercent", precision: 2, step: 1 },
                  model: {
                    value: _vm.formData.codAdvancePercent,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "codAdvancePercent", $$v)
                    },
                    expression: "formData.codAdvancePercent"
                  }
                }),
                _c("help-text", {
                  attrs: {
                    content: "Advance % amount to be accepted from user if COD."
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "COD Rate (%)", prop: "codRate" } },
              [
                _c("el-input-number", {
                  attrs: { name: "codRate", precision: 2, step: 1 },
                  model: {
                    value: _vm.formData.codRate,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "codRate", $$v)
                    },
                    expression: "formData.codRate"
                  }
                }),
                _c("help-text", {
                  attrs: { content: "This is used to calculate COD charge" }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: { label: "Minimum COD Amount", prop: "minimumCodCharge" }
              },
              [
                _c("el-input-number", {
                  attrs: { name: "minimumCodCharge", precision: 2, step: 1 },
                  model: {
                    value: _vm.formData.minimumCodCharge,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "minimumCodCharge", $$v)
                    },
                    expression: "formData.minimumCodCharge"
                  }
                }),
                _c("help-text", {
                  attrs: { content: "This is minimum applicable COD charge" }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: { label: "FOS referral code", prop: "fosReferralCode" }
              },
              [
                _c("el-input", {
                  attrs: { name: "fosReferralCode" },
                  model: {
                    value: _vm.formData.fosReferralCode,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "fosReferralCode", $$v)
                    },
                    expression: "formData.fosReferralCode"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Home Background Image",
                  prop: "Homepage Banner Image"
                }
              },
              [
                _c("file-upload", {
                  attrs: {
                    multiple: false,
                    "file-list": _vm.homepageBannerImageTemp,
                    "max-size": 1,
                    url: "/common/upload"
                  },
                  on: { change: _vm.imageUploaded }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Appbar Image", prop: "appbarImage" } },
              [
                _c("file-upload", {
                  attrs: {
                    multiple: false,
                    "file-list": _vm.appbarImageTemp,
                    "max-size": 1,
                    url: "/common/upload"
                  },
                  on: { change: _vm.imageUploaded1 }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: { label: "Home Background Validity", prop: "validity" }
              },
              [
                _c("el-date-picker", {
                  attrs: {
                    type: "daterange",
                    "start-placeholder": "Start date",
                    "end-placeholder": "End date",
                    "default-time": ["00:00:00", "23:59:59"]
                  },
                  model: {
                    value: _vm.validity,
                    callback: function($$v) {
                      _vm.validity = $$v
                    },
                    expression: "validity"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Search box texts", prop: "searchTexts" } },
              [
                _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newValue,
                        expression: "newValue"
                      }
                    ],
                    attrs: { placeholder: "Add a value" },
                    domProps: { value: _vm.newValue },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.newValue = $event.target.value
                      }
                    }
                  }),
                  _c(
                    "button",
                    { attrs: { type: "button" }, on: { click: _vm.addValue } },
                    [_vm._v("Add Value")]
                  )
                ]),
                _c(
                  "ul",
                  {
                    staticStyle: {
                      display: "flex",
                      "flex-wrap": "wrap",
                      gap: "5px",
                      "margin-top": "2px"
                    }
                  },
                  _vm._l(_vm.formData.searchTexts, function(value, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          padding: "1px 6px",
                          "background-color": "#d0e7ff",
                          border: "1px solid #87cefa",
                          "border-radius": "3px",
                          color: "#005f9e",
                          "font-size": "14px"
                        }
                      },
                      [
                        _vm._v(" " + _vm._s(value) + " "),
                        _c(
                          "button",
                          {
                            staticStyle: {
                              "margin-left": "5px",
                              background: "none",
                              border: "none",
                              color: "#005f9e",
                              "font-weight": "bold",
                              cursor: "pointer",
                              "font-size": "14px"
                            },
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.removeValue(index)
                              }
                            }
                          },
                          [_vm._v(" x ")]
                        )
                      ]
                    )
                  }),
                  0
                )
              ]
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Add product segments", prop: "segments" } },
              [
                _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newValue1,
                        expression: "newValue1"
                      }
                    ],
                    attrs: { placeholder: "Add a value" },
                    domProps: { value: _vm.newValue1 },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.newValue1 = $event.target.value
                      }
                    }
                  }),
                  _c(
                    "button",
                    { attrs: { type: "button" }, on: { click: _vm.addValue1 } },
                    [_vm._v("Add Value")]
                  )
                ]),
                _c(
                  "ul",
                  {
                    staticStyle: {
                      display: "flex",
                      "flex-wrap": "wrap",
                      gap: "5px",
                      "margin-top": "2px"
                    }
                  },
                  _vm._l(_vm.formData.segments, function(value, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          padding: "1px 6px",
                          "background-color": "#d0e7ff",
                          border: "1px solid #87cefa",
                          "border-radius": "3px",
                          color: "#005f9e",
                          "font-size": "14px"
                        }
                      },
                      [
                        _vm._v(" " + _vm._s(value) + " "),
                        _c(
                          "button",
                          {
                            staticStyle: {
                              "margin-left": "5px",
                              background: "none",
                              border: "none",
                              color: "#005f9e",
                              "font-weight": "bold",
                              cursor: "pointer",
                              "font-size": "14px"
                            },
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.removeValue1(index)
                              }
                            }
                          },
                          [_vm._v(" x ")]
                        )
                      ]
                    )
                  }),
                  0
                )
              ]
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      },
                      { name: "waves", rawName: "v-waves" }
                    ],
                    attrs: { type: "success" },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    attrs: { type: "reset" },
                    on: { click: _vm.resetForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }