var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": ""
          },
          on: { "sort-change": _vm.sortChange }
        },
        [
          _c("el-table-column", {
            attrs: {
              sortable: "custom",
              width: "80",
              align: "center",
              label: _vm.$t("table.id"),
              prop: "id",
              "class-name": _vm.getSortClass("id")
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "200px",
              align: "center",
              label: "Name",
              prop: "name"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.productsImage.length > 0
                      ? _c("el-image", {
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: {
                            src: scope.row.productsImage[0].image.concat("-md"),
                            fit: "fill"
                          }
                        })
                      : _vm._e(),
                    _c("br"),
                    _vm._v(" " + _vm._s(scope.row.name) + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "150px",
              align: "center",
              label: "Priority",
              prop: "priority"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-input-number", {
                      attrs: { step: 1, "step-strictly": "", size: "mini" },
                      on: {
                        change: function($event) {
                          return _vm.updateProduct(scope.row)
                        }
                      },
                      model: {
                        value: scope.row.priority,
                        callback: function($$v) {
                          _vm.$set(scope.row, "priority", $$v)
                        },
                        expression: "scope.row.priority"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Seller" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return scope.row.seller
                      ? [
                          _c("i", { staticClass: "el-icon-user" }),
                          _vm._v(" " + _vm._s(scope.row.seller.fullName) + " "),
                          _c("br"),
                          _c("i", { staticClass: "el-icon-mobile" }),
                          _vm._v(
                            " " + _vm._s(scope.row.seller.mobileNumber) + " "
                          ),
                          _c("br"),
                          _c("i", { staticClass: "el-icon-message" }),
                          _vm._v(
                            " " + _vm._s(scope.row.seller.emailAddress) + " "
                          )
                        ]
                      : undefined
                  }
                }
              ],
              null,
              true
            )
          }),
          _c("el-table-column", {
            attrs: { label: "Category" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return scope.row.category
                      ? _vm._l(scope.row.category, function(item) {
                          return _c(
                            "el-tag",
                            {
                              key: item.id,
                              attrs: { size: "mini", effect: "plain" }
                            },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        })
                      : undefined
                  }
                }
              ],
              null,
              true
            )
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "Allowed Bid",
              prop: "bidAllowed",
              width: "100px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(" " + _vm._s(scope.row.bidAllowed) + " % ")]
                }
              }
            ])
          }),
          _vm.columns.createdDate
            ? _c("el-table-column", {
                attrs: {
                  label: _vm.$t("table.createdDate"),
                  width: "110px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("moment")(
                                  scope.row.createdTimestamp,
                                  "from"
                                )
                              )
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  912526870
                )
              })
            : _vm._e(),
          _vm.isSuperAdmin()
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: _vm.$t("table.actions"),
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "router-link",
                            { attrs: { to: "/product/edit/" + scope.row.id } },
                            [
                              _c("el-button", {
                                directives: [
                                  { name: "waves", rawName: "v-waves" }
                                ],
                                attrs: {
                                  type: "primary",
                                  size: "small",
                                  icon: "el-icon-edit"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  259635908
                )
              })
            : _vm._e()
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }