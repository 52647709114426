"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateBroker = exports.getBrokers = exports.getBrokerById = exports.fileTransferHeaders = exports.deleteBroker = exports.defaultBrokerData = exports.createBroker = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = exports.fileTransferHeaders = {};
var defaultBrokerData = exports.defaultBrokerData = {
  id: 0,
  enabled: true,
  name: '',
  mobileNumber: ''
};
var getBrokers = exports.getBrokers = function getBrokers(params) {
  return (0, _request.default)({
    url: '/brokers',
    method: 'get',
    params: params
  });
};
var getBrokerById = exports.getBrokerById = function getBrokerById(id) {
  return (0, _request.default)({
    url: "/brokers/".concat(id),
    method: 'get'
  });
};
var updateBroker = exports.updateBroker = function updateBroker(id, data) {
  return (0, _request.default)({
    url: "/brokers/".concat(id),
    method: 'patch',
    data: data
  });
};
var deleteBroker = exports.deleteBroker = function deleteBroker(id) {
  return (0, _request.default)({
    url: "/brokers/".concat(id),
    method: 'delete'
  });
};
var createBroker = exports.createBroker = function createBroker(data) {
  return (0, _request.default)({
    url: '/brokers/',
    method: 'post',
    data: data
  });
};