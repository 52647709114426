var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "formData",
      staticClass: "demo-form",
      attrs: {
        model: _vm.formData,
        "status-icon": "",
        rules: _vm.rules,
        "label-position": "left"
      }
    },
    [
      _c(
        "el-form-item",
        {
          attrs: { label: "Select Segment", name: "segment", prop: "segment" }
        },
        [
          _c(
            "el-select",
            {
              attrs: {
                name: "segment",
                filterable: "",
                "auto-complete": "off",
                placeholder: "Segment"
              },
              model: {
                value: _vm.formData.segment,
                callback: function($$v) {
                  _vm.$set(_vm.formData, "segment", $$v)
                },
                expression: "formData.segment"
              }
            },
            _vm._l(_vm.segments, function(segment) {
              return _c("el-option", {
                key: segment,
                attrs: { label: segment, value: segment }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Category", prop: "category" } },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 16 } },
                [
                  _c("h3", [_vm._v("Category")]),
                  _c("el-tree", {
                    ref: "tree",
                    attrs: {
                      data: _vm.categories.data,
                      "show-checkbox": "",
                      "node-key": "id",
                      "default-checked-keys": _vm.selectedCategories,
                      "empty-text": "No Categories",
                      props: {
                        label: "name"
                      }
                    },
                    on: { "check-change": _vm.emitFormData }
                  })
                ],
                1
              ),
              _c("h3", [_vm._v("Main Category")]),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "mainCat",
                        rules: _vm.rules.mainCat,
                        "label-width": "0"
                      }
                    },
                    [
                      _c("el-tree", {
                        ref: "mainTree",
                        attrs: {
                          data: _vm.categories.data,
                          "show-checkbox": "",
                          "node-key": "id",
                          name: "mainCat",
                          "default-checked-keys": _vm.selectedMainCategories,
                          "empty-text": "No Categories",
                          props: {
                            label: "name"
                          }
                        },
                        on: { "check-change": _vm.emitMainFormData }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                },
                { name: "waves", rawName: "v-waves" }
              ],
              attrs: { type: "success" },
              on: { click: _vm.submitForm }
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
          ),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              attrs: { type: "reset" },
              on: { click: _vm.resetForm }
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }