"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setToken = exports.setSize = exports.setSidebarStatus = exports.setLanguage = exports.removeToken = exports.getToken = exports.getSize = exports.getSidebarStatus = exports.getLanguage = void 0;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
// App
var sidebarStatusKey = 'sidebar_status';
var getSidebarStatus = exports.getSidebarStatus = function getSidebarStatus() {
  return _jsCookie.default.get(sidebarStatusKey);
};
var setSidebarStatus = exports.setSidebarStatus = function setSidebarStatus(sidebarStatus) {
  return _jsCookie.default.set(sidebarStatusKey, sidebarStatus);
};
var languageKey = 'language';
var getLanguage = exports.getLanguage = function getLanguage() {
  return _jsCookie.default.get(languageKey);
};
var setLanguage = exports.setLanguage = function setLanguage(language) {
  return _jsCookie.default.set(languageKey, language);
};
var sizeKey = 'size';
var getSize = exports.getSize = function getSize() {
  return _jsCookie.default.get(sizeKey);
};
var setSize = exports.setSize = function setSize(size) {
  return _jsCookie.default.set(sizeKey, size);
};
// User
var tokenKey = 'vue_typescript_admin_access_token';
var getToken = exports.getToken = function getToken() {
  return _jsCookie.default.get(tokenKey);
};
var setToken = exports.setToken = function setToken(token) {
  return _jsCookie.default.set(tokenKey, token);
};
var removeToken = exports.removeToken = function removeToken() {
  return _jsCookie.default.remove(tokenKey);
};