"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateUserNotifications = exports.updateNews = exports.getNewsById = exports.getNews = exports.getFilters = exports.deleteNews = exports.defaultNewsData = exports.createNews = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultNewsData = exports.defaultNewsData = {
  id: 0,
  enabled: true,
  title: '',
  summary: '',
  cover: '',
  sourceName: '',
  sourceImage: '',
  source: ''
};
var getNews = exports.getNews = function getNews(params) {
  return (0, _request.default)({
    url: '/news',
    method: 'get',
    params: params
  });
};
var getNewsById = exports.getNewsById = function getNewsById(id) {
  return (0, _request.default)({
    url: "/news/".concat(id),
    method: 'get'
  });
};
var updateNews = exports.updateNews = function updateNews(id, data) {
  return (0, _request.default)({
    url: "/news/".concat(id),
    method: 'patch',
    data: data
  });
};
var deleteNews = exports.deleteNews = function deleteNews(id) {
  return (0, _request.default)({
    url: "/news/".concat(id),
    method: 'delete'
  });
};
var createNews = exports.createNews = function createNews(data) {
  return (0, _request.default)({
    url: '/news/',
    method: 'post',
    data: data
  });
};
var updateUserNotifications = exports.updateUserNotifications = function updateUserNotifications(data) {
  return (0, _request.default)({
    url: '/news/updateUserNotifications/',
    method: 'post',
    data: data
  });
};
var getFilters = exports.getFilters = function getFilters(data) {
  return (0, _request.default)({
    url: '/notifications/fetch',
    method: 'post',
    data: data
  });
};