"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateNotifications = exports.getNotificationsById = exports.getNotifications = exports.getFilters = exports.deleteNotifications = exports.defaultNotificationsData = exports.createNotifications = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultNotificationsData = exports.defaultNotificationsData = {
  id: 0,
  enabled: true,
  title: '',
  message: '',
  image: null,
  link: null,
  page: null,
  params: null,
  sendToAll: false,
  appVersion: null,
  platform: null,
  stateId: null,
  onlyPush: false,
  state: {
    id: null
  },
  countryId: null,
  country: {
    id: null
  },
  cityId: null,
  city: {
    id: null
  }
};
var getNotifications = exports.getNotifications = function getNotifications(params) {
  return (0, _request.default)({
    url: '/notifications',
    method: 'get',
    params: params
  });
};
var getNotificationsById = exports.getNotificationsById = function getNotificationsById(id) {
  return (0, _request.default)({
    url: "/notifications/".concat(id),
    method: 'get'
  });
};
var updateNotifications = exports.updateNotifications = function updateNotifications(id, data) {
  return (0, _request.default)({
    url: "/notifications/".concat(id),
    method: 'patch',
    data: data
  });
};
var deleteNotifications = exports.deleteNotifications = function deleteNotifications(id) {
  return (0, _request.default)({
    url: "/notifications/".concat(id),
    method: 'delete'
  });
};
var createNotifications = exports.createNotifications = function createNotifications(data) {
  return (0, _request.default)({
    url: '/notifications/',
    method: 'post',
    data: data
  });
};
var getFilters = exports.getFilters = function getFilters(data) {
  return (0, _request.default)({
    url: '/notifications/fetch',
    method: 'post',
    data: data
  });
};