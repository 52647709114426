var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-form",
          {
            ref: "formData",
            staticClass: "demo-form",
            attrs: { "status-icon": "", "label-position": "left" }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "Delivery Option", prop: "deliveryOptions" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      placeholder: "Select",
                      name: "deliveryOptions",
                      required: ""
                    },
                    model: {
                      value: _vm.formData.selectedDelivery,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "selectedDelivery", $$v)
                      },
                      expression: "formData.selectedDelivery"
                    }
                  },
                  _vm._l(_vm.deliveryOptions, function(option, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { label: option.label, value: option.value }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm.formData.selectedDelivery === "VRL"
              ? _c(
                  "el-form-item",
                  { attrs: { label: "VRL", prop: "vrl" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          loading: _vm.vrlListLoading,
                          filterable: "",
                          remote: "",
                          clearable: "",
                          "remote-method": _vm.getVrlList,
                          name: "VRL",
                          placeholder: "Enter Product Name"
                        },
                        model: {
                          value: _vm.formData.vrlId,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "vrlId", $$v)
                          },
                          expression: "formData.vrlId"
                        }
                      },
                      _vm._l(_vm.vrlList, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-form-item",
              {
                staticStyle: { "margin-bottom": "20px" },
                attrs: { label: "Product", prop: "product" }
              },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      loading: _vm.productListLoading,
                      filterable: "",
                      remote: "",
                      "remote-method": _vm.getProductList,
                      name: "product",
                      placeholder: "Enter Product Name"
                    },
                    on: { change: _vm.handleProductChange },
                    model: {
                      value: _vm.formData.product,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "product", $$v)
                      },
                      expression: "formData.product"
                    }
                  },
                  _vm._l(_vm.productList, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Quantity", prop: "quantity" } },
              [
                _c("el-button", {
                  attrs: { icon: "el-icon-minus" },
                  on: { click: _vm.decreaseQuantity }
                }),
                _c("el-input", {
                  staticStyle: {
                    width: "100px",
                    "text-align": "center",
                    "font-weight": "bold"
                  },
                  attrs: {
                    name: "quantity",
                    placeholder: "quantity",
                    min: 1,
                    max: 30000,
                    precision: 2,
                    disabled: ""
                  },
                  model: {
                    value: _vm.formData.quantity,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "quantity", $$v)
                    },
                    expression: "formData.quantity"
                  }
                }),
                _c("el-button", {
                  attrs: { icon: "el-icon-plus" },
                  on: { click: _vm.increaseQuantity }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Price", prop: "unitPrice" } },
              [
                _c("el-input", {
                  staticStyle: { width: "100px", "text-align": "center" },
                  attrs: {
                    name: "unitPrice",
                    placeholder: "price",
                    precision: 2
                  },
                  model: {
                    value: _vm.formData.unitPrice,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "unitPrice", $$v)
                    },
                    expression: "formData.unitPrice"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      },
                      { name: "waves", rawName: "v-waves" }
                    ],
                    attrs: { type: "success" },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    attrs: { type: "reset" },
                    on: { click: _vm.resetForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }