var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-form",
          {
            ref: "formData",
            staticClass: "demo-form",
            attrs: {
              model: _vm.formData,
              "status-icon": "",
              rules: _vm.rules,
              "label-position": "left"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "Is seller", prop: "isSeller" } },
              [
                _c("el-switch", {
                  model: {
                    value: _vm.formData.isSeller,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "isSeller", $$v)
                    },
                    expression: "formData.isSeller"
                  }
                })
              ],
              1
            ),
            !_vm.isSeller
              ? _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "Deposit Amount",
                      prop: "sellerDepositAmount"
                    }
                  },
                  [
                    _c("el-input-number", {
                      attrs: {
                        name: "sellerDepositAmount",
                        required: "",
                        min: 1,
                        precision: 2,
                        step: 1
                      },
                      model: {
                        value: _vm.formData.sellerDepositAmount,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "sellerDepositAmount", $$v)
                        },
                        expression: "formData.sellerDepositAmount"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isSeller
              ? _c(
                  "el-form-item",
                  { attrs: { label: "Trade Limit", prop: "sellerTradeLimit" } },
                  [
                    _c("el-input-number", {
                      attrs: {
                        name: "sellerTradeLimit",
                        required: "",
                        min: 1,
                        precision: 2,
                        step: 1
                      },
                      model: {
                        value: _vm.formData.sellerTradeLimit,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "sellerTradeLimit", $$v)
                        },
                        expression: "formData.sellerTradeLimit"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-form-item",
              { attrs: { label: "Commission", prop: "commission" } },
              [
                _c("el-input-number", {
                  attrs: {
                    name: "commission",
                    required: "",
                    min: 1,
                    precision: 2,
                    step: 1
                  },
                  model: {
                    value: _vm.formData.commission,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "commission", $$v)
                    },
                    expression: "formData.commission"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "SKU", prop: "sellerSku" } },
              [
                _c("el-input-number", {
                  attrs: {
                    name: "sellerSku",
                    required: "",
                    min: 1,
                    precision: 2,
                    step: 1
                  },
                  model: {
                    value: _vm.formData.sellerSku,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "sellerSku", $$v)
                    },
                    expression: "formData.sellerSku"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Actual Rating" } },
              [
                _c("el-input-number", {
                  attrs: { name: "ratings", size: "mini", disabled: "" },
                  model: {
                    value: _vm.formData.rating,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "rating", $$v)
                    },
                    expression: "formData.rating"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Rating to show" } },
              [
                _c("el-input-number", {
                  attrs: { name: "ratingToShow", size: "mini" },
                  model: {
                    value: _vm.formData.ratingToShow,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "ratingToShow", $$v)
                    },
                    expression: "formData.ratingToShow"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Bank Name", prop: "bankName" } },
              [
                _c("el-input", {
                  attrs: { name: "mobileNumber", placeholder: "Bank Name" },
                  model: {
                    value: _vm.formData.bankName,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "bankName", $$v)
                    },
                    expression: "formData.bankName"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Branch Name", prop: "branchName" } },
              [
                _c("el-input", {
                  attrs: { name: "mobileNumber", placeholder: "Branch Name" },
                  model: {
                    value: _vm.formData.branchName,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "branchName", $$v)
                    },
                    expression: "formData.branchName"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: { label: "Bank Account Name", prop: "bankAccountName" }
              },
              [
                _c("el-input", {
                  attrs: {
                    name: "mobileNumber",
                    placeholder: "Bank Account Name"
                  },
                  model: {
                    value: _vm.formData.bankAccountName,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "bankAccountName", $$v)
                    },
                    expression: "formData.bankAccountName"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Account Number", prop: "acountNumber" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "mobileNumber",
                    placeholder: "Account Number"
                  },
                  model: {
                    value: _vm.formData.acountNumber,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "acountNumber", $$v)
                    },
                    expression: "formData.acountNumber"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "IFSC code", prop: "ifscCode" } },
              [
                _c("el-input", {
                  attrs: { name: "mobileNumber", placeholder: "IFSC code" },
                  model: {
                    value: _vm.formData.ifscCode,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "ifscCode", $$v)
                    },
                    expression: "formData.ifscCode"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Validity", prop: "validity" } },
              [
                _c("el-date-picker", {
                  attrs: {
                    type: "daterange",
                    "start-placeholder": "Start date",
                    "end-placeholder": "End date",
                    "default-time": ["00:00:00", "23:59:59"]
                  },
                  model: {
                    value: _vm.validity,
                    callback: function($$v) {
                      _vm.validity = $$v
                    },
                    expression: "validity"
                  }
                })
              ],
              1
            ),
            _c("el-divider", { attrs: { "content-position": "left" } }, [
              _vm._v(" Brand ")
            ]),
            _c(
              "el-form-item",
              { attrs: { label: "Brand Name", prop: "Brand Name" } },
              [
                _c("el-input", {
                  attrs: { name: "vendorName", placeholder: "Branch Name" },
                  model: {
                    value: _vm.formData.vendorName,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "vendorName", $$v)
                    },
                    expression: "formData.vendorName"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Brand Image", prop: "vendorIcon" } },
              [
                _c("file-upload", {
                  attrs: {
                    url: "/categories/image",
                    field: "file",
                    multiple: false,
                    "file-list": _vm.vendorIcon
                  },
                  on: { change: _vm.mobileImageUploaded }
                })
              ],
              1
            ),
            _c("el-divider", { attrs: { "content-position": "left" } }, [
              _vm._v(" Seller documents ")
            ]),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading"
                  }
                ],
                key: _vm.tableKey,
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.sellersDocumentList,
                  border: "",
                  fit: "",
                  "highlight-current-row": ""
                }
              },
              [
                _c("el-table-column", {
                  attrs: { width: "auto", label: "Name", prop: "name" }
                }),
                _c("el-table-column", {
                  attrs: { label: "File", prop: "file" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-link",
                            {
                              attrs: {
                                type: "primary",
                                href: scope.row.file,
                                target: "_blank"
                              }
                            },
                            [_vm._v(" view file ")]
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "Status" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "Select" },
                              model: {
                                value:
                                  _vm.formData.sellersDocument[scope.$index]
                                    .status,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.formData.sellersDocument[scope.$index],
                                    "status",
                                    $$v
                                  )
                                },
                                expression:
                                  "formData.sellersDocument[scope.$index].status"
                              }
                            },
                            _vm._l(["Approved", "Rejected"], function(item) {
                              return _c("el-option", {
                                key: item,
                                attrs: { label: item, value: item }
                              })
                            }),
                            1
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      },
                      { name: "waves", rawName: "v-waves" }
                    ],
                    attrs: { type: "success" },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }