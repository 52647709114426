"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'sack': {
    width: 16,
    height: 16,
    viewBox: '0 0 26 26',
    data: '<path pid="0" d="M11.031 0a1 1 0 00-.375.156c-.703.45-1.4.965-2.062 1.532-1.434.398-4.842 1.583-4.281 3.906.3 1.5.48 2.112.78 2.812C5.694 10.406 9 12 13 12s7.206-1.9 7.906-3.5c.3-.7.582-1.6.782-2.5.378-1.514-1.219-3.295-3.594-3.906a8.427 8.427 0 00-.75-.5C16.807 1.274 16.37 1 15.687 1c-.577 0-.944.2-1.218.281-.275.082-.425.083-.656.032-.319-.108-.713-.316-1.094-.563-.202-.13-.402-.287-.594-.406-.192-.12-.34-.226-.688-.313A1 1 0 0011.031 0zm.281 2.219c.102.066.19.107.313.187.447.29 1.012.641 1.688.844a1 1 0 00.062.031c.668.149 1.256.025 1.656-.094.4-.118.634-.187.656-.187-.183 0 .234.08.626.313.391.233.855.564 1.28.968.853.808 1.378 1.828 1.313 2.282a1 1 0 00-.031.093C18.011 7.588 15.91 8.5 13 8.5c-2.33 0-4.144-.591-5.219-1.281-.116-1.08.241-1.942.875-2.782.621-.822 1.639-1.53 2.656-2.218zM11.5 4a.5.5 0 100 1 .5.5 0 000-1zm2 1a.5.5 0 100 1 .5.5 0 000-1zm-2 1a.5.5 0 100 1 .5.5 0 000-1zM4.406 9.5c-1.5.9-2.618 2.806-3.218 6.406-.2 1.4.818 3.681 3.718 5.781 0 0-2.618-.487-3.718-2.187-.2.4-.188.8-.188 1 0 3 5.4 5.5 12 5.5s12-2.4 12-5.5c0-.2.006-.387-.094-.688-.3-1.4-1.8 1-4.5 1 1.8-.9 4.5-3.024 4-5.625-.2-1-.612-3.593-2.812-5.593C20.494 11.594 17.2 13 13 13c-4.2 0-7.494-1.4-8.594-3.5z"/>'
  }
});