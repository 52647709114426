"use strict";

var _interopRequireDefault = require("C:/Users/nasib/Downloads/desktop (1)/tbadmin/tradebridge-admin/tradebridgeadmin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSellersProductsVariation = exports.updateCartDiscountPrice = exports.getSellersProductsVariations = exports.getSellersProductsVariationById = exports.deleteSellersProductsVariation = exports.defaultSellersProductsVariationData = exports.createSellersProductsVariation = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultSellersProductsVariationData = exports.defaultSellersProductsVariationData = {
  id: 0,
  enabled: true,
  sellerId: undefined,
  seller: {
    id: null
  },
  productId: undefined,
  product: {
    id: null
  },
  productsVariationId: undefined,
  productsVariation: {
    id: null
  },
  name: '',
  unitPrice: null,
  fosUserPrice: null,
  salePrice: null,
  sku: null,
  image: null,
  stockStatus: undefined,
  stockQuantity: null,
  lowStockThreshold: 1,
  manageStock: undefined
};
var getSellersProductsVariations = exports.getSellersProductsVariations = function getSellersProductsVariations(params) {
  return (0, _request.default)({
    url: '/sellers-products-variations',
    method: 'get',
    params: params
  });
};
var getSellersProductsVariationById = exports.getSellersProductsVariationById = function getSellersProductsVariationById(id) {
  return (0, _request.default)({
    url: "/sellers-products-variations/".concat(id),
    method: 'get'
  });
};
var updateSellersProductsVariation = exports.updateSellersProductsVariation = function updateSellersProductsVariation(id, data) {
  return (0, _request.default)({
    url: "/sellers-products-variations/".concat(id),
    method: 'patch',
    data: data
  });
};
var deleteSellersProductsVariation = exports.deleteSellersProductsVariation = function deleteSellersProductsVariation(id) {
  return (0, _request.default)({
    url: "/sellers-products-variations/".concat(id),
    method: 'delete'
  });
};
var createSellersProductsVariation = exports.createSellersProductsVariation = function createSellersProductsVariation(data) {
  return (0, _request.default)({
    url: '/sellers-products-variations/',
    method: 'post',
    data: data
  });
};
var updateCartDiscountPrice = exports.updateCartDiscountPrice = function updateCartDiscountPrice(id, data) {
  return (0, _request.default)({
    url: "/order-milestone/".concat(id),
    method: 'patch',
    data: data
  });
};